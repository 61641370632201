import React from "react";

const GremlifyIcon = () => (
  <svg width="32px" height="32px" viewBox="-5 -4 22 16">
    <path
      fill={"none"}
      d="M10.4829759,8.421659 L10.4832,8.45351287 C10.4832,10.3379566 8.95305932,11.8656 7.06553514,11.8656 C5.80465429,11.8656 4.70325026,11.1839129 4.11090794,10.1695532 C3.73508686,10.3500963 3.31373392,10.4512634 2.86871351,10.4512634 C1.28436679,10.4512634 0,9.1689927 0,7.58723168 C0,6.33811739 0.80096158,5.27577238 1.91809912,4.8841911 C1.89112241,4.74035633 1.87705946,4.59198411 1.87705946,4.44033267 C1.87705946,3.11243453 2.95529331,2.0359604 4.28536216,2.0359604 C4.60694024,2.0359604 4.9137972,2.09888612 5.19425348,2.21307706 C5.57441367,1.57759736 6.27019136,1.152 7.06553514,1.152 C7.85186518,1.152 8.54087314,1.56800532 8.92379794,2.19159559 C9.23644911,2.09053002 9.5701117,2.0359604 9.91654054,2.0359604"
      id="cloud"
      strokeWidth="0.576"
      strokeLinecap="square"
    />
    <line
      x1="9.9648"
      y1="4.0896"
      x2="13.0176"
      y2="1.6128"
      id="Line"
      strokeWidth="0.576"
      strokeLinecap="square"
    />
    <line
      x1="5.3568"
      y1="5.0112"
      x2="9.9072"
      y2="4.2048"
      id="Line"
      strokeWidth="0.576"
      strokeLinecap="square"
    />
    <line
      x1="8.5824"
      y1="7.6608"
      x2="9.9072"
      y2="4.2048"
      id="Line"
      strokeWidth="0.576"
      strokeLinecap="square"
    />
    <line
      x1="12.096"
      y1="7.0848"
      x2="9.9072"
      y2="3.8016"
      id="2ECC71"
      strokeWidth="0.576"
      strokeLinecap="square"
    />
    <circle id="Oval" cx="5.3856" cy="5.0976" r="0.9" />
    <circle id="Oval" cx="12.6" cy="7.2" r="0.8" />
    <circle id="Oval" cx="8.5248" cy="7.6608" r="0.8" />
    <circle id="Oval" cx="9.936" cy="4.176" r="0.8" />
    <circle id="Oval" cx="13.3344" cy="1.2384" r="0.9" />
  </svg>
);

export default GremlifyIcon;
