import {FC, useReducer} from "react";
import * as React from "react";
import SandBoxContext from "./SandBoxContext";
import {initialState, sandBoxReducer} from "./reducers/SandBoxReducer";

const SandBoxContextProvider: FC = (props) =>{
  const [state, dispatch] = useReducer(sandBoxReducer, initialState);

  return (
    <SandBoxContext.Provider value={[state, dispatch]} {...props} />
  );
};

export default SandBoxContextProvider;