import * as React from "react";
import { FC, useCallback, useContext } from "react";
import { Pre } from "../../components/pre";
import useAnalytics from "../../shared/hooks/useAnalytics";
import SandBoxContext from "../sandbox/SandBoxContext";
import { UPDATE_QUERY } from "../sandbox/reducers/SandboxActions";
import styles from "./History.module.scss";
import { Sidebar } from "../../components/sidebar";
import { NonIdealState } from "../../components/non-ideal-state";
import { PaginatedList } from "../../components/paginated-list";

interface IHistoryItem {
  text: string;
  onClick: (text: string) => void;
  maxLength?: number;
}

const HistoryItem: FC<IHistoryItem> = ({ text, onClick, maxLength }) => {
  const handleClick = useCallback(() => {
    onClick(text);
  }, [onClick, text]);

  return (
    <div
      title={text}
      className={styles.historyItem}
      onClick={handleClick}
      data-cy={"HistoryItem"}
    >
      <Pre className={styles.query}>
        {maxLength
          ? text.length > maxLength
            ? text.substr(0, maxLength).concat("...")
            : text
          : text}
      </Pre>
    </div>
  );
};

const History: FC = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const [sendEvent] = useAnalytics();
  const handleItemClick = useCallback(
    (item) => {
      sendEvent({
        category: "Workspace",
        action: "History",
      });
      dispatch({
        type: UPDATE_QUERY,
        payload: { query: item },
      });
    },
    [dispatch, sendEvent]
  );

  const renderHistoryItem = useCallback(
    (item) => {
      return (
        <HistoryItem maxLength={40} onClick={handleItemClick} text={item} />
      );
    },
    [handleItemClick]
  );

  return (
    <div className={styles.history} data-cy={"History"}>
      <Sidebar.Content>
        {state.history.items.length > 0 ? (
          <PaginatedList
            perPage={8}
            className={styles.historyList}
            items={state.history.items}
            renderItem={renderHistoryItem}
          />
        ) : (
          <NonIdealState
            icon={"time"}
            title="No History"
            description={"Every query you run will be listed here!"}
          />
        )}
      </Sidebar.Content>
    </div>
  );
};

export default History;
