import AppRestService from "../communication/AppRestService";
import { NotificationService } from "../notification";

export interface IFavouriteQuery {
  query: string;
  id: number;
}

class FavouriteQueriesService {
  /**
   *
   * @param userId
   */
  public async getUserFavouriteQueriesByUserId(
    userId: number
  ): Promise<IFavouriteQuery[] | null> {
    try {
      const result = await AppRestService.get("favourite-queries", { userId });
      return result.data;
    } catch (e) {
      if (e && e.message) {
        NotificationService.error(e.message);
      }
      return null;
    }
  }

  /**
   *
   * @param userId
   * @param query
   */
  public async addToFavourites(
    userId: number,
    query: string
  ): Promise<number | null> {
    try {
      const result = await AppRestService.post(`favourite-queries`, {
        userId,
        query,
      });
      return result.data;
    } catch (e) {
      if (e && e.message) {
        NotificationService.error(e.message);
      }
      return null;
    }
  }

  /**
   *
   * @param userId
   * @param queryId
   */
  async removeFromFavourites(userId: number, queryId: number) {
    try {
      const result = await AppRestService.delete(
        `favourite-queries/${userId}/${queryId}`
      );
      return result.data;
    } catch (e) {
      if (e && e.message) {
        NotificationService.error(e.message);
      }
      return null;
    }
  }
}

export default new FavouriteQueriesService();
