import { useCallback } from "react";
import useSaveWorkspace from "./useSaveWorkspace";
import { useAnalytics } from "../../../shared/hooks";

export const useSaveRevision = () => {
  const { save: saveWorkspace, isLoading } = useSaveWorkspace();
  const [sendEvent] = useAnalytics();
  const save = useCallback(async () => {
    sendEvent({ category: "Workspace", action: "Save Revision" });
    await saveWorkspace();
  }, [saveWorkspace, sendEvent]);

  return { save, isLoading };
};
