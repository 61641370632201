import { useCallback, useContext } from "react";
import { useColorMap } from "../common";
import {
  GraphEntity,
  IEdge,
  IEntityData,
  IGraph,
  INode,
} from "../../../../../components/graph-builder/types/GraphTypes";
import GraphUtils from "../../../../../services/graph-builder/GraphUtils";
import { EntityType } from "../../../../../shared/types/common/enums";
import SandBoxContext from "../../../SandBoxContext";
import { SchemaBuilderUtils } from "./SchemaBuilderUtils";
import {
  CommonUtils,
  useCreateEdge,
  useCreateNode,
  useDeleteEdge,
  useDeleteEntity,
  useDeleteNode,
  useSwapEdge,
  useUpdateEdge,
  useUpdateNode,
  useUpdateNodePosition,
  useSelectedEntity,
  useCreateEntity,
  useCreateDummyNode,
  useCreateDummyEdge,
} from "../common";
import { useNotification } from "../../../../../services/notification";
import { useClearSchema } from "../common/hooks/useClearSchema";

const useSchemaBuilder = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const clearSchema = useClearSchema();
  const { notifyError } = useNotification();
  const { schemaBuilder } = state;
  const { scheme } = schemaBuilder;
  const { selected, setSelected } = useSelectedEntity(scheme);

  const { colorMap, updateColor, assignColorToLabel } = useColorMap();
  const updateScheme = useCallback(
    (nextScheme: IGraph) => {
      dispatch({ type: "UPDATE_SCHEMA", payload: { scheme: nextScheme } });
    },
    [dispatch]
  );

  const updateSchemeDelayed = useCallback(
    (nextScheme: IGraph, delay = 100) => {
      setTimeout(() => updateScheme(nextScheme), delay);
    },
    [updateScheme]
  );

  const createNodeMutator = useCreateNode(scheme);
  const createEdgeMutator = useCreateEdge(scheme);
  const deleteNodeMutator = useDeleteNode(scheme);
  const deleteEdgeMutator = useDeleteEdge(scheme);
  const deleteEntityMutator = useDeleteEntity(scheme);
  const updateNodeDataMutator = useUpdateNode(scheme);
  const updateEdgeMutator = useUpdateEdge(scheme);
  const swapEdgeMutator = useSwapEdge(scheme);
  const updateNodePositionMutator = useUpdateNodePosition(scheme);
  const createDummyNode = useCreateDummyNode(scheme);
  const createDummyEdge = useCreateDummyEdge(scheme);

  const createNode = useCallback(
    (x: number, y: number, data: IEntityData) => {
      if (!CommonUtils.isNodeLabelExists(data.label, scheme.nodes)) {
        const node = GraphUtils.createNode(x, y, data);
        node.id = SchemaBuilderUtils.generateEntityId();
        const nextScheme = createNodeMutator(node);
        assignColorToLabel(data.label);
        updateScheme(nextScheme);
        return node;
      }

      notifyError(`A Schema node with label '${data.label}' already exists.`);

      return null;
    },
    [
      assignColorToLabel,
      createNodeMutator,
      notifyError,
      scheme.nodes,
      updateScheme,
    ]
  );

  const createEdge = useCallback(
    (source: INode, target: INode, data: IEntityData) => {
      if (
        !CommonUtils.isEdgeLabelExists(
          data.label,
          source.id,
          target.id,
          scheme.edges
        )
      ) {
        const edge = GraphUtils.createEdge(source, target, data);
        edge.id = SchemaBuilderUtils.generateEntityId();
        const nextScheme = createEdgeMutator(edge);
        updateScheme(nextScheme);
        return edge;
      }
      notifyError(
        `A Schema edge with label '${data.label}' already exists between the selected nodes.`
      );

      return null;
    },
    [createEdgeMutator, notifyError, scheme.edges, updateScheme]
  );

  const cleanDummyEntities = useCallback(() => {
    const nextScheme = GraphUtils.cleanGraphFromDummyEntities(scheme);
    updateScheme(nextScheme);
  }, [updateScheme, scheme]);

  const onCreateEntity = useCallback(
    (entity: GraphEntity | null) => {
      if (entity) {
        dispatch({
          type: "ADD_LABEL",
          payload: { label: entity.data.label, entityType: entity.type },
        });
        setSelected(entity);
      } else {
        cleanDummyEntities();
      }
    },
    [cleanDummyEntities, dispatch, setSelected]
  );

  const {
    handleCreateEntity,
    setCreatingEntityOfType,
    creatingEntityOfType,
  } = useCreateEntity(createNode, createEdge, onCreateEntity);

  const onMaybeCreateNode = useCallback(
    async (x: number, y: number) => {
      const nextScheme = createDummyNode(x, y);
      updateScheme(nextScheme);
      setCreatingEntityOfType({ type: EntityType.node, args: [x, y] });
    },
    [createDummyNode, updateScheme, setCreatingEntityOfType]
  );

  const onMaybeCreateEdge = useCallback(
    async (source: INode, target: INode) => {
      const nextScheme = createDummyEdge(source, target);
      updateScheme(nextScheme);
      setCreatingEntityOfType({
        type: EntityType.edge,
        args: [source, target],
      });
    },
    [createDummyEdge, updateScheme, setCreatingEntityOfType]
  );

  const onDeleteNode = useCallback(
    async (node: INode, nodeId: number, nodes: INode[]) => {
      const nextScheme = deleteNodeMutator(node, nodeId, nodes);
      updateSchemeDelayed(nextScheme);
    },
    [deleteNodeMutator, updateSchemeDelayed]
  );

  const onDeleteEdge = useCallback(
    (edge: IEdge) => {
      const nextScheme = deleteEdgeMutator(edge);
      updateSchemeDelayed(nextScheme);
    },
    [deleteEdgeMutator, updateSchemeDelayed]
  );

  const onDeleteEntity = useCallback(
    async (entity: GraphEntity) => {
      return await deleteEntityMutator(entity, onDeleteNode, onDeleteEdge);
    },
    [deleteEntityMutator, onDeleteEdge, onDeleteNode]
  );

  const updateNodeData = useCallback(
    (node: INode) => {
      setSelected(node);
      const nextScheme = updateNodeDataMutator(node);
      updateScheme(nextScheme);
      return nextScheme;
    },
    [setSelected, updateNodeDataMutator, updateScheme]
  );

  const updateEdgeData = useCallback(
    (edge: IEdge) => {
      setSelected(edge);
      const nextScheme = updateEdgeMutator(edge);
      updateScheme(nextScheme);
      return nextScheme;
    },
    [setSelected, updateEdgeMutator, updateScheme]
  );

  const onUpdateEntityData = useCallback(
    (entity: GraphEntity) => {
      let nextScheme = null;
      if (entity.type === "node") {
        nextScheme = updateNodeData(entity);
      } else {
        nextScheme = updateEdgeData(entity);
      }

      if (nextScheme) {
        updateScheme(nextScheme as IGraph);
      }
    },
    [updateScheme, updateEdgeData, updateNodeData]
  );

  const closeCreationDialog = useCallback(() => {
    cleanDummyEntities();
    setCreatingEntityOfType(null);
  }, [cleanDummyEntities, setCreatingEntityOfType]);

  const onUpdateNodePosition = useCallback(
    (node: INode) => {
      const nextScheme = updateNodePositionMutator(node);
      updateScheme(nextScheme);
    },
    [updateNodePositionMutator, updateScheme]
  );

  const onSwapEdge = useCallback(
    (source: INode, target: INode, edge: IEdge) => {
      const newEdge = GraphUtils.createEdge(source, target, edge.data);
      const oldEdge = scheme.edges.find((e) => e.id === edge.id);
      if (oldEdge) {
        const nextScheme = swapEdgeMutator(oldEdge, newEdge);
        updateScheme(nextScheme);
      }
    },
    [scheme.edges, swapEdgeMutator, updateScheme]
  );

  const onSelectNode = useCallback(
    (node: INode) => {
      setSelected(node);
    },
    [setSelected]
  );

  const onSelectEdge = useCallback(
    (edge: IEdge) => {
      setSelected(edge);
    },
    [setSelected]
  );

  const onCopy = useCallback(() => {
    // do nothing
  }, []);

  const onPaste = useCallback(async () => {
    // do nothing
  }, []);

  // reset selected entity if entity does not exist anymore
  const updateLabelColor = useCallback(
    (label: string, color: string) => {
      updateColor(label, color);
    },
    [updateColor]
  );

  return {
    graph: scheme,
    onCreateEntity: handleCreateEntity,
    onDeleteNode,
    onDeleteEdge,
    onDeleteEntity,
    onMaybeCreateNode,
    onMaybeCreateEdge,
    onUpdateNodePosition,
    onUpdateEntityData,
    creatingEntityOfType,
    closeCreationDialog,
    onSwapEdge,
    onCopy,
    onPaste,
    onSelectNode,
    onSelectEdge,
    selected,
    updateLabelColor,
    colorMap,
    onClear: clearSchema,
    updateScheme,
  };
};

export default useSchemaBuilder;
