import { FC, useCallback } from "react";
import Entry from "./Entry/Entry";
import * as React from "react";
import classNames from "classnames";
import { IUiEntry } from "../KeyValueEditor";
import Button from "../../button/Button";
import styles from "./EntryLine.module.scss";
import { IEntryLinePartialProps } from "../useKeyValueEntries";

interface IEntryLine {
  index: number;
  entry: IUiEntry;
  canAdd: boolean;
  canRemove: boolean;
  onUpdate: (index: number, part: string, value: string) => void;
  onAdd: () => void;
  onRemove: (id: string) => void;
  keyProps: IEntryLinePartialProps;
  valueProps: IEntryLinePartialProps;
  isEditable: boolean;
}

const EntryLine: FC<IEntryLine> = ({
  index,
  entry,
  onUpdate,
  canAdd,
  canRemove,
  onAdd,
  onRemove,
  keyProps,
  valueProps,
  isEditable,
}) => {
  const onRemoveEntry = useCallback(() => {
    onRemove(entry.id);
  }, [entry.id, onRemove]);

  return (
    <div className={styles.entryLine} data-cy={"EntryLine"}>
      <Entry
        index={index}
        key={entry.id}
        onChange={onUpdate}
        keyProps={keyProps}
        valueProps={valueProps}
      />
      <div
        className={classNames(styles.actions, { [styles.empty]: !isEditable })}
      >
        {isEditable && canRemove && (
          <Button
            onClick={onRemoveEntry}
            minimal={true}
            icon={"trash"}
            data-cy={"RemoveProperty"}
            className={styles.remove}
          />
        )}
        {isEditable && canAdd && (
          <Button
            data-cy={"AddProperty"}
            onClick={onAdd}
            minimal={true}
            icon={"plus"}
            className={styles.add}
          />
        )}
      </div>
    </div>
  );
};

export default EntryLine;
