
class LocalStorageService {
  public set(key: string, value: string) {
   return window.localStorage.setItem(key, value);
  }

  public get(key: string) {
    return window.localStorage.getItem(key);
  }

  public clear(key: string) {
   return window.localStorage.clear();
  }
}

export default new LocalStorageService();
