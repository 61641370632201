import { SandboxAction } from "../../reducers/SandboxActions";
import { IQuery } from "./Query.interface";

export type QueryConsoleAction =
  | {
      type: "UPDATE_QUERY";
      payload: { query?: string; title?: string };
    }
  | { type: "ADD_QUERY" }
  | { type: "REMOVE_QUERY"; payload: { index: number } }
  | { type: "SELECT_QUERY"; payload: { index: number } };

export interface IQueryConsoleState {
  queries: IQuery[];
  index: number;
}

export const initialState = {
  queries: [{ title: "query 1", query: "" }],
  index: 0,
};

const getNextTitle = function* () {
  let i = 1;
  while (true) {
    yield ++i;
  }
};

const numGen = getNextTitle();

export const reducer = (action: SandboxAction, state: IQueryConsoleState) => {
  switch (action.type) {
    case "UPDATE_QUERY":
      const { query, title } = action.payload;
      return {
        ...state,
        queries: state.queries.map((q, i) =>
          i === state.index
            ? {
                title: title ? title : q.title,
                query: query !== undefined ? query : q.query,
              }
            : q
        ),
      };
    case "SELECT_QUERY": {
      return {
        ...state,
        index: action.payload.index,
      };
    }
    case "REMOVE_QUERY":
      const { index } = action.payload;
      return {
        ...state,
        index: Math.max(state.index - 1, 0),
        queries: state.queries.filter((q, i) => i !== index),
      };
    case "ADD_QUERY":
      return {
        ...state,
        index: state.index + 1,
        queries: [
          ...state.queries,
          { query: "", title: `query ${numGen.next().value}` },
        ],
      };
    case "SET_WORKSPACE":
      return {
        ...state,
        queries:
          action.payload.queries && action.payload.queries.length > 0
            ? action.payload.queries
            : state.queries,
        index: !action.payload.queries ? state.index : 0,
      };
    case "CLEAR_WORKSPACE":
      return initialState;
  }

  return state;
};
