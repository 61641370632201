import * as React from "react";
import { FC, useCallback, useContext } from "react";
import { Dialog } from "../../../../../../components/dialog";
import SandBoxContext from "../../../../SandBoxContext";
import StringUtils from "../../../../../../utils/StringUtils/StringUtils";
import styles from "./CreationDialog.module.scss";
import { EntityType } from "../../../../../../shared/types/common/enums";
import { KeyValueEditor } from "../../../../../../components/key-value-editor";
import { IEntityData } from "../../../../../../components/graph-builder/types/GraphTypes";
import { useCreation } from "./useCreation";
import { CloudSpinner } from "../../../../../../components/spinner";
import { PropertyValueTypeMap } from "../../../../../../shared/constants/common/common";

interface ICreationDialogProps {
  entityType: EntityType | null;
  onClose: () => void;
  onCreate: (data: IEntityData) => void;
}

const TYPE_ITEMS = Object.values(PropertyValueTypeMap).map((t) => ({
  label: t,
  value: t,
}));

export const CreationDialog: FC<ICreationDialogProps> = ({
  entityType,
  onClose,
  onCreate,
}) => {
  const [state] = useContext(SandBoxContext);
  const { loading, handleSubmit, entries } = useCreation(
    state.graphBuilder.scheme,
    entityType,
    onCreate
  );

  const createEntry = useCallback(
    (id) => ({
      id,
      keyProps: {
        value: "",
        type: "text" as const,
        editable: true,
      },
      valueProps: {
        value: "STRING",
        type: "select" as const,
        selectOptions: TYPE_ITEMS,
        editable: true,
      },
    }),
    []
  );
  return (
    <Dialog
      onClose={onClose}
      className={styles.creationDialog}
      title={
        entityType !== null &&
        `Create ${StringUtils.capitalizeFirstLetter(entityType)}`
      }
      isOpen={entityType !== null}
    >
      <div className={styles.dialogContent} data-cy={"CreationDialog"}>
        {loading ? (
          <div className={styles.spinnerWrapper}>
            <CloudSpinner size={130} />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <KeyValueEditor
                entryFactory={createEntry}
                className={styles.editor}
                isEditMode={false}
                entriesWrapperClassName={styles.entries}
                entries={entries}
                footerClassName={styles.footer}
                onChange={handleSubmit}
                containerElement={"div"}
              />
            </form>
          </>
        )}
      </div>
    </Dialog>
  );
};
