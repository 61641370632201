import React, { FC } from "react";
import {
  ActionsToolbar,
  ActionsToolbarButton,
  ToolbarContainer,
} from "../../common/components/ActionsToolbar";
import { Instructions } from "../../common/components/Instructions";
import { Settings } from "./Settings";

interface IToolbarProps {
  onActionClick: (action: string) => void;
  disabledActions: string[];
  activeWindow: string | null;
}

export const GraphActionsToolbar: FC<IToolbarProps> = ({
  onActionClick,
  disabledActions,
  activeWindow,
}) => {
  return (
    <ToolbarContainer>
      <ActionsToolbar hasActiveWindow={activeWindow !== null}>
        <ActionsToolbarButton
          disabled={disabledActions.includes("export")}
          onClick={() => onActionClick("export")}
          tooltipText={"Export to Query"}
          icon={"export"}
          className={"gy-export-to-query"}
        />
        <ActionsToolbarButton
          disabled={disabledActions.includes("settings")}
          onClick={() => onActionClick("settings")}
          tooltipText={"Settings"}
          icon={"cog"}
          active={activeWindow === "settings"}
          className={"gy-graph-settings"}
        />
        <ActionsToolbarButton
          disabled={disabledActions.includes("help")}
          onClick={() => onActionClick("help")}
          tooltipText={"Help"}
          icon={"info-sign"}
          active={activeWindow === "help"}
          className={"gy-graph-help"}
        />
      </ActionsToolbar>
      {activeWindow === "help" && <Instructions />}
      {activeWindow === "settings" && <Settings />}
    </ToolbarContainer>
  );
};
