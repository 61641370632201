import { Dispatch, useCallback, useEffect, useState } from "react";
import TemplatesService from "../../services/templates/TemplatesService";
import { GraphUtils } from "../../services/graph-builder";
import { SandboxAction } from "../sandbox/reducers/SandboxActions";
import { useColorMap } from "../sandbox/components/builders/common";
import { useLabels } from "../sandbox/components/builders/common/hooks/useLabels";
import { IGraph } from "../../components/graph-builder/types/GraphTypes";
import { LayoutType } from "../../services/graph-builder/Layout/LayoutFactory";

export interface ITemplate {
  name: string;
  title: string;
  description: string;
}

const useTemplates = (
  dispatch: Dispatch<SandboxAction>
): [boolean, ITemplate[], (template: string) => Promise<void>] => {
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [loading, setLoading] = useState(false);
  const { assignColorsToGraph } = useColorMap();
  const { extractLabelsFromScheme } = useLabels(null);

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      return await TemplatesService.fetchTemplates();
    };

    fetchTemplates().then((templates) => {
      setLoading(false);
      setTemplates(templates);
    });
  }, []);

  const updateWorkspaceMetadata = useCallback(
    (template: ITemplate) => {
      dispatch({
        type: "UPDATE_WORKSPACE_META",
        payload: {
          title: template.title,
          description: template.description,
        },
      });
    },
    [dispatch]
  );

  const updateGraphId = useCallback(
    (id: string) => {
      dispatch({
        type: "UPDATE_GRAPH_ID",
        payload: {
          id,
        },
      });
    },
    [dispatch]
  );

  const updateLabels = useCallback(
    (scheme: IGraph) => {
      const labels = extractLabelsFromScheme(scheme);
      dispatch({ type: "SET_LABELS", payload: { ...labels } });
    },
    [dispatch, extractLabelsFromScheme]
  );

  const updateGraphLayout = useCallback(
    (scheme: IGraph, layout: LayoutType) => {
      dispatch({ type: "UPDATE_GRAPH_LAYOUT", payload: { scheme, layout } });
    },
    [dispatch]
  );

  const resetDisplaySettings = useCallback(
    () => dispatch({ type: "RESET_DISPLAY_SETTINGS" }),
    [dispatch]
  );

  const applyTemplate = useCallback(
    async (name: string) => {
      setLoading(true);
      const result = await TemplatesService.loadTemplate(name);
      const layout = result.template.layout || {};
      const raw = GraphUtils.buildGraphFromResponse(result.nextGraph);
      const scheme = GraphUtils.generateGraphFromLayout(layout, raw);

      dispatch({ type: "RESET_SCHEMA" });
      updateGraphLayout(scheme, layout);
      updateWorkspaceMetadata(result.template);
      updateGraphId(name);
      assignColorsToGraph(scheme);
      updateLabels(scheme);
      resetDisplaySettings();
      dispatch({ type: "UPDATE_GRAPH_SUCCESS" });

      setTimeout(() => {
        setLoading(false);
      }, 800);
    },
    [
      assignColorsToGraph,
      dispatch,
      resetDisplaySettings,
      updateGraphId,
      updateGraphLayout,
      updateLabels,
      updateWorkspaceMetadata,
    ]
  );

  return [loading, templates, applyTemplate];
};

export default useTemplates;
