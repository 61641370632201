import { SandboxAction } from "../sandbox/reducers/SandboxActions";

export type UpdatesAction = {
  type: "HIDE_NEW_UPDATES";
};

export interface IUpdatesState {
  newUpdate: boolean;
}

export const initialState: IUpdatesState = {
  newUpdate: true,
};

export const reducer = (
  action: SandboxAction,
  state: IUpdatesState
): IUpdatesState => {
  if (action.type === "HIDE_NEW_UPDATES") {
    return {
      ...state,
      newUpdate: false,
    };
  }

  return state;
};
