import isEqual from "lodash/isEqual";

class Common {
  /**
   * check if two elements are equal
   * @param obj1
   * @param obj2
   */
  public static isEqual(obj1: unknown, obj2: unknown) {
    return isEqual(obj1, obj2);
  }

  public static randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  /**
   * get depth of object
   * @param el
   */
  public static depth(el: { [key: string]: any }) {
    let level = 1;
    for (const key in el) {
      if (!el.hasOwnProperty(key)) continue;

      if (typeof el[key] == "object") {
        if (el[key] instanceof Array && typeof el[key][0] === "object") {
          level = level + 1;
        } else if (!(el[key] instanceof Array)) {
          const depth = Common.depth(el[key]) + 1;
          level = Math.max(depth, level);
        }
      }
    }
    return level;
  }
}

export default Common;
