export enum AuthStatus {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
  CONNECTING = "connecting",
  CHANGE_PASSWORD = "change_password",
}

export enum UserStatus {
  CHANGE_PASSWORD = "Change_Password",
  ACTIVE = "Active"
}

export interface IAuthContext {
  user: IUser;
  authStatus: AuthStatus;
  login: (user: IUserLogIn) => Promise<IUser | null>;
  signUp: (user: IUserSignUp) => Promise<IUser | null>;
  logOut: () => void;
  resetPasswordRequest: (email: string) => Promise<boolean>;
  changePassword: (
    password: string,
    confirmPassword: string,
    token: string
  ) => Promise<boolean>;
  changeOldPassword: (
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) => Promise<boolean>;
}

export const authDefaultContext = {
  user: {
    email: null,
    name: null,
    id: null,
    status: null,
  },
  authStatus: AuthStatus.DISCONNECT,
  login: async () => {
    return null;
  },
  signUp: async () => {
    return null;
  },
  logOut: () => {
    return;
  },
  resetPasswordRequest: async () => {
    return false;
  },
  changePassword: async () => {
    return false;
  },
  changeOldPassword: async () => {
    return false;
  }
};

export const userDefaultState = {
  name: null,
  email: null,
  id: null,
  status: null
};

export interface IUser {
  name: string | null;
  email: string | null;
  id: number | null;
}

export interface IUserLogIn {
  email: string;
  password: string;
}

export interface IUserSignUp {
  name: string;
  email: string;
  password: string;
}
