import React, { FC } from "react";
import { Alert } from "../../../../../../components/alert";

interface ISchemaReductionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const SchemaReductionDialog: FC<ISchemaReductionDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => (
  <Alert
    data-cy={"SchemaReduction"}
    cancelButtonText="No, I don't need one"
    confirmButtonText="Yes"
    icon="layout"
    intent={"danger"}
    isOpen={isOpen}
    onCancel={onClose}
    onConfirm={onConfirm}
    canOutsideClickCancel={false}
  >
    <p>A Schema is not yet defined for this workspace Graph.</p>
    <p>Would you like to create one based on your existing entities?</p>
  </Alert>
);
