import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import WorkspaceService, {
  IWorkspace,
} from "../../services/workspace/WorkspaceService";
import { browserHistory } from "../router/history";
import { useParams } from "react-router";

export interface IFilterState {
  users: string[];
}

interface IWorkspacesState {
  loading: boolean;
  workspaces: IWorkspace[];
  filters: IFilterState;
  userOptions: string[];
}

const initialState = {
  workspaces: [] as IWorkspace[],
  loading: false,
  userOptions: [] as string[],
  filters: {
    users: [] as string[],
  },
};

const getWorkspacesUsers = (workspaces: IWorkspace[]) =>
  workspaces.reduce(
    (list, cur) =>
      cur.user && !list.includes(cur.user) ? list.concat(cur.user) : list,
    [] as string[]
  );

type Action =
  | { type: "SET_WORKSPACES"; payload: { workspaces: IWorkspace[] } }
  | { type: "TOGGLE_LOADING"; payload: { loading: boolean } }
  | { type: "TOGGLE_PRIVACY"; payload: { id: number; isPrivate: boolean } }
  | { type: "DELETE_WORKSPACE"; payload: { id: number } }
  | {
      type: "UPDATE_FILTER";
      payload: { key: keyof IFilterState; value: string };
    };

const reducer = (state: IWorkspacesState, action: Action) => {
  switch (action.type) {
    case "SET_WORKSPACES":
      const users = getWorkspacesUsers(action.payload.workspaces);
      return {
        ...state,
        workspaces: action.payload.workspaces,
        filters: {
          ...state.filters,
          users,
        },
        userOptions: users,
      };
    case "TOGGLE_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "TOGGLE_PRIVACY":
      return {
        ...state,
        workspaces: state.workspaces.map((w) =>
          w.id === action.payload.id
            ? {
                ...w,
                isPrivate: action.payload.isPrivate,
              }
            : w
        ),
      };
    case "DELETE_WORKSPACE":
      return {
        ...state,
        workspaces: state.workspaces.filter((w) => w.id !== action.payload.id),
      };
    case "UPDATE_FILTER": {
      const { key, value } = action.payload;
      const current = state.filters[key];
      return {
        ...state,
        filters: {
          ...state.filters,
          [key]: current.includes(value)
            ? current.filter((v) => v !== value)
            : current.concat(value),
        },
      };
    }
  }
  return state;
};

const useWorkspaces = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchTerm, setSearchTerm] = useState("");
  const [stagedForDelete, stageDelete] = useState<null | number>(null);
  const { view, key } = useParams();

  useEffect(() => {
    let isCancelled = false;
    const fetch = async () => {
      dispatch({ type: "TOGGLE_LOADING", payload: { loading: true } });
      try {
        const result = await WorkspaceService.getList(view === "organization");

        if (!isCancelled) {
          dispatch({ type: "SET_WORKSPACES", payload: { workspaces: result } });
        }
      } catch (e) {
        browserHistory.push("/");
      } finally {
        dispatch({ type: "TOGGLE_LOADING", payload: { loading: false } });
      }
    };
    fetch();

    return () => {
      isCancelled = true;
    };
  }, [view]);

  const changePrivacy = useCallback(async (id: number, isPrivate: boolean) => {
    const result = await WorkspaceService.changePrivacy(id, isPrivate);
    if (result) {
      dispatch({ type: "TOGGLE_PRIVACY", payload: { id, isPrivate } });
    }
  }, []);

  const filterBySearchTerm = useCallback(
    (w) => {
      const title = w.title || "Untitled Workspace";
      const term = searchTerm.toLocaleLowerCase();
      const description = w.description || "";
      if (title.toLocaleLowerCase().includes(term)) {
        return true;
      }
      return description.toLocaleLowerCase().includes(term);
    },
    [searchTerm]
  );

  const filterByUsers = useCallback(
    (w: IWorkspace) => (w.user ? state.filters.users.includes(w.user) : true),
    [state.filters.users]
  );

  const filterWorkspaces = useCallback(
    (w) => filterBySearchTerm(w) && filterByUsers(w),
    [filterBySearchTerm, filterByUsers]
  );

  const reduceRevisions = useCallback(
    (maxRevisionsMap) => (acc: IWorkspace[], cur: IWorkspace) => {
      // if we are drilled down, include every revision
      if (key) {
        return acc.concat(cur.key === key ? [cur] : []);
      } else {
        if (maxRevisionsMap[cur.key] === cur.revision) {
          return acc.concat(cur);
        }
      }
      return acc;
    },
    [key]
  );
  const workspaces = useMemo(() => {
    const maxRevisionsMap = state.workspaces.reduce(
      (acc: { [key: string]: number }, cur: IWorkspace) => {
        if (!acc[cur.key]) {
          acc[cur.key] = cur.revision;
        } else if (acc[cur.key] < cur.revision) {
          acc[cur.key] = cur.revision;
        }
        return acc;
      },
      {} as { [key: string]: number }
    );
    return state.workspaces
      .filter(filterWorkspaces)
      .reduce(reduceRevisions(maxRevisionsMap), []);
  }, [filterWorkspaces, reduceRevisions, state.workspaces]);

  const unstageFromDelete = useCallback(() => {
    stageDelete(null);
  }, []);

  const confirmDeleteWorkspace = useCallback(async () => {
    dispatch({ type: "TOGGLE_LOADING", payload: { loading: true } });
    if (stagedForDelete) {
      const result = await WorkspaceService.deleteWorkspace(stagedForDelete);
      if (result) {
        dispatch({
          type: "DELETE_WORKSPACE",
          payload: { id: stagedForDelete },
        });
        unstageFromDelete();
        dispatch({ type: "TOGGLE_LOADING", payload: { loading: false } });
      }
    }
  }, [stagedForDelete, unstageFromDelete]);

  const updateFilter = useCallback((key: keyof IFilterState, value: string) => {
    dispatch({ type: "UPDATE_FILTER", payload: { key, value } });
  }, []);

  return {
    loading: state.loading,
    workspaces,
    filters: state.filters,
    confirmDeleteWorkspace,
    stageDelete,
    stagedForDelete,
    changePrivacy,
    unstageFromDelete,
    search: setSearchTerm,
    key,
    view,
    updateFilter,
    userOptions: state.userOptions,
  };
};

export default useWorkspaces;
