import React, { FC, useCallback } from "react";
import { HTMLSelect } from "../../../../../../../../../../components/html-select";
import styles from "./LabelItem.module.scss";

interface ILabelItemProps {
  label: string;
  props: string[];
  onChange: (label: string, value: string) => void;
  value: string;
}

export const LabelItem: FC<ILabelItemProps> = ({
  label,
  props,
  onChange,
  value,
}) => {
  const handleChange = useCallback(
    (event) => {
      const {
        currentTarget: { value },
      } = event;
      onChange(label, value);
    },
    [label, onChange]
  );
  return (
    <div className={styles.labelItem}>
      <div className={styles.label}>
        <span>{label}</span>
      </div>
      <HTMLSelect
        value={value}
        onChange={handleChange}
        className={styles.propSelect}
      >
        {props.map((prop) => (
          <option value={prop} key={`${label}-${prop}`}>
            {prop}
          </option>
        ))}
      </HTMLSelect>
    </div>
  );
};
