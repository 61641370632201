import { TourBuilder } from "../../../../components/tour";
import React from "react";

const tour = new TourBuilder()
  .addStep(
    "body",
    <p>
      Welcome to Gremlify! <br /> In this short guide we will walk you through
      the key features we provide.
    </p>,
    {
      placement: "center",
    }
  )
  .addStep(
    "#gy-templates",
    "New to Gremlin, or too lazy to build your own Graph? use one of our prebuilt templates!",
    {
      placement: "left",
      disableBeacon: true,
    }
  )
  .addStep(
    ".gy-scheme-builder",
    "If you wish to build your own Graph, use the instructions above this window to build your graph with our Visual Graph Builder",
    {
      placement: "left",
    }
  )
  .addStep(
    ".gy-tab-schema",
    <p>
      Click here to define a Schema for your workspace. You can read more about
      schemas{" "}
      <a
        href={"https://gremlify.gitbook.io/gremlify/schema"}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        here
      </a>
      .
    </p>,
    {
      placement: "left",
    }
  )
  .addStep(
    ".gy-query-console",
    "This the Query Console. Here you can write your Gremlin queries. You can also build your graph here, using Gremlin steps."
  )
  .addStep(
    ".gy-run",
    "After your'e done building your graph and query, click here to run your query."
  )
  .addStep(
    ".gy-results",
    "If everything went ok, you will see the query results right here.",
    {
      placement: "top",
    }
  )
  .addStep("#gy-history", "Click here to see the history of your query runs.")
  .addStep(
    "#gy-favourites",
    "Click here to browse the list of your favourite queries."
  )
  .addStep(
    "#gy-metadata",
    "Here you can give your Workspace a nice catchy name and a short description."
  )
  .addStep(
    ".gy-save-workspace",
    "Happy with your work and want to share it with the world? Click here and use the generated URL."
  )
  .addStep(
    ".gy-clear-workspace",
    "Want to start over? Click here to drop your Graph, and clear your Workspace."
  )
  .addStep(
    ".gy-undo-graph",
    "Regret your last steps since last save? no worries. Click here and the Graph will be reverted to the last save state. Please note that this means that graph entities will be assigned with new ids."
  )
  .addStep(
    "#gy-updates",
    "Keep yourself updated with our latest product releases!"
  )
  .addStep("#gy-community", "And...don't forget to follow us!")
  .build();

export default tour;
