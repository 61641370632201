import { IGremlinGraphScheme } from "../../../shared/types/gremlin";
import { IRunService, RunResult } from "../RunServiceTypes";
import { LanguageType } from "../../../shared/types/common/language";
import AppRestService from "../../communication/AppRestService";
import GoogleAnalyticsService from "../../analytics/google/GoogleAnalyticsService";
import { NotificationService } from "../../notification";

class RunGremlinService implements IRunService {
  public getLanguage() {
    return LanguageType.GREMLIN;
  }

  /**
   * runs the current scheme & code
   * @param language
   * @param graph
   * @param query
   * @param schema
   */
  public async run(
    language: LanguageType,
    query: string,
    graph: IGremlinGraphScheme,
    schema?: IGremlinGraphScheme
  ): Promise<RunResult | null> {
    GoogleAnalyticsService.sendEvent({
      action: "Run Query",
      category: `Sandbox`,
      label: `query: ${query}, scheme: ${JSON.stringify(graph)}`,
    });

    try {
      const response = await AppRestService.post(`runner/${language}/run`, {
        graph,
        schema,
        query,
      });

      NotificationService.success("Query was executed successfully.");
      const { result, nextGraph } = response.data;
      return {
        result,
        nextGraph: nextGraph ? nextGraph : null,
      };
    } catch (e) {
      GoogleAnalyticsService.sendEvent({
        action: "Failed Query",
        category: `Sandbox`,
        label: `query: ${query}, graph: ${JSON.stringify(graph)}`,
      });
      return this.handleError(e);
    }
  }

  /**
   * handle error
   * @param e
   * @private
   */
  private handleError(e: any) {
    let error;
    if (e.message) {
      if (typeof e.message === "string") {
        NotificationService.error(e.message);
        error = e.reason;
      } else {
        e.message.forEach((message: any) => {
          error = `Invalid Gremlin ${message.property}`;
          NotificationService.error(error);
        });
      }
    } else {
      error = "An unexpected error occurred";
      NotificationService.error(error);
    }

    return { result: [], error };
  }
}

export default RunGremlinService;
