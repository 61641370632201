import * as React from "react";
import styles from "./Instructions.module.scss";
import { Tag } from "../../../../../../../components/tag";
import { Icon, MouseIcon } from "../../../../../../../components/icon";

interface IShortCut {
  description: string;
  keys: string[];
}

const shortCuts: IShortCut[] = [
  {
    description: "Create a new Vertex",
    keys: ["shift", "mouse-left"],
  },
  {
    description: "Create a new Edge between two Vertexes",
    keys: ["shift", "mouse-left", "drag"],
  },
  {
    description: "Delete a Vertex",
    keys: ["mouse-left", "backspace"],
  },
  {
    description: "Delete an Edge",
    keys: ["mouse-left", "backspace"],
  },
  {
    description: "Copy / Paste a Vertex",
    keys: ["mouse-left", "ctrl", "c/v"],
  },
  {
    description: "Zoom in / out",
    keys: ["mousewheel"],
  },
  {
    description: "More options",
    keys: ["mouse-right"],
  },
];

export const Instructions = () => (
  <ul className={styles.instructions}>
    {shortCuts.map((shortcut, i) => (
      <li key={shortcut.description}>
        <span className={styles.description}>{shortcut.description}</span>
        <div className={styles.keys}>
          {shortcut.keys.map((key, j) => (
            <React.Fragment key={`${key}-${i}-${j}`}>
              <Tag className={styles.key}>
                {key.includes("mouse-") ? (
                  <MouseIcon button={key === "mouse-left" ? "left" : "right"} />
                ) : (
                  key
                )}
              </Tag>
              {j !== shortcut.keys.length - 1 ? (
                <Icon className={styles.plus} icon={"plus"} />
              ) : null}
            </React.Fragment>
          ))}
        </div>
      </li>
    ))}
  </ul>
);
