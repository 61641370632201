import * as React from "react";
import styles from "./PaginatedList.module.scss";
import { FC, ReactElement } from "react";
import classNames from "classnames";
import usePagination from "./usePagination";
import { Button } from "../button";

interface IPaginatedListProps {
  perPage: number;
  items: unknown[];
  renderItem: (item: unknown) => ReactElement;
  className?: string;
}

const PaginatedList: FC<IPaginatedListProps> = ({
  className,
  items,
  renderItem,
  perPage,
}) => {
  const {
    currentPage,
    numPages,
    currentPageItems,
    goToNextPage,
    goToPreviousPage,
  } = usePagination(items, perPage);
  return (
    <div className={styles.paginatedList} data-cy={"PaginatedList"}>
      <ul className={className}>
        {currentPageItems.map((item, index) => (
          <li key={`${item}-${index}`}>{renderItem(item)}</li>
        ))}
      </ul>
      {numPages > 1 && (
        <div className={styles.navControls}>
          <Button
            minimal={true}
            onClick={goToPreviousPage}
            disabled={currentPage === 0}
            className={classNames(styles.navButton)}
            icon={"chevron-left"}
            data-cy={"Prev"}
          />
          <Button
            minimal={true}
            onClick={goToNextPage}
            disabled={currentPage === numPages - 1}
            className={classNames(styles.navButton)}
            icon={"chevron-right"}
            data-cy={"Next"}
          />
        </div>
      )}
    </div>
  );
};

export default PaginatedList;
