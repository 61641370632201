import * as React from "react";
import { FC } from "react";
import { Dialog } from "../../../../../../components/dialog";
import StringUtils from "../../../../../../utils/StringUtils/StringUtils";
import styles from "./CreationDialog.module.scss";
import { EntityType } from "../../../../../../shared/types/common/enums";
import { KeyValueEditor } from "../../../../../../components/key-value-editor";
import { IEntityData } from "../../../../../../components/graph-builder/types/GraphTypes";
import useCreation from "./useCreation";
import { CloudSpinner } from "../../../../../../components/spinner";
interface ICreationDialogProps {
  entityType: EntityType | null;
  onClose: () => void;
  onCreate: (data: IEntityData) => void;
  lastEntityLabels: {
    node: string;
    edge: string;
  };
}

export const CreationDialog: FC<ICreationDialogProps> = ({
  entityType,
  onClose,
  onCreate,
}) => {
  const {
    loading,
    handleSubmit,
    entries,
    validateEntries,
    handleChangeEntries,
  } = useCreation(entityType, onCreate);

  return (
    <Dialog
      onClose={onClose}
      className={styles.creationDialog}
      title={
        entityType !== null &&
        `Create ${StringUtils.capitalizeFirstLetter(entityType)}`
      }
      isOpen={entityType !== null}
    >
      <div className={styles.dialogContent} data-cy={"CreationDialog"}>
        {loading ? (
          <div className={styles.spinnerWrapper}>
            <CloudSpinner size={130} />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <KeyValueEditor
                className={styles.editor}
                isEditMode={false}
                entriesWrapperClassName={styles.entries}
                entries={entries}
                footerClassName={styles.footer}
                onChange={handleSubmit}
                containerElement={"div"}
                customValidator={validateEntries}
                onEntriesChange={handleChangeEntries}
              />
            </form>
          </>
        )}
      </div>
    </Dialog>
  );
};
