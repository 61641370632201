import { useCallback } from "react";

const useTour = (onStop: () => void) => {
  return useCallback(
    state => {
      if (state.action === "close" || state.action === "reset") {
        onStop();
      }
    },
    [onStop]
  );
};

export default useTour;
