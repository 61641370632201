import { useCallback, useEffect, useMemo, useState } from "react";
import KeyValueUtils from "./KeyValueUtils";
import { EntryType, IKeyValueEntry, IUiEntry } from "./KeyValueEditor";
import { IOption } from "./EntryLine/interface";
import { PropertyValueType } from "../../shared/types/common/graph";

export interface IEntryLinePartialProps {
  editable: boolean;
  type: EntryType;
  autoCompleteItems?: string[];
  selectOptions?: IOption[];
  value: string;
  valueType?: PropertyValueType;
}

export interface IEntry {
  id: string;
  valueProps: IEntryLinePartialProps;
  keyProps: IEntryLinePartialProps;
}

const defaultEntryFactory = (id: string): IUiEntry => ({
  id,
  keyProps: {
    value: "",
    type: "text",
    editable: true,
  },
  valueProps: {
    value: "",
    type: "text",
    editable: true,
  },
});

export const useKeyValueEntries = (
  entries: IEntry[],
  onChange: (entries: { [key: string]: string }) => void,
  entryFactory: (id: string) => IUiEntry = defaultEntryFactory,
  customValidator?: (entries: IKeyValueEntry[]) => string | null,
  onValueChange?: (newEntries: IEntry[]) => void
) => {
  const [isDirty, setIsDirty] = useState(false);
  const [errors, setErrors] = useState<string | null>(null);
  const [dataArray, setDataArray] = useState(entries);
  const onChangeDataArray = useCallback((dataArray) => {
    setIsDirty(true);
    setDataArray(dataArray);
  }, []);

  const toKeyValuePair = useCallback(
    (entry: IEntry) => ({
      key: entry.keyProps.value,
      value: entry.valueProps.value,
    }),
    []
  );

  const keyValuePairs = useMemo(() => dataArray.map(toKeyValuePair), [
    dataArray,
    toKeyValuePair,
  ]);

  useEffect(() => {
    setDataArray(entries);
    setIsDirty(false);
  }, [entries]);

  useEffect(() => {
    setErrors(KeyValueUtils.validate(keyValuePairs, customValidator));
  }, [customValidator, dataArray, keyValuePairs, toKeyValuePair]);

  const onUpdateEntry = useCallback(
    (index: number, part: string, value: string) => {
      const newEntries = dataArray.map((entry, i) =>
        i === index
          ? {
              ...entry,
              keyProps: {
                ...entry.keyProps,
                value: part === "key" ? value : entry.keyProps.value,
              },
              valueProps: {
                ...entry.valueProps,
                value: part === "value" ? value : entry.valueProps.value,
              },
            }
          : entry
      );
      onChangeDataArray(newEntries);
      if (onValueChange) {
        onValueChange(newEntries);
      }
    },
    [dataArray, onChangeDataArray, onValueChange]
  );

  const onAddEntry = useCallback(() => {
    onChangeDataArray([
      ...dataArray,
      entryFactory((dataArray.length + 1).toString()),
    ]);
  }, [dataArray, entryFactory, onChangeDataArray]);

  const onRemoveEntry = useCallback(
    (id: string) => {
      onChangeDataArray(dataArray.filter((entry) => entry.id !== id));
    },
    [dataArray, onChangeDataArray]
  );

  const onSave = useCallback(() => {
    onChange(KeyValueUtils.fromEntries(keyValuePairs));
  }, [keyValuePairs, onChange]);

  const onCancel = useCallback(() => {
    setDataArray(entries);
    setIsDirty(false);
  }, [entries]);

  return {
    dataArray,
    errors,
    isDirty,
    onSave,
    onCancel,
    onRemoveEntry,
    onAddEntry,
    onUpdateEntry,
  };
};

export default useKeyValueEntries;
