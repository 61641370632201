import {useEffect, useState} from "react";
import ConfigurationLoader from "./ConfigurationLoader";

const useAppConfig = () => {
  const [ready, setReady] = useState(ConfigurationLoader.isReady);

  useEffect(() => {
    const initAppConfig = async () => {
      return await ConfigurationLoader.init();
    };

    initAppConfig().then((config) => {
      setReady(ConfigurationLoader.isReady);
    });
  }, []);

  return [
    ready
  ];
};

export default useAppConfig;