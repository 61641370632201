import {
  TextArea as BPTextArea,
  ITextAreaProps as IBPTextAreaProps,
} from "@blueprintjs/core";
import { FC } from "react";
import * as React from "react";
import classNames from "classnames";
import withErrors from "./withErrors";
import styles from "./TextArea.module.scss";

interface ITextAreaProps extends IBPTextAreaProps {
  resizeable?: boolean;
}

const TextArea: FC<ITextAreaProps> = ({ resizeable, ...restProps }) => {
  const textareaClassNames = classNames(styles.gyTextarea, {
    [styles.nonResizeable]: !resizeable,
  });

  return <BPTextArea className={textareaClassNames} {...restProps} />;
};

export default withErrors(TextArea);
