import React, { FC } from "react";
import classNames from "classnames";
import styles from "./Content.module.scss";

export interface IContentProps {
  className?: string;
}

const Content: FC<IContentProps> = ({ children, className }) => (
  <div className={classNames(styles.sideBarContent, className)}>{children}</div>
);

export default Content;
