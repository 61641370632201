import React, { FC } from "react";
import styles from "./ActionsToolbar.module.scss";
import { Tooltip } from "../../../../../../../components/tooltip";
import { Button } from "../../../../../../../components/button";
import classNames from "classnames";
import { IconName } from "@blueprintjs/core";

interface IActionsToolbarButtonProps {
  onClick: () => void;
  disabled?: boolean;
  icon: IconName;
  className?: string;
  tooltipText: string;
  label?: string;
  active?: boolean;
}

export const ActionsToolbarButton: FC<IActionsToolbarButtonProps> = ({
  icon,
  className,
  onClick,
  tooltipText,
  label,
  disabled = false,
  active = false,
}) => (
  <Tooltip content={tooltipText}>
    <Button
      disabled={disabled}
      onClick={onClick}
      minimal={true}
      text={label}
      active={active}
      icon={icon}
      className={classNames(className, styles.button)}
    />
  </Tooltip>
);

interface IActionsToolbarProps {
  hasActiveWindow?: boolean;
}

export const ActionsToolbar: FC<IActionsToolbarProps> = ({
  children,
  hasActiveWindow,
}) => {
  return (
    <div
      className={classNames(styles.toolbar, { [styles.open]: hasActiveWindow })}
    >
      {children}
    </div>
  );
};

export const ToolbarContainer: FC = ({ children }) => (
  <div className={styles.toolbarContainer}>{children}</div>
);
