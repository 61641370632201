import React, { FC } from "react";
import {
  HTMLSelect as BPHTMLSelect,
  IHTMLSelectProps,
} from "@blueprintjs/core";
import classNames from "classnames";
import styles from './HTMLSelect.module.scss';

export const HTMLSelect: FC<IHTMLSelectProps> = (props) => (
  <BPHTMLSelect {...props} className={classNames(props.className, styles.htmlSelect)} />
);
