import { useCallback } from "react";
import {
  GraphEntity,
  IEdge,
  IEntityData,
  IGraph,
  INode,
} from "../../../../../components/graph-builder/types/GraphTypes";
import { TypeUtils } from "../../../../../utils/Type";
import NodeFactory from "../../../../../components/graph-builder/factory/NodeFactory";
import EdgeFactory from "../../../../../components/graph-builder/factory/EdgeFactory";
import { GraphUtils } from "../../../../../services/graph-builder";
import { DEFAULT_LAYOUT } from "../../../../../services/graph-builder/Layout/LayoutFactory";

interface IEntityMap {
  [label: string]: IEntityData;
}

const EDGE_SAP = "%%%";

export const useReduceGraphToSchema = () => {
  const findEntityLabelById = useCallback(
    (entities: GraphEntity[], id: number) => {
      const found = entities.find((e) => e.id === id);
      if (found) {
        return found.data.label;
      }
      return null;
    },
    []
  );
  const typeEntityDataProperties = useCallback((entity: GraphEntity) => {
    const { label, ...rest } = entity.data;
    const typed = Object.entries(rest).map(([key, value]) => [
      key,
      TypeUtils.inferType(value),
    ]);
    return {
      ...entity,
      title: entity.data.label,
      data: {
        label,
        ...Object.fromEntries(typed),
      },
    };
  }, []);

  /**
   * merge entity data properties, such that any non-label property is an array of values
   */
  const mergeEntitiesData = useCallback(
    (data1: IEntityData, data2: IEntityData) => {
      const { label, ...rest } = data2;
      return {
        ...(data1 || {}),
        ...rest,
      };
    },
    []
  );

  /**
   * generate a node entity map from graph edges
   */
  const reduceNodes = useCallback(
    (nodes: INode[]) => {
      return nodes.reduce((acc, cur) => {
        acc[cur.data.label] = mergeEntitiesData(acc[cur.data.label], cur.data);
        return acc;
      }, {} as IEntityMap);
    },
    [mergeEntitiesData]
  );

  /**
   * generate an edge entity map from graph edges
   */
  const reduceEdges = useCallback(
    (edges: IEdge[], nodes: INode[]) => {
      return edges.reduce((acc, cur) => {
        const sourceLabel = findEntityLabelById(nodes, cur.source);
        const targetLabel = findEntityLabelById(nodes, cur.target);
        const key = `${cur.data.label}${EDGE_SAP}${sourceLabel}${EDGE_SAP}${targetLabel}`;
        acc[key] = mergeEntitiesData(acc[key], cur.data);
        return acc;
      }, {} as IEntityMap);
    },
    [findEntityLabelById, mergeEntitiesData]
  );

  /**
   * find an entity id by it's label
   */
  const getEntityIdByLabel = useCallback(
    (entities: GraphEntity[], label: string) => {
      const found = entities.find((e) => e.data.label === label);
      if (found) {
        return found.id;
      }
      return -1;
    },
    []
  );

  /**
   * creates a scheme from entity maps
   */
  const getSchemeFromEntityMaps = useCallback(
    (nodesMap: IEntityMap, edgesMap: IEntityMap) => {
      let entityId = 1;
      const nodes = Object.entries(nodesMap).map(([label, data]) =>
        NodeFactory.create(entityId++, 0, 0, { ...data, label })
      );
      const edges = Object.entries(edgesMap).map(([key, data]) => {
        const chunks = key.split(EDGE_SAP);
        const [label, sourceLabel, targetLabel] = chunks;
        const sourceId = getEntityIdByLabel(nodes, sourceLabel);
        const targetId = getEntityIdByLabel(nodes, targetLabel);

        return EdgeFactory.create(entityId++, sourceId, targetId, {
          ...data,
          label,
        });
      });

      return { nodes, edges };
    },
    [getEntityIdByLabel]
  );

  /**
   * replace all entities in scheme with property types
   */
  const replacePropertiesWithTypes = useCallback(
    (scheme: IGraph) => {
      return {
        nodes: scheme.nodes.map(typeEntityDataProperties) as INode[],
        edges: scheme.edges.map(typeEntityDataProperties) as IEdge[],
      };
    },
    [typeEntityDataProperties]
  );

  return useCallback(
    (graph: IGraph) => {
      const nodesMap = reduceNodes(graph.nodes);
      const edgesMap = reduceEdges(graph.edges, graph.nodes);
      const propertyScheme = getSchemeFromEntityMaps(nodesMap, edgesMap);
      const typedScheme = replacePropertiesWithTypes(propertyScheme);
      return GraphUtils.generateGraphFromLayout(DEFAULT_LAYOUT, typedScheme);
    },
    [
      getSchemeFromEntityMaps,
      reduceEdges,
      reduceNodes,
      replacePropertiesWithTypes,
    ]
  );
};
