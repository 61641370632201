import { WorkspaceSnapshot } from "../../../components/workspace-snapshot";
import React, { FC, useCallback } from "react";
import { LayoutType } from "../../../services/graph-builder/Layout/LayoutFactory";
import { IGraph } from "../../../components/graph-builder/types/GraphTypes";
import { Card } from "../../../components/card";
import classNames from "classnames";
import styles from "./WorkspaceCard.module.scss";
import { Button } from "../../../components/button";
import Footer from "./Footer/Footer";
import { browserHistory } from "../../router/history";

interface IWorkspaceCardProps {
  id: number;
  layout: LayoutType | null;
  nodeLabelsColorMap: { [key: string]: string } | null;
  scheme: IGraph;
  className?: string;
  description?: string;
  title?: string;
  updatedAt: string;
  isPrivate: boolean;
  onChangePrivacy: (id: number, isPrivate: boolean) => void;
  onDeleteWorkspace: (id: number) => void;
  keyId: string;
  revision?: number;
  hasRevisions: boolean;
  view?: string;
  user?: string;
  isOwner: boolean;
}

const WorkspaceCard: FC<IWorkspaceCardProps> = ({
  id,
  layout,
  nodeLabelsColorMap,
  scheme,
  className,
  title,
  description,
  updatedAt,
  isPrivate,
  onChangePrivacy,
  onDeleteWorkspace,
  keyId,
  revision,
  hasRevisions,
  view,
  user,
  isOwner,
}) => {
  const handleChangePrivacy = useCallback(
    (isPrivate: boolean) => {
      onChangePrivacy(id, isPrivate);
    },
    [id, onChangePrivacy]
  );

  const handleDelete = useCallback(() => {
    onDeleteWorkspace(id);
  }, [id, onDeleteWorkspace]);

  const handleView = useCallback(() => {
    const path = [`/${keyId}`]
      .concat(revision ? [`${revision}`] : [])
      .join("/");
    browserHistory.push(path);
  }, [keyId, revision]);

  const handleRevisions = useCallback(() => {
    browserHistory.push(`/workspaces/${view}/${keyId}`);
  }, [keyId, view]);

  return (
    <Card
      data-cy={"WorkspaceCard"}
      elevation={2}
      className={classNames(styles.workspaceCard, className)}
    >
      <div className={styles.snapshotWrapper}>
        <div className={styles.overlay}>
          <Button
            onClick={handleView}
            minimal={true}
            data-cy={"ViewWorkspace"}
            icon={"eye-open"}
            text={"View"}
          />
          {hasRevisions && (
            <Button
              onClick={handleRevisions}
              minimal={true}
              data-cy={"Revisions"}
              icon={"updated"}
              text={"Revisions"}
            />
          )}
          {isOwner && (
            <Button
              onClick={handleDelete}
              minimal={true}
              data-cy={"DeleteWorkspace"}
              icon={"trash"}
              text={"Delete"}
            />
          )}
        </div>
        <WorkspaceSnapshot
          className={styles.snapshot}
          edgeWidth={2}
          nodeScaleFactor={0.3}
          nodeRadius={10}
          height={180}
          width={250}
          layoutType={layout}
          colorMap={nodeLabelsColorMap}
          scheme={scheme}
        />
      </div>
      <Footer
        onChangePrivacy={handleChangePrivacy}
        isPrivate={isPrivate}
        updatedAt={updatedAt}
        description={description}
        keyId={keyId}
        title={title}
        revision={revision}
        user={user}
        isOwner={isOwner}
      />
    </Card>
  );
};

export default WorkspaceCard;
