import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Header } from "../../components/header";
import styles from "./Workspaces.module.scss";
import useWorkspaces from "./useWorkspaces";
import { GlobalLoader } from "../../components/global-loader";
import WorkspaceCard from "./WorkspaceCard/WorkspaceCard";
import { NonIdealState } from "../../components/non-ideal-state";
import { InputGroup } from "../../components/form";
import { Alert } from "../../components/alert";
import { browserHistory } from "../router/history";
import { Button } from "../../components/button";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { Helmet } from "react-helmet";
import { Menu } from "./Menu";
import { IOrganization } from "../organization/types";
import { OrganizationService } from "../../services/organization";
import useAuth from "../authentication/hook/useAuth";

const Workspaces = () => {
  const { user } = useAuth();
  const [organization, setOrganization] = useState<IOrganization | null>(null);
  const {
    loading,
    workspaces,
    changePrivacy,
    confirmDeleteWorkspace,
    unstageFromDelete,
    stagedForDelete,
    search,
    stageDelete,
    key,
    view,
    updateFilter,
    filters,
    userOptions,
  } = useWorkspaces();

  useEffect(() => {
    const fetchOrganization = async () => {
      const data = await OrganizationService.getUserOrganization();
      setOrganization(data);
    };
    fetchOrganization();
  }, []);

  const handleSearch = useCallback(
    (evt) => {
      search(evt.currentTarget.value);
    },
    [search]
  );
  const handleGoBack = useCallback(() => {
    browserHistory.push("/");
  }, []);

  const breadcrumbs = useMemo(() => {
    const items = [{ href: "/workspaces", text: "All Workspaces" }];
    return key
      ? items.concat([{ href: `/workspaces/${key}`, text: key }])
      : items;
  }, [key]);

  const breadcrumbRenderer = useCallback(({ href, text }) => {
    return (
      <Button
        className={styles.breadCrumb}
        minimal={true}
        onClick={() => browserHistory.push(href)}
        text={text}
      />
    );
  }, []);

  const currentBreadcrumbRenderer = useCallback(({ href, text }) => {
    return (
      <Button
        className={styles.breadCrumb}
        minimal={true}
        disabled={true}
        text={text}
      />
    );
  }, []);

  const getTitle = () => {
    if (view === "my") return "My Workspaces";
    return organization ? `${organization.name}'s Workspaces` : "";
  };

  return (
    <div className={styles.workspaces}>
      <Helmet>
        <title>Gremlify - My Workspaces</title>
      </Helmet>
      <Header>
        <Button minimal={true} onClick={handleGoBack} text={"Home"} />
      </Header>
      {loading && <GlobalLoader />}
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        icon="trash"
        intent={"danger"}
        isOpen={stagedForDelete !== null}
        onCancel={unstageFromDelete}
        onConfirm={confirmDeleteWorkspace}
        canOutsideClickCancel={true}
      >
        <p>Are you sure you want to delete this Workspace?</p>
      </Alert>
      <div className={styles.wrapper}>
        <Menu
          filters={filters}
          updateFilter={updateFilter}
          activeItem={view}
          organization={organization}
          users={userOptions}
        />
        <div className={styles.content}>
          <h1 className={styles.title}>{getTitle()}</h1>
          <Breadcrumbs
            currentBreadcrumbRenderer={currentBreadcrumbRenderer}
            breadcrumbRenderer={breadcrumbRenderer}
            items={breadcrumbs}
          />
          <div className={styles.searchWrapper}>
            <InputGroup
              data-cy={"SearchWorkspaces"}
              onChange={handleSearch}
              placeholder={"Search..."}
              leftIcon={"search"}
              type={"search"}
            />
          </div>
          {workspaces.length === 0 ? (
            <NonIdealState icon={"search"} title={"No Workspaces found!"} />
          ) : (
            <div className={styles.list}>
              {workspaces.map((workspace) => (
                <WorkspaceCard
                  id={workspace.id}
                  onChangePrivacy={changePrivacy}
                  className={styles.item}
                  key={workspace.id}
                  keyId={workspace.key}
                  layout={workspace.layout}
                  nodeLabelsColorMap={workspace.nodeLabelsColorMap}
                  scheme={workspace.graph}
                  title={workspace.title}
                  updatedAt={workspace.updatedAt}
                  description={workspace.description}
                  onDeleteWorkspace={stageDelete}
                  isPrivate={workspace.isPrivate}
                  revision={workspace.revision}
                  hasRevisions={!key}
                  view={view}
                  user={workspace.user}
                  isOwner={workspace.user === user.name}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Workspaces;
