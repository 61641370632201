import { LanguageType } from "../../../shared/types/common/language";
import { SandboxAction } from "./SandboxActions";
import {
  IFavouriteQueriesState,
  initialState as favouriteQueriesInitialState,
  reducer as favouriteQueriesReducer,
} from "./../../favourite-queries/FavouriteQueriesReducer";
import {
  IHistoryState,
  initialState as historyInitialState,
  reducer as historyReducer,
} from "./../../history/HistoryReducer";
import {
  IResultsState,
  reducer as resultsReducer,
  initialState as resultsInitialState,
} from "../components/results/ResultsReducer";
import {
  IQueryConsoleState,
  reducer as queryConsoleReducer,
  initialState as queryConsoleInitialState,
} from "../components/query-console/QueryConsoleReducer";
import {
  IGraphBuilderState,
  reducer as graphBuilderReducer,
  initialState as graphBuilderInitialState,
} from "../components/builders/GraphBuilder/GraphBuilderReducer";
import {
  ISchemaBuilderState,
  reducer as schemaBuilderReducer,
  initialState as schemaBuilderInitialState,
} from "../components/builders/SchemaBuilder/SchemaBuilderReducer";
import {
  IMetadataState,
  reducer as metadataReducer,
  initialState as metadataInitialState,
} from "../../metadata/MetadataReducer";
import {
  IHelpState,
  reducer as helpReducer,
  initialState as helpInitialState,
} from "../../help/HelpReducer";
import {
  IUpdatesState,
  reducer as updatesReducer,
  initialState as updatesInitialState,
} from "../../updates/UpdatesReducer";
import {
  ILabelsState,
  reducer as labelsReducer,
  initialState as labelsInitialState,
} from "../components/builders/common/reducers/LabelsReducer";
import {
  IColorMapState,
  reducer as colorMapReducer,
  initialState as colorMapInitialState,
} from "../components/builders/common/reducers/ColorMapReducer";

export interface ISandBoxReducerState {
  ready: boolean;
  language: LanguageType;
  query: IQueryConsoleState;
  results: IResultsState;
  graphBuilder: IGraphBuilderState;
  history: IHistoryState;
  metadata: IMetadataState;
  updates: IUpdatesState;
  help: IHelpState;
  favouriteQueries: IFavouriteQueriesState;
  schemaBuilder: ISchemaBuilderState;
  labels: ILabelsState;
  colorMap: IColorMapState;
}

export const initialState: ISandBoxReducerState = {
  ready: false,
  language: LanguageType.GREMLIN,
  history: historyInitialState,
  graphBuilder: graphBuilderInitialState,
  query: queryConsoleInitialState,
  results: resultsInitialState,
  metadata: metadataInitialState,
  updates: updatesInitialState,
  help: helpInitialState,
  favouriteQueries: favouriteQueriesInitialState,
  schemaBuilder: schemaBuilderInitialState,
  labels: labelsInitialState,
  colorMap: colorMapInitialState
};

export const sandBoxReducer = (
  state: ISandBoxReducerState,
  action: SandboxAction
) => {
  if (action.type === "TOGGLE_READY") {
    return {
      ...state,
      ready: action.payload.ready ? action.payload.ready : !state.ready,
    };
  }

  return {
    ...state,
    favouriteQueries: favouriteQueriesReducer(action, state.favouriteQueries),
    history: historyReducer(action, state.history),
    results: resultsReducer(action, state.results),
    query: queryConsoleReducer(action, state.query),
    graphBuilder: graphBuilderReducer(action, state.graphBuilder),
    metadata: metadataReducer(action, state.metadata),
    help: helpReducer(action, state.help),
    updates: updatesReducer(action, state.updates),
    schemaBuilder: schemaBuilderReducer(action, state.schemaBuilder),
    labels: labelsReducer(action, state.labels),
    colorMap: colorMapReducer(action, state.colorMap)
  };
};
