import * as React from "react";
import { Tour } from "../../../../components/tour";
import { FC } from "react";
import tour from "./tour";

interface IHelpTourProps {
  run: boolean;
  onStop: () => void;
}

const HelpTour: FC<IHelpTourProps> = ({ run, onStop }) => {
  return <Tour onStop={onStop} run={run} steps={tour.steps} />;
};

export default HelpTour;
