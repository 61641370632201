import * as React from "react";
import AceEditor from "react-ace";
import { Tag } from "../tag";
import { IEditorProps } from "./EditorTypes";
import styles from "./Editor.module.scss";
import TagContainer from "../tag/TagContainer";
import "./Editor.scss";
import "ace-builds/src-min-noconflict/ext-language_tools";
// import 'brace/ext/searchbox';
import { setCompleters } from "ace-builds/src-noconflict/ext-language_tools";
// const ace = require("brace");
import functions from "../../data/editor/functions.json";

// const langTools = ace.require("ace/ext/language_tools");

export interface IFunctions {
  functionsName: string[];
}

const staticWordCompleter = {
  getDocTooltip: function (item: any) {
    if (item.doc) {
      item.docHTML = `
        <b style="font-size: 12px;font-weight: bolder;color: #6ab04c">${
          item.caption
        }<span style="color: white">${item.meta || ""}</span></b>
        <hr style="border: none; border-bottom: 1px solid #4a4c4e; width: 100%">
        <span style="font-size: 11px; color: #b1b1b1">${item.doc}</span>
      `;
    }
  },
  getCompletions: function (
    editor: any,
    session: any,
    pos: any,
    prefix: any,
    callback: any
  ) {
    const wordList = functions.steps;
    callback(
      null,
      wordList.map(function (entry) {
        const { step, args, doc, snippet } = entry;

        return {
          caption: step,
          doc,
          snippet,
          meta: args,
          completer: {
            insertMatch: function (editor: any, data: any) {
              editor.completer.insertMatch({
                value: data.snippet ? data.snippet : data.caption + "()",
              });
              const pos = editor.selection.getCursor();
              editor.gotoLine(pos.row + 1, pos.column - 1);
            },
          },
        };
      })
    );
  },
};

setCompleters([staticWordCompleter]);

const Editor = (props: IEditorProps) => {
  const { as: Component = "div" } = props;

  return (
    <Component className={styles.editorContainer}>
      <TagContainer>
        {props.renderCustomToolbar && props.renderCustomToolbar()}
        {props.label && (
          <Tag className={`tag-${props.name}`} minimal={true}>
            {props.label}
          </Tag>
        )}
      </TagContainer>
      <AceEditor
        className={styles.gremlifyEditor}
        enableLiveAutocompletion={true}
        enableSnippets={true}
        enableBasicAutocompletion={true}
        setOptions={{ useWorker: false }}
        {...props}
      />
    </Component>
  );
};

Editor.defaultProps = {
  width: "100%",
  height: "100%",
  showPrintMargin: false,
  allowedUploadFormats: "",
};

export default Editor;
