import { useCallback, useEffect, useMemo, useState } from "react";
import { EntityType } from "../../../../../../shared/types/common/enums";
import {
  IEntityData,
  IGraph,
} from "../../../../../../components/graph-builder/types/GraphTypes";
import { SchemaBuilderUtils } from "../SchemaBuilderUtils";

export const useCreation = (
  scheme: IGraph,
  entityType: EntityType | null,
  onCreate: (data: IEntityData) => void
) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (entityType === null) {
      setLoading(false);
    }
  }, [entityType]);

  const handleSubmit = useCallback(
    (data) => {
      setLoading(true);
      onCreate(data);
    },
    [onCreate]
  );

  const entries = useMemo(() => {
    return SchemaBuilderUtils.convertSchemaToDataEntries();
  }, []);

  return {
    entries,
    loading,
    handleSubmit,
  };
};
