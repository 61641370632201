import React, { FC, useCallback, useMemo } from "react";
import { GraphEntity } from "../../../types/GraphTypes";
import { KeyValueEditor } from "../../../../key-value-editor/index";
import styles from "./EntityEditor.module.scss";
import { IOption } from "../../../../key-value-editor/EntryLine/interface";
import { IKeyValueEntry } from "../../../../key-value-editor/KeyValueEditor";

interface IEntityEditor {
  entity: GraphEntity | null;
  save: (entity: GraphEntity) => void;
  valueOptions?: IOption[];
  keyOptions?: IOption[];
  customValidator?: (entries: IKeyValueEntry[]) => string | null;
  canModifyEntries?: boolean;
}

export const EntityEditor: FC<IEntityEditor> = ({
  entity,
  save,
  valueOptions,
  keyOptions,
  customValidator,
  canModifyEntries = true,
}) => {
  const onSave = useCallback(
    (entries) => {
      if (entity) {
        save({
          ...entity,
          data: entries,
        });
      }
    },
    [save, entity]
  );

  const makeValueProps = useCallback(
    () => ({
      value: valueOptions ? valueOptions[0].value.toString() : "",
      type: valueOptions ? ("select" as const) : ("text" as const),
      selectOptions: valueOptions,
      editable: true,
    }),
    [valueOptions]
  );

  const createEntry = useCallback(
    (id) => ({
      id,
      keyProps: {
        value: "",
        type: "text" as const,
        editable: true,
      },
      valueProps: makeValueProps(),
    }),
    [makeValueProps]
  );

  const entries = useMemo(() => {
    if (entity) {
      return Object.entries(entity.data)
        .map(([key, value], index) => ({
          id: index.toString(),
          keyProps: {
            value: key,
            editable: canModifyEntries && key !== "label",
            type: keyOptions ? ("select" as const) : ("text" as const),
            selectOptions: keyOptions,
          },
          valueProps: {
            value,
            editable: key !== "label" && true,
            type: valueOptions ? ("select" as const) : ("text" as const),
            selectOptions: valueOptions,
          },
        }))
        .sort(({ keyProps: { value } }) => (value === "label" ? -1 : 1));
    }

    return [];
  }, [canModifyEntries, entity, keyOptions, valueOptions]);

  return (
    <div className={styles.nodeEditor}>
      {entity ? (
        <div className={styles.editor}>
          <KeyValueEditor
            entriesWrapperClassName={styles.entries}
            onChange={onSave}
            entries={entries}
            entryFactory={createEntry}
            customValidator={customValidator}
            canModifyEntries={canModifyEntries}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
