import { useCallback, useState } from "react";
import { useAnalytics } from "../../shared/hooks";

const useTheme = () => {
  const [sendEvent] = useAnalytics();
  const [theme, setTheme] = useState("dark");

  const updateTheme = useCallback(
    (theme: string) => {
      sendEvent({
        category: "Settings",
        action: "Set Theme",
        label: theme,
      });

      setTheme(theme);
    },
    [sendEvent]
  );

  return {
    theme,
    setTheme: updateTheme,
  };
};

export default useTheme;
