import Joyride from "react-joyride";
import * as React from "react";
import { FC } from "react";
import { IStep } from "./TourBuilder";
import useTour from "./useTour";
import "./Tour.scss";

const defaultOptions = {
  options: {
    beaconSize: 48,
    overlayColor: "rgba(0, 0, 0, 0.5)",
    width: undefined,
    zIndex: 100,
  },
};

interface ITourProps {
  steps: IStep[];
  run: boolean;
  onStop: () => void;
}

const Tour: FC<ITourProps> = ({ steps, run, onStop }) => {
  const callback = useTour(onStop);
  return (
    <Joyride
      callback={callback}
      styles={defaultOptions}
      continuous={true}
      run={run}
      steps={steps}
    />
  );
};

export default Tour;
