import InputGroup, { IInputGroupProps } from "../form/InputGroup";
import * as React from "react";
import { FC, useCallback, useMemo, useState } from "react";
import { Menu, MenuItem } from "../menu";
import StringUtils from "../../utils/StringUtils/StringUtils";
import { PopOver } from "../popOver";
import "./AutoComplete.scss";

// TODO: make generic
interface IItemProps {
  value: string;
  onClick: (value: string) => any;
}

// TODO: make generic
interface IAutoCompleteProps {
  items: string[];
  value: string;
  name: string;
  inputProps?: IInputGroupProps;
  onChange?: (name: string, value: string) => any;
  className?: string;
}

const Item: FC<IItemProps> = ({ value, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);
  return <MenuItem onClick={handleClick} text={value} />;
};

const AutoComplete: FC<IAutoCompleteProps> = ({
  items,
  onChange,
  name,
  value,
  className,
  inputProps = {},
}) => {
  const [listShown, setListShown] = useState(false);
  const filteredItems = useMemo(
    () =>
      items.filter((item: string) =>
        StringUtils.subStringNotEqual(item, value)
      ),
    [items, value]
  );
  const toggleList = useCallback(() => {
    setListShown(!listShown);
  }, [listShown]);

  const handleItemSelect = useCallback(
    (value) => {
      if (onChange) {
        onChange(name, value);
      }
      toggleList();
    },
    [name, onChange, toggleList]
  );

  const handleChange = useCallback(
    (evt) => {
      if (onChange) {
        onChange(name, evt.target.value);
      }
    },
    [name, onChange]
  );

  const handleFocus = useCallback(() => {
    toggleList();
  }, [toggleList]);

  return (
    <div className={`auto-complete`}>
      <PopOver
        captureDismiss={true}
        popoverClassName={"popover"}
        isOpen={filteredItems.length > 0 && listShown}
      >
        <InputGroup
          {...inputProps}
          value={value || ""}
          name={name}
          autoComplete={"off"}
          onFocus={handleFocus}
          onChange={handleChange}
        />
        <Menu className={"list"}>
          {filteredItems.map((it: string) => (
            <Item value={it} onClick={handleItemSelect} key={it} />
          ))}
        </Menu>
      </PopOver>
    </div>
  );
};

export default AutoComplete;
