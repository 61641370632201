import { Intent, IToaster, Position, Toaster } from "@blueprintjs/core";

class NotificationService {
  private toaster: IToaster;

  constructor() {
    this.toaster = Toaster.create({
      position: Position.TOP_RIGHT
    });
  }

  public error(message: string) {
    this.notify(message, Intent.DANGER);
  }

  public success(message: string) {
    this.notify(message, Intent.SUCCESS);
  }

  private notify(message: string, intent: Intent = Intent.NONE) {
    this.toaster.show({ message, intent });
  }
}

export default new NotificationService();
