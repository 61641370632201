import React, { FC } from "react";
import styles from "./Menu.module.scss";
import { Button } from "../../../components/button";
import { IOrganization } from "../../organization/types";
import { browserHistory } from "../../router/history";
import { Filters } from "./Filters";
import { IFilterState } from "../useWorkspaces";

interface IMenuProps {
  activeItem?: string;
  organization: IOrganization | null;
  users: string[];
  updateFilter: (key: keyof IFilterState, value: string) => void;
  filters: IFilterState;
}

export const Menu: FC<IMenuProps> = ({
  activeItem,
  organization,
  users,
  updateFilter,
  filters,
}) => (
  <div className={styles.menu}>
    <ul className={styles.list}>
      <li className={styles.item}>
        <Button
          icon={"person"}
          className={styles.button}
          text={"My"}
          minimal
          large
          onClick={() => browserHistory.push("/workspaces/my")}
          active={activeItem === "my"}
        />
      </li>
      <li className={styles.item}>
        <Button
          icon={"people"}
          className={styles.button}
          text={"Organization"}
          minimal
          large
          onClick={() => browserHistory.push("/workspaces/organization")}
          active={activeItem === "organization"}
          disabled={organization === null}
        />
      </li>
    </ul>
    <Filters filters={filters} updateFilter={updateFilter} users={users} />
  </div>
);
