import { useCallback, useMemo } from "react";
import {
  GraphEntity,
  IGraph,
} from "../../../../../../components/graph-builder/types/GraphTypes";
import { useColorMap } from "./useColorMap";

interface IEntityDisplayProperties {
  edges: { [key: string]: string };
  nodes: { [key: string]: string };
}

export const useEnhancedGraph = (
  scheme: IGraph,
  displayId: boolean,
  displayProperties?: IEntityDisplayProperties
) => {
  const { colorMap } = useColorMap();

  const getEntityTitle = useCallback(
    (entity: GraphEntity) => {
      if (displayProperties) {
        const settings =
          entity.type === "node"
            ? displayProperties.nodes
            : displayProperties.edges;
        const prop = settings[entity.data.label];
        return prop ? entity.data[prop] || "-" : entity.data.label;
      }
      return entity.data.label;
    },
    [displayProperties]
  );

  return useMemo(
    () => ({
      nodes: scheme.nodes.map((n) => ({
        ...n,
        style: {
          color: colorMap[n.data.label],
        },
        typeText: displayId && n.id !== -1 ? ` (${n.id})` : "",
        title: getEntityTitle(n),
      })),
      edges: scheme.edges.map((e) => ({
        ...e,
        label: `${getEntityTitle(e)}${
          displayId && e.id !== -1 ? ` (${e.id})` : ""
        }`,
      })),
    }),
    [colorMap, displayId, getEntityTitle, scheme.edges, scheme.nodes]
  );
};
