import { useCallback, useContext, useEffect, useState } from "react";
import {
  GraphEntity,
  IGraph,
} from "../../../../../../components/graph-builder/types/GraphTypes";
import SandBoxContext from "../../../../SandBoxContext";
import { EntityType } from "../../../../../../shared/types/common/enums";
import NodeFactory from "../../../../../../components/graph-builder/factory/NodeFactory";
import EdgeFactory from "../../../../../../components/graph-builder/factory/EdgeFactory";
import {
  useSelectEdgeLabels,
  useSelectNodeLabels,
} from "../selectors/LabelsSelectors";

export const useLabels = (entityType: EntityType | null) => {
  const [state] = useContext(SandBoxContext);
  const edgeLabels = useSelectEdgeLabels(state);
  const nodeLabels = useSelectNodeLabels(state);
  const [currentLabel, setCurrentLabel] = useState<string>("");

  const getEntitiesLabels = useCallback(
    (entities: GraphEntity[]) =>
      entities.reduce(
        (labels, { id, data: { label } }) =>
          id === -1 || labels.includes(label) ? labels : labels.concat(label),
        [] as string[]
      ),
    []
  );

  const extractLastLabel = useCallback(
    (labels: string[], fallbackLabel: string) => {
      return labels[0] ? labels[0] : fallbackLabel;
    },
    []
  );

  const extractLabelsFromScheme = useCallback(
    (scheme: IGraph) => {
      const edgeLabels = getEntitiesLabels(scheme.edges);
      const nodeLabels = getEntitiesLabels(scheme.nodes);

      return { edgeLabels, nodeLabels };
    },
    [getEntitiesLabels]
  );
  useEffect(() => {
    const labels = entityType === EntityType.edge ? edgeLabels : nodeLabels;
    const fallbackLabel =
      entityType === EntityType.node
        ? NodeFactory.DefaultLabel
        : EdgeFactory.DefaultLabel;

    setCurrentLabel(extractLastLabel(labels, fallbackLabel));
  }, [edgeLabels, entityType, extractLastLabel, nodeLabels]);

  return { extractLabelsFromScheme, currentLabel, setCurrentLabel };
};
