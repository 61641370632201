import useAppConfig from "./services/config/useAppConfig";
import {useEffect, useState} from "react";
import SessionService from "./services/session/SessionService";
import {AppRestService} from "./services/communication";
import GoogleAnalyticsService from "./services/analytics/google/GoogleAnalyticsService";
import ConfigurationLoader from "./services/config/ConfigurationLoader";

export const useAppStart = () => {
  const [appConfigReady] = useAppConfig();
  const [appReady, setAppReady] = useState(false);

  useEffect(() => {
    const startApp = async () => {
      if (appConfigReady) {
        AppRestService.init();
        await GoogleAnalyticsService.init(ConfigurationLoader.get('GOOGLE_ANALYTICS_KEY'));
        await SessionService.start();
        setTimeout(() => setAppReady(true), 1000);
      }
    };
    startApp();
  }, [appConfigReady]);

  return [appReady];
};

export default useAppStart;
