import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { Button } from "../../../../../components/button";
import styles from "./QueryToolbar.module.scss";
import { QueryService } from "../../../../../services/query";
import { FavouriteQueriesService } from "../../../../../services/favourite-queries";
import AuthContext from "../../../../authentication/AuthContext";
import { AuthStatus } from "../../../../authentication/AuthTypes";
import SandBoxContext from "../../../SandBoxContext";

interface IQueryToolbarProps {
  onFormat: (formatted: string) => void;
}

const QueryToolbar: FC<IQueryToolbarProps> = ({ onFormat }) => {
  const [state, dispatch] = useContext(SandBoxContext);
  const [isLoading, setIsLoading] = useState(false);
  const { user, authStatus } = useContext(AuthContext);

  const query = useMemo(() => state.query.queries[state.query.index].query, [
    state.query.index,
    state.query.queries,
  ]);

  const format = useCallback(async () => {
    setIsLoading(true);
    const result = await QueryService.format(query);
    if (result) {
      onFormat(result);
    }
    setIsLoading(false);
  }, [query, onFormat]);
  const addToFavourites = useCallback(async () => {
    if (user.id) {
      const resultId = await FavouriteQueriesService.addToFavourites(
        user.id,
        query
      );
      if (resultId) {
        dispatch({
          type: "ADD_QUERY_TO_FAVOURITES",
          payload: { query: { query, id: resultId } },
        });
      }
    }
  }, [user.id, query, dispatch]);
  return (
    <div className={styles.queryToolbar}>
      <div className={styles.actionsWrapper}>
        {
          <div className={styles.actions}>
            {authStatus === AuthStatus.CONNECT && (
              <Button
                onClick={addToFavourites}
                data-cy={"AddToFavourites"}
                className={styles.format}
                text={"Add To Favourites"}
                disabled={query.length === 0}
                minimal={true}
                icon={"star"}
              />
            )}
            <Button
              loading={isLoading}
              onClick={format}
              data-cy={"Format"}
              className={styles.format}
              disabled={query.length === 0}
              text={"Format"}
              minimal={true}
              icon={"clean"}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default QueryToolbar;
