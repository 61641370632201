import { LanguageType } from "../../types/common/language";
import { LayoutType } from "../../../services/graph-builder/Layout/LayoutFactory";

export const DEFAULT_LANGUAGE = LanguageType.GREMLIN;

export const THEMES = {
  dark: {
    color: "#000",
  },
};

export const LAYOUTS = {
  dagre: {
    label: "Force Directed (Dagre)",
    value: LayoutType.Dagre,
  },
  viva: {
    label: "Force Directed (Viva)",
    value: LayoutType.Viva,
  },
};

export const PropertyValueTypeMap = {
  STRING: "STRING",
  BOOLEAN: "BOOLEAN",
  NUMBER: "NUMBER",
};

export const MAX_QUERY_TABS = 5;
