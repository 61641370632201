import React, { useCallback, useState } from "react";
import { SideBar } from "./SideBar";
import { GraphBuilder } from "./GraphBuilder";
import { SchemaBuilder } from "./SchemaBuilder";
import { useCleanGraph } from "./useCleanGraph";
import { useAnalytics } from "../../../../shared/hooks";
import { SyncDialog } from "./common/components/SyncDialog";
import { useSyncGraphAndSchema } from "./common/useSyncGraphAndSchema";

export const BuildersToggler = () => {
  const [view, setView] = useState("graph");
  const [sendEvent] = useAnalytics();
  const { isSyncDialogOpen, cancelSync, confirmSync } = useSyncGraphAndSchema();
  useCleanGraph();

  const handleSyncCancel = useCallback(() => {
    cancelSync();
    setView("graph");
  }, [cancelSync]);
  const handleChangeView = useCallback(
    (view) => {
      sendEvent({ category: "SanBox", action: "ChangeView", label: view });
      setView(view);
    },
    [sendEvent]
  );

  return (
    <>
      <SyncDialog
        isOpen={isSyncDialogOpen}
        onClose={handleSyncCancel}
        onConfirm={confirmSync}
      />
      <SideBar activeView={view} onChangeView={handleChangeView} />
      {view === "graph" ? (
        <GraphBuilder />
      ) : (
        <SchemaBuilder setView={setView} />
      )}
    </>
  );
};
