import { useCallback } from "react";
import {
  GraphEntity,
  IEdge,
  IGraph,
} from "../../../../../../components/graph-builder/types/GraphTypes";
import { useGetEntityLabel } from "./useGetEntityLabel";

export const useEdgePredicate = () => {
  const getEntityLabel = useGetEntityLabel();
  return useCallback(
    (edge: GraphEntity, schema: IGraph, graph: IGraph) => {
      return schema.edges.find((e) => {
        const schemaSourceLabel = getEntityLabel(schema.nodes, e.source);
        const schemaTargetLabel = getEntityLabel(schema.nodes, e.target);
        const graphSourceLabel = getEntityLabel(
          graph.nodes,
          (edge as IEdge).source
        );
        const graphTargetLabel = getEntityLabel(
          graph.nodes,
          (edge as IEdge).target
        );
        return (
          e.data.label === edge.data.label &&
          graphTargetLabel === schemaTargetLabel &&
          graphSourceLabel === schemaSourceLabel
        );
      });
    },
    [getEntityLabel]
  );
};

export const useNodePredicate = () => {
  return useCallback(
    (node: GraphEntity, schema: IGraph) =>
      schema.nodes.find((n) => n.data.label === node.data.label),
    []
  );
};
