import * as React from "react";
import {
  FormGroup as BPFormGroup,
  IFormGroupProps as IBPFormGroupProps,
} from "@blueprintjs/core";
import { FC } from "react";
import withErrors from "./withErrors";
import styles from "./FormGroup.module.scss";
import classNames from "classnames";

interface IFormGroupProps {
  errors?: string;
  required?: boolean;
}

const FormGroup: FC<IFormGroupProps & IBPFormGroupProps> = ({
  errors = "",
  ...props
}) => {
  const formGroupClassNames = classNames(styles.gyFormGroup, props.className);

  return (
    <BPFormGroup
      labelInfo={props.required ? "(required)" : ""}
      helperText={errors ? errors : props.helperText || ""}
      {...props}
      className={formGroupClassNames}
    />
  );
};

export default withErrors(FormGroup);
