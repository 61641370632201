import { ISessionService } from "./SessionServiceTypes";
import { AppRestService } from "../communication";
import FingerprintService from "../fingerprint/FingerprintService";

class SessionService implements ISessionService {
  /**
   * create a finger print and start a new session
   */
  public async start() {
    const fingerprint = await FingerprintService.get();
    const response = await AppRestService.post("session/start", {
      fingerprint,
    });
    this.pairSessionToken(response.data);

    return response.data;
  }

  public async end() {
    const response = await AppRestService.post("session/end", {});
    return response.data;
  }

  /**
   *
   * @param token
   */
  private pairSessionToken(token: string) {
    AppRestService.setSessionToken(token);
  }
}

export default new SessionService();
