import RestService from "./RestService";
import ConfigurationLoader from "../config/ConfigurationLoader";

class AppRestService extends RestService {
  public init() {
    this.setBaseUrl(ConfigurationLoader.get("API_BASE_URL"));
  }

  public setConnectionMetadata(connection: string) {
    this.addRequestInterceptor("connection", ({ headers, ...rest }) => ({
      ...rest,
      headers: {
        ...headers,
        "X-Connection": connection,
      },
    }));
  }

  public setAccessToken(token: string) {
    this.addRequestInterceptor("authorization", ({ headers, ...rest }) => ({
      ...rest,
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    }));
  }

  public setSessionToken(token: string) {
    this.addRequestInterceptor("session", ({ headers, ...rest }) => ({
      ...rest,
      headers: {
        ...headers,
        "X-GremlifyId": token,
      },
    }));
  }

  public clearConnectionMetadata() {
    this.ejectInterceptor("connection");
  }

  public clearAccessToken() {
    this.ejectInterceptor("authorization");
  }
}

export default new AppRestService("", {
  "Content-Type": "application/json",
});
