export enum HttpStatus {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  SERVER_ERROR = 500
}

export type RequestMethodType = 'GET' | 'POST' | 'PUT' | 'DELETE';

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export interface IObject {
  [key: string]: any
}

export interface IResponse<D> {
  status: HttpStatus;
  data: D;
}

export interface IRestService {
  get: (url: string, query: IObject) => Promise<IResponse<any>>;
  put: (url: string, body: IObject) => Promise<IResponse<any>>;
  post: (url: string, body: IObject) => Promise<IResponse<any>>;
  delete: (url: string) => Promise<IResponse<any>>;
}