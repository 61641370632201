import React, { FC } from "react";
import BoringAvatar from "boring-avatars";

interface IAvatarProps {
  name: string;
  size: number;
  className?: string;
}

export const Avatar: FC<IAvatarProps> = ({ name, size, className }) => (
  <div className={className}>
    <BoringAvatar
      size={size}
      name={name}
      variant="beam"
      colors={["#FD4E6D", "#525C5A", "#56877D", "#8CCC81", "#1a1e22"]}
    />
  </div>
);
