import {
  AuthStatus,
  IUser,
  IUserLogIn,
  IUserSignUp,
  userDefaultState,
  UserStatus,
} from "../AuthTypes";
import { useCallback, useEffect, useState } from "react";
import { LocalStorageService } from "../../../services/localStorage/index";
import JwtUtils from "../../../utils/JwtUtils/Jwt";
import { UserService } from "../../../services/user/index";
import useAppConfig from "../../../services/config/useAppConfig";
import { browserHistory } from "../../router/history";
import { OrganizationService } from "../../../services/organization";

export const useAuth = () => {
  const [user, setUser] = useState<IUser>(userDefaultState);
  const [status, setStatus] = useState<UserStatus | null>(null);
  const [appConfigReady] = useAppConfig();
  const [authStatus, setAuthStatus] = useState<AuthStatus>(
    AuthStatus.DISCONNECT
  );

  useEffect(() => {
    const resolveRedirectPath = async () => {
      const hasPendingInvitation = await OrganizationService.getPendingInvitation();
      switch (true) {
        case status === UserStatus.CHANGE_PASSWORD:
          browserHistory.replace("/user/changeTempPassword");
          break;
        case hasPendingInvitation:
          browserHistory.replace("/organization/invitation");
          break;
      }
    };
    if (status && AuthStatus.CONNECT) {
      resolveRedirectPath();
    }
  }, [status]);

  useEffect(() => {
    const startAuth = async () => {
      const authToken = LocalStorageService.get("token");
      if (appConfigReady && authToken) {
        const user = JwtUtils.decode(authToken);
        UserService.setAccessToken(authToken);
        const res = await UserService.validateToken();
        if (!res) {
          UserService.logOut();
        } else {
          setStatus(res.data);
          setUser(user);
          setAuthStatus(AuthStatus.CONNECT);
        }
      }
    };
    startAuth();
  }, [appConfigReady]);

  const login = useCallback(async (loginDetails: IUserLogIn) => {
    setAuthStatus(AuthStatus.CONNECTING);
    const user = await UserService.login(
      loginDetails.email,
      loginDetails.password
    );
    const res = await UserService.validateToken();
    if (user && res) {
      setStatus(res.data);
      setUser(user);
      setAuthStatus(AuthStatus.CONNECT);
    } else {
      setAuthStatus(AuthStatus.DISCONNECT);
    }
    return user;
  }, []);

  const signUp = useCallback(async (signUpDetails: IUserSignUp) => {
    setAuthStatus(AuthStatus.CONNECTING);
    const user = await UserService.signUp(
      signUpDetails.name,
      signUpDetails.email,
      signUpDetails.password
    );
    if (user) {
      setUser(user);
      setAuthStatus(AuthStatus.CONNECT);
    } else {
      setAuthStatus(AuthStatus.DISCONNECT);
    }
    return user;
  }, []);

  const logOut = useCallback(async () => {
    UserService.logOut();
    setUser(userDefaultState);
    setAuthStatus(AuthStatus.DISCONNECT);
  }, []);

  const resetPasswordRequest = useCallback(async (email: string) => {
    return await UserService.resetPassword(email);
  }, []);

  const changePassword = useCallback(
    async (password: string, confirmPassword: string, token: string) => {
      return await UserService.changePassword(password, confirmPassword, token);
    },
    []
  );

  const changeOldPassword = useCallback(
    async (
      oldPassword: string,
      newPassword: string,
      confirmPassword: string
    ) => {
      const result = await UserService.changeOldPassword(
        oldPassword,
        newPassword,
        confirmPassword
      );
      if (result) {
        setStatus(UserStatus.ACTIVE);
      }
      return result;
    },
    []
  );

  return {
    login,
    signUp,
    logOut,
    user,
    authStatus,
    resetPasswordRequest,
    changePassword,
    changeOldPassword,
  };
};

export default useAuth;
