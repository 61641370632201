import { default as CHeader } from "../../../../components/header/Header";
import HeaderContents from "./HeaderContents";
import * as React from "react";

const Header = () => {
  return (
    <CHeader>
      <HeaderContents />
    </CHeader>
  );
};

export default Header;
