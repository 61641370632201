import { useCallback, useContext, useState } from "react";
import { LanguageType } from "../../../shared/types/common/language";
import { GraphUtils } from "../../../services/graph-builder";
import SandBoxContext from "../SandBoxContext";
import useRunService from "../../../services/run/useRunService";
import { useAnalytics } from "../../../shared/hooks";
import { useColorMap } from "../components/builders/common";
import { useSelectCurrentQuery } from "../components/query-console/QuerySelectors";

export const useRun = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const [isLoading, setIsLoading] = useState(false);
  const [RunService] = useRunService(state.language);
  const [sendEvent] = useAnalytics();
  const { assignColorsToGraph } = useColorMap();
  const currentQuery = useSelectCurrentQuery(state);

  const run = useCallback(async () => {
    setIsLoading(true);
    const runResult = await RunService.run(
      LanguageType.GREMLIN,
      currentQuery.query,
      state.graphBuilder.scheme,
      state.schemaBuilder.scheme
    );
    if (runResult) {
      const { nextGraph, result, error } = runResult;

      sendEvent({
        category: "Sandbox",
        action: "Run Pressed",
      });

      dispatch({ type: "RUN_FINISHED", payload: { results: result, error } });
      dispatch({
        type: "ADD_QUERY_TO_HISTORY",
        payload: { query: currentQuery.query },
      });
      if (nextGraph) {
        // flatten the raw graph response
        const raw = GraphUtils.buildGraphFromResponse(nextGraph);
        const flatNextGraph = GraphUtils.generateGraphFromLayout(
          state.graphBuilder.layout,
          raw
        );
        assignColorsToGraph(flatNextGraph);
        dispatch({
          type: "UPDATE_GRAPH",
          payload: { scheme: flatNextGraph },
        });
      }
    }
    setIsLoading(false);
  }, [
    RunService,
    currentQuery.query,
    state.graphBuilder.scheme,
    state.graphBuilder.layout,
    state.schemaBuilder.scheme,
    sendEvent,
    dispatch,
    assignColorsToGraph,
  ]);

  return {
    isLoading,
    run,
  };
};
