// import { Dialog } from "../../components/dialog";
// import { Button } from "../../components/button";
// import * as React from "react";
import styles from "./Templates.module.scss";
// import { FC, useCallback } from "react";
// import { ButtonGroup } from "../../components/button-group";
// import { Checkbox } from "../../components/form";
import { NonIdealState } from "../../components/non-ideal-state";
import React, { FC } from "react";
import { Button } from "../../components/button";
import { ButtonGroup } from "../../components/button-group";

interface IConfirmationDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  // dontShowAgain: boolean;
  // onChangeDontShowAgain: (dontShowAgain: boolean) => void;
}

const ConfirmationDialog: FC<IConfirmationDialogProps> = ({
  onConfirm,
  onClose,
  // dontShowAgain,
  // onChangeDontShowAgain,
}) => {
  // const handleDontShowAgainChange = useCallback(
  //   (e) => {
  //     onChangeDontShowAgain(e.target.checked);
  //   },
  //   [onChangeDontShowAgain]
  // );

  return (
    <div className={styles.confirmation}>
      <NonIdealState
        className={styles.confirmationContent}
        icon={"warning-sign"}
        description={
          <>
            Loading a template will override your Graph and remove your Schema,
            if exists.
            <br />
            <br />
            Are you sure?
          </>
        }
        action={
          <ButtonGroup className={styles.buttonGroup}>
            <Button
              fill={true}
              onClick={onClose}
              className={styles.button}
              text={"No"}
            />
            <Button
              onClick={onConfirm}
              fill={true}
              className={styles.button}
              text={"Yes"}
              data-cy={"Confirm"}
              intent={"success"}
            />
          </ButtonGroup>
        }
      />
    </div>
  );
};

export default ConfirmationDialog;
