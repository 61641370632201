import React, { FC, useCallback } from "react";
import { Tooltip } from "../tooltip";
import classNames from "classnames";

export interface IItemProps {
  title: string;
  name: string;
  onClick: (name: string) => void;
  className?: string;
  id?: string;
  disabled: boolean;
}

const Item: FC<IItemProps> = ({
  title,
  onClick,
  name,
  children,
  className,
  id,
  disabled,
}) => {
  const handleClick = useCallback(() => {
    onClick(name);
  }, [onClick, name]);

  const buttonClassNames = classNames(className);
  return (
    <Tooltip content={title}>
      <button
        id={id}
        disabled={disabled}
        className={buttonClassNames}
        onClick={handleClick}
      >
        {children}
      </button>
    </Tooltip>
  );
};

export default Item;
