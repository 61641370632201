import { Spinner as BPSpinner } from "@blueprintjs/core";
import React, { FC } from "react";
import CloudSpinner from "./Cloud";

export enum SpinnerType {
  SPINNER,
  FLOATING_GREMLIN
}

interface ISpinnerProps {
  size?: number;
  type?: SpinnerType;
}

const Spinner: FC<ISpinnerProps> = ({
  size,
  type = SpinnerType.SPINNER
}) => {
  if (type === SpinnerType.FLOATING_GREMLIN) {
    return <CloudSpinner size={150} />;
  } else {
    return <BPSpinner size={size} />;
  }
};
export default Spinner;
