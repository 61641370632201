import {useCallback, useMemo, useState} from "react";

const usePagination = (items: unknown[], perPageNum: number) => {
  const [currentPage, setCurrentPage] = useState(0);
  const totalItemsNum = items.length;
  const numPages = Math.ceil(totalItemsNum / perPageNum);

  const goToNextPage = useCallback(() => {
    if (currentPage < numPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, numPages]);

  const goToPreviousPage = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  const currentPageItems = useMemo(() => {
    return items.slice(currentPage * perPageNum, currentPage * perPageNum + perPageNum);
  },[currentPage, items, perPageNum]);

  return {
    currentPageItems,
    currentPage,
    goToPreviousPage,
    goToNextPage,
    numPages,
  };
};

export default usePagination;
