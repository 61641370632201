import React, { useEffect } from "react";
import "./App.css";
import { Mimic } from "./features/mimic";
import useAppStart from "./useAppStart";
import ThemeProvider from "./features/theme/ThemeContextProvider";
import useTheme from "./features/theme/useTheme";
import styles from "./App.module.scss";
import AuthProvider from "./features/authentication/AuthContextProvider";
import useAuth from "./features/authentication/hook/useAuth";
import { Router } from "./features/router";

const App: React.FC = () => {
  const [appReady] = useAppStart();
  const { theme, setTheme } = useTheme();
  const {
    login,
    signUp,
    user,
    authStatus,
    logOut,
    resetPasswordRequest,
    changePassword,
    changeOldPassword,
  } = useAuth();

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(`theme-${theme}`);
  }, [theme]);

  return (
    <AuthProvider
      user={user}
      authStatus={authStatus}
      login={login}
      signUp={signUp}
      changeOldPassword={changeOldPassword}
      logOut={logOut}
      resetPasswordRequest={resetPasswordRequest}
      changePassword={changePassword}
    >
      <ThemeProvider theme={theme} setTheme={setTheme}>
        <div className={`App bp3-dark theme-${theme} ${styles.app}`}>
          {appReady ? <Router /> : <Mimic />}
        </div>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
