import { SandboxAction } from "../sandbox/reducers/SandboxActions";

export type HistoryAction = {
  type: "ADD_QUERY_TO_HISTORY";
  payload: { query: string };
};

export interface IHistoryState {
  items: string[];
}

export const initialState = {
  items: [],
};

export const reducer = (action: SandboxAction, state: IHistoryState) => {
  switch (action.type) {
    case "ADD_QUERY_TO_HISTORY":
      return {
        ...state,
        items: [action.payload.query, ...state.items],
      };
    case "CLEAR_WORKSPACE":
      return initialState;
  }

  return state;
};
