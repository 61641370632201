import { SandboxAction } from "../../../reducers/SandboxActions";
import { IGraph } from "../../../../../components/graph-builder/types/GraphTypes";
import { LayoutType } from "../../../../../services/graph-builder/Layout/LayoutFactory";

export type SchemaBuilderAction =
  | {
      type: "UPDATE_SCHEMA_ID";
      payload: { id: string };
    }
  | { type: "UPDATE_SCHEMA"; payload: { scheme: IGraph } }
  | { type: "UPDATE_SCHEMA_SUCCESS" }
  | {
      type: "UPDATE_SCHEMA_LAYOUT";
      payload: { scheme: IGraph; layout: LayoutType };
    }
  | {
      type: "UPDATE_COLOR_MAP";
      payload: { nodeLabelsColorMap: { [key: string]: string } };
    }
  | { type: "RESET_SCHEMA" };

export interface ISchemaBuilderState {
  scheme: IGraph;
  id: string;
  isDirty: boolean;
  lastUpdatedTime: number | null;
}

export const initialState: ISchemaBuilderState = {
  scheme: {
    nodes: [],
    edges: [],
  },
  id: "initialScheme",
  lastUpdatedTime: null,
  isDirty: true,
};

export const reducer = (
  action: SandboxAction,
  state: ISchemaBuilderState
): ISchemaBuilderState => {
  switch (action.type) {
    case "SET_WORKSPACE":
      return {
        ...state,
        lastUpdatedTime: Date.now(),
        scheme: action.payload.schema || state.scheme,
      };
    case "CLEAR_WORKSPACE":
      return {
        ...state,
        scheme: initialState.scheme,
        lastUpdatedTime: Date.now(),
      };
    case "UPDATE_SCHEMA":
      const { scheme } = action.payload;
      return {
        ...state,
        lastUpdatedTime: Date.now(),
        isDirty: true,
        scheme,
      };
    case "UPDATE_SCHEMA_ID":
      return {
        ...state,
        id: action.payload.id,
      };
    case "UPDATE_SCHEMA_LAYOUT":
      return {
        ...state,
        scheme: action.payload.scheme,
      };
    case "UPDATE_SCHEMA_SUCCESS":
      return {
        ...state,
        isDirty: false,
        lastUpdatedTime: Date.now(),
      };
    case "RESET_SCHEMA":
      return {
        ...state,
        scheme: { ...initialState.scheme },
      };
  }

  return state;
};
