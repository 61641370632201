import React from "react";

const ChefHatIcon = () => (
  <svg width="26px" height="26px" viewBox="0 0 150 150">
    <path
      fill={'none'}
      d="M72.5,14.5 C65.7336013,14.5 59.4575324,16.5998838 54.3372297,20.1840957 C49.1050242,23.8466396 45.0794875,29.0589423 42.5,35.5 C35.5964406,35.5 29.3464406,38.2982203 24.8223305,42.8223305 C20.2982203,47.3464406 17.5,53.5964406 17.5,60.5 C17.5,67.4035594 20.2982203,73.6535594 24.8223305,78.1776695 C29.3464406,82.7017797 35.5964406,85.5 42.9911938,85.4952728 L43.5008338,85.4854553 L43.5000098,127.5 L105.50001,127.5 L105.500834,85.4999981 C112.492976,85.4998268 118.81458,82.6290594 123.351562,78.0020609 C127.773381,73.4925092 130.5,67.3146706 130.5,60.5 C130.5,53.5964406 127.70178,47.3464406 123.17767,42.8223305 C118.653559,38.2982203 112.403559,35.5 105.5,35.5 C104.656857,35.5 103.823459,35.5417207 102.48546,35.3001599 C100.18322,29.1390654 96.0363891,23.8778131 90.7171376,20.1879667 C85.5469503,16.6015228 79.2688762,14.5 72.5,14.5 Z"
      id="Combined-Shape"
      strokeWidth={6}
    />
    <g
      id="Group"
      transform="translate(62.000000, 86.000000)"
      strokeLinecap="square"
      strokeWidth="6"
    >
      <line x1="0.5" y1="0.5" x2="0.5" y2="25" id="Line" />
      <line x1="13.5" y1="0.5" x2="13.5" y2="25" id="Line" />
      <line x1="26.5" y1="0.5" x2="26.5" y2="25" id="Line" />
    </g>
    <path
      fill={'none'}
      d="M128,63 C128,50.2974508 117.702549,40 105,40"
      id="Path"
    />
  </svg>
);

export default ChefHatIcon;
