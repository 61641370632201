import React, { useContext, useEffect } from "react";
import SandBoxContext from "../sandbox/SandBoxContext";
import { Icon } from "../../components/icon";
import styles from "./SideMenu.module.scss";
import { LocalStorageService } from "../../services/localStorage";
import { HIDE_NEW_UPDATES } from "../sandbox/reducers/SandboxActions";
import GoogleAnalyticsService from "../../services/analytics/google/GoogleAnalyticsService";
import { INewUpdatesContent } from "../sandbox/components/header/updates/NewUpdatesPopOver";
const newUpdatesContent: INewUpdatesContent = require("./../../data/updates/updates.json");

const UpdatesToggle = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  useEffect(() => {
    const lastSeenUpdate = LocalStorageService.get("last_seen_update");
    const shouldShowDot = Boolean(
      !lastSeenUpdate || parseInt(lastSeenUpdate) < newUpdatesContent.version
    );
    if (!shouldShowDot) {
      dispatch({ type: HIDE_NEW_UPDATES });
    }
  }, [state.updates.newUpdate, dispatch]);

  const onClick = () => {
    GoogleAnalyticsService.sendEvent({
      action: "Show updates",
      category: `Sandbox`,
    });
    LocalStorageService.set(
      "last_seen_update",
      newUpdatesContent.version.toString()
    );
    dispatch({ type: HIDE_NEW_UPDATES });
  };

  return (
    <div className={styles.updates} onClick={onClick}>
      {state.updates.newUpdate && (
        <Icon
          className={styles.dotIcon}
          icon={"full-circle"}
          iconSize={10}
          intent={"danger"}
          data-cy={"UpdatesNotificationBadge"}
        />
      )}
      <Icon icon={"inbox"} />
    </div>
  );
};

export default UpdatesToggle;
