import React, { FC } from "react";
import { Alert } from "../../../../../../../components/alert";

interface ISchemaReductionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const SyncDialog: FC<ISchemaReductionDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => (
  <Alert
    data-cy={"SchemaReduction"}
    cancelButtonText="No. Clear Schema"
    confirmButtonText="Yes"
    icon="random"
    intent={"primary"}
    isOpen={isOpen}
    onCancel={onClose}
    onConfirm={onConfirm}
    canOutsideClickCancel={false}
  >
    <p>
      Your Graph contains elements that do not apply to the workspace Schema.
    </p>
    <p>
      If you wish to keep your Schema, those elements will be removed from the
      Graph.
    </p>
    <p>Would you like to sync your Graph with the new Schema?</p>
  </Alert>
);
