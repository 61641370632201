import {useMemo, useState} from "react";
import {LanguageType} from "../../shared/types/common/language";
import RunnerServiceProvider from "./RunnerServiceProvider";
import {IRunService} from "./RunServiceTypes";

const useRunService = (initLanguage: LanguageType): [IRunService, any] => {
  const [language, setLanguage] = useState(initLanguage);

  const RunService = useMemo(() => RunnerServiceProvider.getInstance(language)
    ,[language]
  );

  return [
    RunService,
    setLanguage
  ];
};

export default useRunService;