import * as React from "react";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import SandBoxContext from "../../../SandBoxContext";
import { GraphBuilder as DiGraphBuilder } from "../../../../../components/graph-builder";
import styles from "./GraphBuilder.module.scss";
import useGraphBuilder from "./useGraphBuilder";
import { CreationDialog } from "./CreationDialog";
import { FirstInstructions } from "../common/components/FirstInstructions";
import GraphBuilderContextMenu from "./GraphBuilderContextMenu/GraphBuilderContextMenu";
import { GraphActionsToolbar } from "./GraphActionsToolbar";
import { ExportResultDialog } from "./ExportResultDialog";
import { useAnalytics } from "../../../../../shared/hooks";
import { useToolbarWindows } from "../common/components/ActionsToolbar";
import { useEnhancedGraph } from "../common/hooks/useEnhancedGraph";

export const GraphBuilder = memo(() => {
  const [state] = useContext(SandBoxContext);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [sendEvent] = useAnalytics();
  const {
    graph,
    selected,
    onMaybeCreateNode,
    onMaybeCreateEdge,
    onCreateEntity,
    onSwapEdge,
    onDeleteNode,
    onDeleteEdge,
    onUpdateEntityData,
    lastEntityLabels,
    creatingEntityOfType,
    closeCreationDialog,
    onSelectNode,
    onSelectEdge,
    onCopy,
    onPaste,
    onUpdateNodePosition,
    updateLabelColor,
    colorMap,
    onDeleteEntity,
    updateLayout,
    validateEntityEntries,
  } = useGraphBuilder();

  const enhancedGraph = useEnhancedGraph(
    state.graphBuilder.scheme,
    true,
    state.graphBuilder.displayByProperty
  );

  const {
    activeWindow,
    handleClick: handleWindowTriggerClick,
  } = useToolbarWindows();
  const entityKeyOptions = useMemo(() => [{ value: "1", label: "hello" }], []);

  const contextMenu = useMemo(
    () => (
      <GraphBuilderContextMenu
        selectedEntity={selected}
        onSelectLayout={updateLayout}
        schemeLayout={state.graphBuilder.layout}
      />
    ),
    [selected, state.graphBuilder.layout, updateLayout]
  );

  const toggleExportModal = useCallback(() => {
    sendEvent({
      category: "Graph",
      action: "Export to query",
    });
    setIsExportDialogOpen(!isExportDialogOpen);
  }, [isExportDialogOpen, sendEvent]);

  const handlers = useMemo<{ [key: string]: Function }>(
    () => ({
      export: toggleExportModal,
      help: handleWindowTriggerClick("help"),
      settings: handleWindowTriggerClick("settings"),
    }),
    [handleWindowTriggerClick, toggleExportModal]
  );

  const handleActionClick = useCallback(
    (action: string) => {
      if (handlers[action]) {
        handlers[action].apply(null, []);
      }
    },
    [handlers]
  );

  const disabledActions = useMemo(() => {
    const disabled = [];
    const nodes = state.graphBuilder.scheme.nodes;
    if (nodes.filter((n) => n.id !== -1).length === 0) {
      disabled.push("export");
    }
    return disabled;
  }, [state.graphBuilder.scheme.nodes]);

  return (
    <div className={`${styles.graphBuilder}`}>
      <ExportResultDialog
        onClose={toggleExportModal}
        isOpen={isExportDialogOpen}
      />
      <CreationDialog
        onCreate={onCreateEntity}
        entityType={creatingEntityOfType ? creatingEntityOfType.type : null}
        onClose={closeCreationDialog}
        lastEntityLabels={lastEntityLabels}
      />
      {state.ready && graph.nodes.length === 0 && <FirstInstructions />}
      <DiGraphBuilder
        graphWrapperClassName={`${styles.graph} gy-scheme-builder`}
        scheme={enhancedGraph}
        onSwapEdge={onSwapEdge}
        onCreateNode={onMaybeCreateNode}
        onCreateEdge={onMaybeCreateEdge}
        onDeleteNode={onDeleteNode}
        onDeleteEdge={onDeleteEdge}
        onUpdateNodePosition={onUpdateNodePosition}
        onUpdateEntityData={onUpdateEntityData}
        onCopy={onCopy}
        onPaste={onPaste}
        onSelectEdge={onSelectEdge}
        onSelectNode={onSelectNode}
        selected={selected}
        graphId={state.graphBuilder.id}
        updateLabelColor={updateLabelColor}
        colorMap={colorMap}
        onDeleteEntity={onDeleteEntity}
        contextMenu={contextMenu}
        entityKeyOptions={entityKeyOptions}
        customEntryValidator={validateEntityEntries}
        customToolbar={
          <GraphActionsToolbar
            activeWindow={activeWindow}
            disabledActions={disabledActions}
            onActionClick={handleActionClick}
          />
        }
      />
    </div>
  );
});
