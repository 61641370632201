import dagre, { GraphLabel, graphlib } from "dagre";
import { IGraphLayout } from "./IGraphLayout";
import { IGraph } from "../../../components/graph-builder/types/GraphTypes";

interface IDagreParams extends GraphLabel {
  nodeWidth: number;
  nodeHeight: number;
}

export const DEFAULT_DAGRE_OPTIONS = {
  nodesep: 60,
  ranksep: 90,
  edgesep: 100,
  ranker: "network-simplex",
  acyclicer: "greedy",
  nodeWidth: 30,
  nodeHeight: 30,
};

class DagreLayout implements IGraphLayout {
  private readonly g: graphlib.Graph;
  private readonly options: IDagreParams;

  constructor() {
    this.g = new dagre.graphlib.Graph({
      directed: true,
      multigraph: true,
      compound: true,
    });
    this.options = DEFAULT_DAGRE_OPTIONS;
  }

  public buildLayout(graph: IGraph): IGraph {
    const { nodes, edges } = graph;
    this.g.setGraph(this.options);
    this.g.setDefaultEdgeLabel(() => ({}));

    nodes.forEach((n) => {
      this.g.setNode(n.id.toString(), {
        width: this.options.nodeWidth,
        height: this.options.nodeHeight,
      });
    });

    edges.forEach((e) => {
      this.g.setEdge(e.source.toString(), e.target.toString());
    });

    dagre.layout(this.g);

    return {
      nodes: nodes.map((n) => {
        const position = this.getNodePosition(n.id.toString());

        return {
          ...n,
          x: position.x,
          y: position.y,
        };
      }),
      edges,
    };
  }

  private getNodePosition(nodeId: string) {
    const node = this.g.node(nodeId);

    return {
      x: node.x,
      y: node.y,
    };
  }
}

export default DagreLayout;
