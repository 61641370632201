import { useCallback, useContext, useState } from "react";
import WorkspaceService from "../../../services/workspace/WorkspaceService";
import { AuthStatus } from "../../authentication/AuthTypes";
import { browserHistory } from "../../router/history";
import SandBoxContext from "../SandBoxContext";
import AuthContext from "../../authentication/AuthContext";
import { useParams } from "react-router";

const useSaveWorkspace = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const { user, authStatus } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const { id: workspaceId } = useParams();

  const save = useCallback(
    async (forkedFrom?: string) => {
      setIsLoading(true);
      const { key, revision, id } = await WorkspaceService.save({
        title: state.metadata.title,
        description: state.metadata.description,
        isPrivate: state.metadata.isPrivate,
        graph: state.graphBuilder.scheme,
        queries: state.query.queries,
        nodeLabelsColorMap: state.colorMap.map,
        layout: state.graphBuilder.layout,
        schema: state.schemaBuilder.scheme,
        key: workspaceId,
        forkedFrom,
      });
      const username =
        authStatus === AuthStatus.CONNECT && user.name ? user.name : undefined;
      dispatch({
        type: "UPDATE_WORKSPACE_META",
        payload: { user: username, id },
      });

      const path = [key].concat(revision ? [revision] : []);
      setIsLoading(false);
      browserHistory.push(`/${path.join("/")}`);
    },
    [
      state.metadata.title,
      state.metadata.description,
      state.metadata.isPrivate,
      state.graphBuilder.scheme,
      state.graphBuilder.layout,
      state.query.queries,
      state.colorMap.map,
      state.schemaBuilder.scheme,
      workspaceId,
      authStatus,
      user.name,
      dispatch,
    ]
  );

  return { save, isLoading };
};

export default useSaveWorkspace;
