import { useCallback } from "react";

export const useCopyToClipboard = () => {
  return useCallback((value) => {
    const el = document.createElement("textarea");
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }, []);
};
