import { Button as BPButton, IButtonProps } from "@blueprintjs/core";
import * as React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";

const Button = ({
  active,
  className,
  style,
  ...props
}: IButtonProps & { style?: object }) => {
  const buttonClassNames = classNames(
    styles.button,
    { [styles.active]: active },
    className
  );

  return <BPButton style={style} className={buttonClassNames} {...props} />;
};

export default Button;
