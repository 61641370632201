import { SandboxAction } from "../../../../reducers/SandboxActions";
import { EntityType } from "../../../../../../shared/types/common/enums";
import { GraphUtils } from "../../../../../../services/graph-builder";

export type LabelsAction =
  | {
      type: "ADD_LABEL";
      payload: { label: string; entityType: "node" | "edge" };
    }
  | {
      type: "SET_LABELS";
      payload: { edgeLabels: string[]; nodeLabels: string[] };
    };

export interface ILabelsState {
  nodeLabels: string[];
  edgeLabels: string[];
}

export const initialState: ILabelsState = {
  nodeLabels: [],
  edgeLabels: [],
};

export const reducer = (
  action: SandboxAction,
  state: ILabelsState
): ILabelsState => {
  switch (action.type) {
    case "SET_WORKSPACE":
      const { schema, graph } = action.payload;
      const hasSchema = schema && schema.nodes.length > 0;
      const scheme = hasSchema ? schema : graph;
      const { edges, nodes } = scheme;
      const nodeLabels = GraphUtils.getEntitiesLabels(nodes);
      const edgeLabels = GraphUtils.getEntitiesLabels(edges);

      return {
        nodeLabels,
        edgeLabels,
      };
    case "ADD_LABEL":
      return {
        ...state,
        nodeLabels:
          action.payload.entityType === EntityType.node
            ? [action.payload.label, ...state.nodeLabels]
            : state.nodeLabels,
        edgeLabels:
          action.payload.entityType === EntityType.edge
            ? [action.payload.label, ...state.edgeLabels]
            : state.edgeLabels,
      };
    case "SET_LABELS":
      return {
        ...state,
        nodeLabels: [...action.payload.nodeLabels],
        edgeLabels: [...action.payload.edgeLabels],
      };
  }

  return state;
};
