import { IWorkspaceService } from "./WorkspaceServiceTypes";
import { AppRestService } from "../communication";
import GoogleAnalyticsService from "../analytics/google/GoogleAnalyticsService";
import { NotificationService } from "../notification";
import { IGraph } from "../../components/graph-builder/types/GraphTypes";
import { LayoutType } from "../graph-builder/Layout/LayoutFactory";
import { IQuery } from "../../features/sandbox/components/query-console/Query.interface";

export interface IWorkspaceData {
  title: string;
  description: string;
  isPrivate: boolean;
  graph: IGraph;
  schema: IGraph;
  queries: IQuery[];
  nodeLabelsColorMap: { [key: string]: string } | null;
  layout: LayoutType | null;
  forkedFrom?: string;
  key?: string;
}

export interface IWorkspace extends IWorkspaceData {
  id: number;
  updatedAt: string;
  key: string;
  revision: number;
  user?: string;
}

class WorkspaceService implements IWorkspaceService {
  /**
   * save the current workspace
   * @param workspace
   */
  public async save(workspace: IWorkspaceData) {
    const payload = {
      graph: this.stringifyScheme(workspace.graph),
      schema: this.stringifyScheme(workspace.schema),
      queries: workspace.queries,
      title: workspace.title,
      description: workspace.description,
      isPrivate: workspace.isPrivate,
      nodeLabelsColorMap: workspace.nodeLabelsColorMap
        ? JSON.stringify(workspace.nodeLabelsColorMap)
        : null,
      layout: workspace.layout,
      forkedFrom: workspace.forkedFrom,
      key: workspace.key,
    };
    const result = await AppRestService.post("workspace/save", payload);

    GoogleAnalyticsService.sendEvent({
      action: "Save Workspace",
      category: `Sandbox`,
      label: JSON.stringify(payload),
    });

    return result.data;
  }

  /**
   * forks an existing workspace
   * @param key
   */
  public async fork(key: string) {
    try {
      const result = await AppRestService.post("workspace/fork", { key });
      GoogleAnalyticsService.sendEvent({
        action: "Fork Workspace",
        category: `Sandbox`,
        label: key,
      });

      return result.data;
    } catch (e) {
      NotificationService.error(
        "Could not fork workspace. Please try again later."
      );
    }
  }

  /**
   * clean a scheme, leave it with
   * @param scheme
   */
  private stringifyScheme(scheme: IGraph) {
    return JSON.stringify({
      nodes: scheme.nodes.map(({ title, ...rest }) => ({ ...rest })),
      edges: scheme.edges,
    });
  }

  /**
   *
   * @param isOrganization
   */
  public async getList(isOrganization: boolean) {
    try {
      const result = await AppRestService.get("workspace/list", {
        isOrganization,
      });

      return result.data;
    } catch (e) {
      NotificationService.error(e.message);

      throw e;
    }
  }

  /**
   * fetch workspace data
   * @param id
   * @param revision
   */
  public async get(id: string, revision?: string) {
    try {
      const path = ["workspace", id].concat(revision ? [revision] : []);
      const result = await AppRestService.get(path.join("/"));

      GoogleAnalyticsService.sendEvent({
        action: "Load Workspace",
        category: `Workspace`,
        label: `id: ${id}`,
      });

      return result.data;
    } catch (e) {
      NotificationService.error(e.message);

      return null;
    }
  }

  /**
   * toggle workspace privacy
   * @param id
   * @param isPrivate
   */
  public async changePrivacy(id: number, isPrivate: boolean) {
    try {
      const result = await AppRestService.put(`workspace/${id}/privacy`, {
        isPrivate,
      });
      return result.data;
    } catch (e) {
      NotificationService.error(e.message);
    }
  }

  /**
   * delete a workspace
   * @param id
   */
  public async deleteWorkspace(id: number) {
    try {
      const result = await AppRestService.delete(`workspace/${id}`);
      return result.data;
    } catch (e) {
      NotificationService.error(e.message);
    }
  }

  /**
   * undo a workspace
   * @param id
   * @param graph
   */
  public async undo(id: number, graph: IGraph) {
    try {
      const result = await AppRestService.post(`workspace/undo/${id}`, {
        graph,
      });
      return result.data;
    } catch (e) {
      NotificationService.error(e.message);
    }
  }
}

export default new WorkspaceService();
