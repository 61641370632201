import {
  IBreadcrumbsProps,
  Breadcrumbs as BPBreadcrumbs,
} from "@blueprintjs/core";
import React, { FC } from "react";

const Breadcrumbs: FC<IBreadcrumbsProps> = (props) => (
  <BPBreadcrumbs {...props} />
);

export default Breadcrumbs;
