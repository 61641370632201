import React, { ComponentType } from "react";
import { Metadata } from "../metadata";
import {
  ChefHatIcon,
  GithubIcon,
  GremlifyIcon,
  Icon,
} from "../../components/icon";
import { Settings } from "../settings";
import { History } from "../history";
import UpdatesToggle from "./UpdatesToggle";
import { Updates } from "../updates";
import { Templates } from "../templates";
import { Community } from "../community";
import { Help } from "../help";
import { FavouriteQueries } from "../favourite-queries";

const items: {
  [key: string]: {
    title: string;
    component: ComponentType;
    name: string;
    toggle: ComponentType;
    markupId?: string;
  };
} = {
  updates: {
    name: "updates",
    title: "What's new?",
    component: Updates,
    toggle: () => <UpdatesToggle />,
    markupId: "gy-updates",
  },
  metadata: {
    name: "metadata",
    title: "Metadata",
    component: Metadata,
    toggle: GremlifyIcon,
    markupId: "gy-metadata",
  },
  history: {
    name: "history",
    title: "Query History",
    component: History,
    toggle: () => <Icon icon={"history"} />,
    markupId: "gy-history",
  },
  favouriteQueries: {
    name: "favouriteQueries",
    title: "Favourite Queries",
    component: FavouriteQueries,
    markupId: "gy-favourites",
    toggle: () => <Icon icon={"star"} />,
  },
  templates: {
    name: "templates",
    title: "Graph Templates",
    component: Templates,
    toggle: ChefHatIcon,
    markupId: "gy-templates",
  },
  settings: {
    title: "Settings",
    component: Settings,
    name: "settings",
    toggle: () => <Icon icon={"cog"} />,
    markupId: "gy-settings",
  },
  community: {
    name: "community",
    title: "Community",
    component: Community,
    toggle: GithubIcon,
    markupId: "gy-community",
  },
  help: {
    name: "help",
    title: "Help",
    component: Help,
    toggle: () => <Icon icon={"help"} />,
    markupId: "gy-help",
  },
};

export default items;
