import { FormGroup, InputGroup, TextArea } from "../../components/form";
import * as React from "react";
import styles from "./Metadata.module.scss";
import { Sidebar } from "../../components/sidebar";
import { useContext } from "react";
import SandBoxContext from "../sandbox/SandBoxContext";
import { useCallback } from "react";
import { UPDATE_WORKSPACE_META } from "../sandbox/reducers/SandboxActions";
import { Switch } from "../../components/switch";
import AuthContext from "../authentication/AuthContext";
import { AuthStatus } from "../authentication/AuthTypes";
import { Tooltip } from "../../components/tooltip";

const Metadata = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const { authStatus } = useContext(AuthContext);

  const handleInputChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch({
        type: UPDATE_WORKSPACE_META,
        payload: { [event.currentTarget.name]: event.currentTarget.value },
      });
    },
    [dispatch]
  );

  const handleCheckboxChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      dispatch({
        type: UPDATE_WORKSPACE_META,
        payload: { [event.currentTarget.name]: event.currentTarget.checked },
      });
    },
    [dispatch]
  );

  return (
    <div className={styles.metadata} data-cy={"Metadata"}>
      <Sidebar.Content>
        <Sidebar.Section>
          <FormGroup
            className={styles.formGroup}
            label="Title"
            labelFor="title"
          >
            <InputGroup
              value={state.metadata.title}
              id="title"
              name="title"
              onChange={handleInputChange}
              placeholder="Untitled workspace"
            />
          </FormGroup>
        </Sidebar.Section>
        <Sidebar.Section>
          <FormGroup
            className={styles.formGroup}
            label="Description"
            labelFor="description"
          >
            <TextArea
              value={state.metadata.description}
              onChange={handleInputChange}
              id="description"
              name="description"
              placeholder="No description"
            />
          </FormGroup>
          {authStatus === AuthStatus.CONNECT ? (
            <div className={styles.privacyWrapper}>
              <Tooltip
                content={"Private Workspaces will be accessible to you only."}
                position={"bottom"}
              >
                <Switch
                  onChange={handleCheckboxChange}
                  name={"isPrivate"}
                  checked={state.metadata.isPrivate}
                  label={"Private"}
                />
              </Tooltip>
            </div>
          ) : null}
        </Sidebar.Section>
      </Sidebar.Content>
    </div>
  );
};

export default Metadata;
