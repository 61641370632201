import React, { FC } from "react";
import classNames from "classnames";
import styles from "./Icon.module.scss";

interface IMouseIconProps {
  button: "left" | "right";
}

const MouseIcon: FC<IMouseIconProps> = ({ button }) => (
  <svg width="24px" height="24px" viewBox="0 0 64 64" className={styles.mouse}>
    <g
      id="Artboard"
      stroke="none"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M18,25.5 C18,18.0441559 24.0441559,12 31.5,12 L31.5,30.5114746 L18,30.5 L18,25.5 Z"
        id="Shape"
        className={classNames({ [styles.active]: button === "left" })}
      />
      <path
        d="M32,25.8562705 C32,18.2036636 38.2680135,12 46,12 L46,31 L32,30.9882226 L32,25.8562705 Z"
        id="Shape"
        className={classNames({ [styles.active]: button === "right" })}
        transform="translate(39.000000, 21.500000) scale(-1, 1) translate(-39.000000, -21.500000) "
      />
      <rect
        id="Rectangle"
        stroke="#979797"
        x="17.5"
        y="11.5"
        width="29"
        height="46"
        rx="14.5"
      />
      <line
        x1="18.5"
        y1="30.5"
        x2="45.5"
        y2="30.5"
        id="Line"
        strokeLinecap="square"
      />
      <path
        d="M32,30.1220703 C31.6666667,17.3948568 31.6666667,9.58251953 32,6.68505859 C32.5,2.33886719 39,0.907208173 39,0.907208173"
        id="Line-2"
        className={styles.cable}
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
    </g>
  </svg>
);

export default MouseIcon;
