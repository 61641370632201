import React from "react";
import styles from "./Settings.module.scss";
import { CustomLabelProperties } from "./CustomLabelProperties";

export const Settings = () => {
  return (
    <div className={styles.graphSettings}>
      <CustomLabelProperties />
    </div>
  );
};
