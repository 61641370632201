import { IGraphLayout } from "./IGraphLayout";
import {
  IGraph,
  INode,
} from "../../../components/graph-builder/types/GraphTypes";
import Viva, { IGrid, ILayout } from "vivagraphjs";
import { IEdge } from "../GraphBuilderTypes";

class VivaLayout implements IGraphLayout {
  private readonly g: IGrid;
  private layout!: ILayout;

  public constructor() {
    const graphGenerator = Viva.Graph.generator();
    this.g = graphGenerator.grid(3, 3);
  }

  public buildLayout(graph: IGraph): IGraph {
    this.buildNodes(graph.nodes);
    this.buildEdges(graph.edges);

    this.layout = this.createDirecteLayout();
    this.converge();

    return {
      nodes: graph.nodes.map((n: INode) => {
        const position = this.layout.getNodePosition(n.id.toString());
        return {
          ...n,
          x: position.x,
          y: position.y,
        };
      }),
      edges: graph.edges,
    };
  }

  private buildNodes(nodes: INode[]) {
    nodes.forEach((n: INode) => {
      this.g.addNode(n.id);
    });
  }

  private buildEdges(edges: IEdge[]) {
    edges.forEach((e: IEdge) => {
      this.g.addLink(e.source, e.target);
    });
  }

  private getSpringLength() {
    return 600;
  }

  private getSpringCoeff() {
    return 0.0005;
  }

  private getDragCoeff() {
    return 0.2;
  }

  private createDirecteLayout(): ILayout {
    return Viva.Graph.Layout.forceDirected(this.g, {
      springLength: this.getSpringLength(),
      springCoeff: this.getSpringCoeff(),
      dragCoeff: this.getDragCoeff(),
      gravity: -1.2,
    });
  }

  private converge() {
    for (let i = 0; i < 1000; i++) {
      this.layout.step();
    }
  }
}
export default VivaLayout;
