import { IEntryLinePartialProps } from "../../../../../../../components/key-value-editor/useKeyValueEntries";
import { EntryType } from "../../../../../../../components/key-value-editor/KeyValueEditor";
import { IOption } from "../../../../../../../components/key-value-editor/EntryLine/interface";
import { PropertyValueType } from "../../../../../../../shared/types/common/graph";

class EntryProps {
  private readonly editable: boolean;
  private readonly value: string;
  private readonly type: EntryType;
  private readonly selectOptions?: IOption[];
  private readonly valueType?: PropertyValueType;

  constructor(builder: EntryPropsBuilder) {
    this.editable = builder.props.editable;
    this.value = builder.props.value;
    this.type = builder.props.type;
    this.selectOptions = builder.props.selectOptions;
    this.valueType = builder.props.valueType;
  }

  public serialize() {
    return {
      editable: this.editable,
      value: this.value,
      type: this.type,
      selectOptions: this.selectOptions,
      valueType: this.valueType,
    };
  }
}

export class EntryPropsBuilder {
  private readonly _props: IEntryLinePartialProps;

  constructor() {
    this._props = {
      editable: false,
      type: "text" as const,
      value: "",
    };
  }

  setEditable(editable: boolean) {
    this._props.editable = editable;
    return this;
  }

  setType(type: EntryType) {
    this._props.type = type;
    return this;
  }

  setValue(value: string) {
    this._props.value = value;
    return this;
  }

  setValueIfNotExisting(value: string) {
    this._props.value = this._props.value ? this._props.value : value;
    return this;
  }

  setSelectOptions(options: IOption[]) {
    this._props.selectOptions = options;
    return this;
  }

  setValueType(valueType: string) {
    this._props.valueType = valueType as PropertyValueType;
    return this;
  }

  public build() {
    return new EntryProps(this);
  }

  public get props() {
    return this._props;
  }
}
