import * as React from "react";
import styles from "./Themes.module.scss";
import { THEMES } from "../../shared/constants/common/common";
import StringUtils from "../../utils/StringUtils/StringUtils";
import { Icon } from "../../components/icon";
import { FC, useCallback } from "react";

interface IThemeItemProps {
  selected: boolean;
  name: string;
  meta: {
    color: string;
  };
  onSelect: (theme: string) => void;
}

interface IThemesProps {
  selected: string;
  onSelect: (theme: string) => void;
}

const ThemeItem: FC<IThemeItemProps> = ({ name, selected, onSelect, meta }) => {
  const handleClick = useCallback(
    (e) => {
      onSelect(name);
    },
    [name, onSelect]
  );
  return (
    <li key={name} onClick={handleClick} className={styles.themeItem}>
      <svg viewBox="-1 -1 2 2" className={styles.circleSvg}>
        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
        <circle
          fill={meta.color}
          className={styles.themeCircle}
          cx="0"
          cy="0"
          r="0.9"
        />
      </svg>
      <span className={styles.themeName}>
        {StringUtils.capitalizeFirstLetter(name)}
      </span>
      {selected && <Icon icon={"tick"} iconSize={12} />}
    </li>
  );
};

const Themes: FC<IThemesProps> = ({ selected, onSelect }) => {
  const handleThemeSelect = useCallback(
    (name: string) => {
      onSelect(name);
    },
    [onSelect]
  );
  return (
    <div className={styles.themes}>
      <ul className={styles.themeList}>
        {Object.entries(THEMES).map(([name, meta]) => (
          <ThemeItem
            key={name}
            name={name}
            meta={meta}
            selected={selected === name}
            onSelect={handleThemeSelect}
          />
        ))}
      </ul>
    </div>
  );
};

export default Themes;
