import React, { FC } from "react";
import classNames from "classnames";
import { IMenuProps as IBPMenuProps, Menu as BPMenu } from "@blueprintjs/core";
import styles from "./Menu.module.scss";

interface IMenuProps extends IBPMenuProps {
  type?: "primary" | "secondary";
}

const Menu: FC<IMenuProps> = ({ className, type = "primary", ...props }) => (
  <BPMenu
    className={classNames(styles.menu, className, styles[type])}
    {...props}
  />
);

export default Menu;
