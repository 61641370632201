import { FC } from "react";
import * as React from "react";
import styles from "./help.module.scss";
import { Icon } from "../icon";
import { Tooltip } from "../tooltip";

interface IHelp {
  text: string;
  iconSize?: number;
}

const Help: FC<IHelp> = ({ children, text, iconSize = 16 }) => (
  <div className={styles.help}>
    {children}
    <Tooltip content={text}>
      <Icon iconSize={iconSize} icon={"help"} />
    </Tooltip>
  </div>
);

export default Help;
