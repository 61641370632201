import {
  EditableText as BPEditableText,
  IEditableTextProps,
} from "@blueprintjs/core";
import * as React from "react";
import { FC } from "react";

export const EditableText: FC<IEditableTextProps> = (props) => (
  <BPEditableText {...props} />
);
