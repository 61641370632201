import { Switch } from "../../../../components/switch";
import React, { FC, useCallback } from "react";
import styles from "./Footer.module.scss";
import { DateUtils } from "../../../../utils";
import { Avatar } from "../../../../components/Avatar";

interface IWFooterProps {
  title?: string;
  description?: string;
  updatedAt: string;
  isPrivate: boolean;
  onChangePrivacy: (isPrivate: boolean) => void;
  keyId: string;
  revision?: number;
  user?: string;
  isOwner: boolean;
}

const Footer: FC<IWFooterProps> = ({
  description,
  title,
  updatedAt,
  isPrivate,
  onChangePrivacy,
  keyId,
  revision,
  user,
  isOwner,
}) => {
  const handlePrivacyChange = useCallback(
    (evt) => {
      onChangePrivacy(evt.target.checked);
    },
    [onChangePrivacy]
  );
  return (
    <div className={styles.footer}>
      <div className={styles.titleWrapper}>
        <div>
          <h3 className={styles.title}>
            {title || "Untitled Workspace"}{" "}
            <span className={styles.keyId}>#{keyId}</span>
          </h3>
          <span className={styles.description}>{description || "-"}</span>
        </div>
        {isOwner && (
          <div className={styles.privacyWrapper}>
            <Switch
              data-cy={"ChangePrivacy"}
              onChange={handlePrivacyChange}
              checked={isPrivate}
              label={"Private"}
            />
          </div>
        )}
      </div>
      <div className={styles.infoWrapper}>
        {user && (
          <span className={styles.user}>
            <Avatar className={styles.avatar} name={user} size={25} />
            {user}
          </span>
        )}
        <span>{revision && revision > 0 ? `#${revision} revision` : ""}</span>
        <span>{DateUtils.formatDate(updatedAt, "lll")}</span>
      </div>
    </div>
  );
};

export default Footer;
