import { SandboxAction } from "../../../../reducers/SandboxActions";

export type ColorMapAction =
  | {
      type: "SET_LABEL_COLOR";
      payload: { label: string; color: string };
    }
  | { type: "SET_COLOR_MAP"; payload: { map: IColorMap } };

interface IColorMap {
  [key: string]: string;
}

export interface IColorMapState {
  map: IColorMap;
}

export const initialState: IColorMapState = {
  map: { "?": "transparent" },
};

export const reducer = (
  action: SandboxAction,
  state: IColorMapState
): IColorMapState => {
  switch (action.type) {
    case "SET_WORKSPACE":
      return {
        ...state,
        map: action.payload.nodeLabelsColorMap || state.map,
      };
    case "SET_COLOR_MAP":
      return {
        ...state,
        map: action.payload.map || state.map,
      };
    case "SET_LABEL_COLOR":
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.label]: action.payload.color,
        },
      };
  }

  return state;
};
