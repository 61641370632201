import * as React from "react";
import { useCallback, useContext } from "react";
import SandBoxContext from "../../SandBoxContext";
import styles from "./QueryConsole.module.scss";
import { QueryEditor } from "../../../../components/editor";
import QueryToolbar from "./QueryToolbar/QueryToolbar";
import { useSelectCurrentQuery } from "./QuerySelectors";
import { QueryTabs } from "./QueryTabs";
import { MAX_QUERY_TABS } from "../../../../shared/constants/common/common";

const QueryConsole = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const currentQuery = useSelectCurrentQuery(state);
  const handleUpdateQueryTitle = useCallback(
    (title: string) => {
      dispatch({ type: "UPDATE_QUERY", payload: { title } });
    },
    [dispatch]
  );

  const handleAddQueryTab = useCallback(() => {
    dispatch({ type: "ADD_QUERY" });
  }, [dispatch]);

  const handleRemoveQueryTab = useCallback(
    (index: number) => {
      dispatch({ type: "REMOVE_QUERY", payload: { index } });
    },
    [dispatch]
  );

  const handleSelectQueryTab = useCallback(
    (index: number) => {
      dispatch({ type: "SELECT_QUERY", payload: { index } });
    },
    [dispatch]
  );

  const handleUpdateQueryValue = useCallback(
    (query: string) => {
      dispatch({ type: "UPDATE_QUERY", payload: { query } });
    },
    [dispatch]
  );

  return (
    <div className={`${styles.wrapper} gy-query-console`} data-cy={"Editor"}>
      <QueryToolbar onFormat={handleUpdateQueryValue} />
      <QueryEditor
        value={currentQuery.query}
        onChange={handleUpdateQueryValue}
      />
      <QueryTabs
        maxTabs={MAX_QUERY_TABS}
        queries={state.query.queries}
        onAddTab={handleAddQueryTab}
        currentTabIndex={state.query.index}
        onSelectTab={handleSelectQueryTab}
        onRemoveTab={handleRemoveQueryTab}
        onUpdateQueryTitle={handleUpdateQueryTitle}
      />
    </div>
  );
};

export default QueryConsole;
