import GraphConfigBuilder from "./GrahpConfigBuilder";
import React from "react";

const defaultGraphConfig = new GraphConfigBuilder()
  .setNodeTypes({
    node: {
      typeText: "",
      shapeId: `#node`,
      shape: (
        <symbol viewBox="0 0 60 60" width="60" height="60" id="node" key="0">
          <circle cx="30" cy="30" r="28" />
        </symbol>
      )
    }
  })
  .setNodeSubtypes({})
  .setEdgeTypes({
    edge: {
      shapeId: "#edge",
      shape: (
        <symbol />
      )
    }
  })
  .build();
export default defaultGraphConfig;
