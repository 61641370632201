class StringUtils {
  /**
   * generate a radnom secret string
   */
  public static randomString() {
    return StringUtils.numToString(Math.random()).substr(2);
  }

  public static capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase().concat(str.slice(1));
  }

  public static subStringNotEqual(str1: string, str2: string, caseSensitive = false) {
    let normStr1 = str1, normStr2 = str2;
    if (!caseSensitive) {
      normStr1 = str1.toLowerCase();
      normStr2 = str2.toLowerCase();
    }

    return normStr1 !== normStr2 && normStr1.includes(normStr2);
  }

  private static numToString(num: number) {
    return num.toString(36);
  }
}

export default StringUtils;