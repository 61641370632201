import { Circle, Layer, Line, Stage } from "react-konva";
import React, { FC, memo, useMemo } from "react";
import { GraphUtils } from "../../services/graph-builder";
import { IGraph } from "../graph-builder/types/GraphTypes";
import {
  DEFAULT_LAYOUT,
  LayoutType,
} from "../../services/graph-builder/Layout/LayoutFactory";
import { useColorMap } from "../../features/sandbox/components/builders/common";

interface IWorkspaceSnapshotProps {
  scheme: IGraph;
  colorMap: { [key: string]: string } | null;
  layoutType: LayoutType | null;
  width: number;
  height: number;
  nodeRadius: number;
  nodeScaleFactor: number;
  edgeWidth: number;
  className?: string;
}

const WorkspaceSnapshot: FC<IWorkspaceSnapshotProps> = ({
  scheme,
  colorMap,
  width,
  height,
  nodeRadius,
  nodeScaleFactor,
  edgeWidth,
  layoutType,
  className,
}) => {
  const { colorMap: dummyColorMap } = useColorMap();
  const layout = useMemo(() => {
    const rawLayout = GraphUtils.generateGraphFromLayout(
      layoutType || DEFAULT_LAYOUT,
      scheme
    );
    return GraphUtils.alignLayout(
      rawLayout,
      { x: width / 2, y: height / 2 },
      nodeScaleFactor
    );
  }, [height, layoutType, nodeScaleFactor, scheme, width]);

  const nodePositions = useMemo(
    () =>
      GraphUtils.generatePropertyMap<{ x: number; y: number }>(layout.nodes, [
        "x",
        "y",
      ]),
    [layout.nodes]
  );

  const getNodeColor = (label: string) =>
    colorMap ? colorMap[label] : dummyColorMap[label];

  return (
    <Stage className={className} width={width} height={height}>
      <Layer>
        {layout.edges.map((edge) => (
          <Line
            stroke={"white"}
            strokeWidth={edgeWidth}
            key={edge.id}
            points={[
              nodePositions[edge.source].x,
              nodePositions[edge.source].y,
              nodePositions[edge.target].x,
              nodePositions[edge.target].y,
            ]}
          />
        ))}
        {layout.nodes.map((node) => (
          <Circle
            key={node.id}
            radius={nodeRadius}
            fill={getNodeColor(node.data.label)}
            x={node.x}
            y={node.y}
          />
        ))}
      </Layer>
    </Stage>
  );
};

export default memo(WorkspaceSnapshot);
