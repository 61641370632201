import { IElementRefProps, Pre as BPPre } from "@blueprintjs/core";
import { FC } from "react";
import * as React from "react";
import classNames from "classnames";

const Pre: FC<React.HTMLProps<HTMLElement> & IElementRefProps<HTMLElement>> = (
  props
) => <BPPre {...props} className={classNames(props.className)} />;

export default Pre;
