import { SandboxAction } from "../sandbox/reducers/SandboxActions";

export type HelpAction = {
  type: "TOGGLE_HELP_TOUR";
  payload: { run: boolean };
};

export interface IHelpState {
  helpTourRun: boolean;
}

export const initialState: IHelpState = {
  helpTourRun: false,
};

export const reducer = (
  action: SandboxAction,
  state: IHelpState
): IHelpState => {
  if (action.type === "TOGGLE_HELP_TOUR") {
    return {
      ...state,
      helpTourRun: action.payload.run,
    };
  }

  return state;
};
