import { useCallback, useState } from "react";
import {
  GraphEntity,
  IEdge,
  IEntityData,
  INode,
} from "../../../../../../components/graph-builder/types/GraphTypes";
import { EntityType } from "../../../../../../shared/types/common/enums";

export const useCreateEntity = (
  createNode: (x: number, y: number, data: IEntityData) => INode | Promise<INode> | null,
  createEdge: (source: INode, target: INode, data: IEntityData) => IEdge | Promise<IEdge> | null,
  callback: (entity: GraphEntity) => void
) => {
  const [creatingEntityOfType, setCreatingEntityOfType] = useState<{
    type: EntityType;
    args: unknown[];
  } | null>(null);

  const handleCreateEntity = useCallback(
    async (data: IEntityData) => {
      if (creatingEntityOfType) {
        const { args = [] } = creatingEntityOfType;
        let entity: GraphEntity;

        if (creatingEntityOfType.type === EntityType.node) {
          entity = (await createNode(
            args[0] as number,
            args[1] as number,
            data
          )) as INode;
        } else {
          entity = (await createEdge(
            args[0] as INode,
            args[1] as INode,
            data
          )) as IEdge;
        }
        setCreatingEntityOfType(null);
        callback(entity);
      }
    },
    [creatingEntityOfType, callback, createNode, createEdge]
  );

  return {
    handleCreateEntity,
    creatingEntityOfType,
    setCreatingEntityOfType,
  };
};
