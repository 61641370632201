import React from "react";
import styles from './Icon.module.scss';

const ChalkArrow = () => (
  <svg className={styles.chalkArrow} width="80px" height="120px" viewBox="0 0 80 120">
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(17.000000, 5.000000)" strokeWidth="3.06">
        <path d="M22.9415625,0 C22.6258868,9.39757203 22.1906922,18.7939267 21.9945355,28.1927161 C19.6475158,140.649426 23.8803316,-20.1376277 21.1026436,68.8978964 C21.0296349,71.2381026 21.2946904,73.5821096 21.1026436,75.9197929 C20.0478072,88.759755 19.0255847,98.275369 16.7599409,110.350889 C16.7354647,110.481343 16.561022,110.58363 16.4615625,110.7" id="Path"/>
        <path d="M0,71.4684375 C0.841153657,79.2491088 0.653477265,80.6917673 4.9021875,90.2025 C6.85218902,94.567576 9.85840475,98.3824935 12.166875,102.569063 C13.3156669,104.652475 14.0771322,106.937775 15.26625,108.998438 C15.6561189,109.674055 16.0994432,110.864294 16.86375,110.708438 C18.1692245,110.442226 18.7972477,108.866662 19.8590625,108.061875 C21.4900003,106.82573 23.1946772,105.686104 24.9271875,104.596875 C35.4911524,97.9553089 46.1434466,92.1394675 57.6534375,87.339375" id="Path-2"/>
      </g>
    </g>
  </svg>
);

export default ChalkArrow;
