import * as React from "react";
import { FC, useCallback, useContext } from "react";
import { Pre } from "../../components/pre";
import styles from "./FavouriteQueries.module.scss";
import { Sidebar } from "../../components/sidebar";
import { NonIdealState } from "../../components/non-ideal-state";
import { PaginatedList } from "../../components/paginated-list";
import AuthContext from "../authentication/AuthContext";
import { AuthStatus } from "../authentication/AuthTypes";
import useFavouriteQueries from "./useFavouriteQueries";
import { Button } from "../../components/button";
import { CloudSpinner } from "../../components/spinner";

interface IFavouriteQueriesItem {
  text: string;
  id: number;
  onClick: (text: string) => void;
  onRemove: (id: number) => void;
  maxLength?: number;
}

const FavouriteItem: FC<IFavouriteQueriesItem> = ({
  id,
  text,
  onClick,
  onRemove,
  maxLength,
}) => {
  const handleClick = useCallback(() => {
    onClick(text);
  }, [onClick, text]);

  const handleRemove = useCallback(() => {
    onRemove(id);
  }, [id, onRemove]);

  return (
    <div title={text} className={styles.queryItem} data-cy={"FavouriteItem"}>
      <Pre className={styles.query} onClick={handleClick}>
        {maxLength
          ? text.length > maxLength
            ? text.substr(0, maxLength).concat("...")
            : text
          : text}
      </Pre>
      <div className={styles.actions}>
        <Button
          onClick={handleRemove}
          small={true}
          data-cy={"RemoveQueryFromFavourites"}
          className={styles.actionButton}
          intent={"danger"}
          icon={"small-cross"}
        />
      </div>
    </div>
  );
};

const FavouriteQueries: FC = () => {
  const { authStatus } = useContext(AuthContext);
  const {
    favouriteQueries,
    applyQuery,
    removeQueryFromFavourites,
    isLoading,
  } = useFavouriteQueries();

  const renderQueryItem = useCallback(
    (data) => {
      return (
        <FavouriteItem
          onRemove={removeQueryFromFavourites}
          id={data.id}
          maxLength={40}
          onClick={applyQuery}
          text={data.query}
        />
      );
    },
    [applyQuery, removeQueryFromFavourites]
  );

  return (
    <div className={styles.favouriteQueries} data-cy={"FavouriteQueries"}>
      <Sidebar.Content>
        {authStatus === AuthStatus.DISCONNECT ? (
          <NonIdealState
            icon={"lock"}
            title="Ooops."
            description={
              "You must be logged-in in to view your favourite queries."
            }
          />
        ) : (
          <>
            {isLoading && (
              <div className={styles.spinnerWrapper}>
                <CloudSpinner />
              </div>
            )}
            {favouriteQueries.length > 0 ? (
              <PaginatedList
                perPage={8}
                className={styles.favouriteQueriesList}
                items={favouriteQueries}
                renderItem={renderQueryItem}
              />
            ) : (
              <NonIdealState
                icon={"star-empty"}
                title="No Favourites"
                description={
                  "Add queries to your favourite queries list from the editor Toolbar"
                }
              />
            )}
          </>
        )}
      </Sidebar.Content>
    </div>
  );
};

export default FavouriteQueries;
