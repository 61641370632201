import React from "react";
import ReactDOM from "react-dom";
import styles from "./GlobalLoader.module.scss";
import { CloudSpinner } from "../spinner";

const GlobalLoader = () => {
  return ReactDOM.createPortal(
    <div className={styles.globalLoader}>
      <CloudSpinner size={150} />
    </div>,
    document.getElementById("root") as Element
  );
};

export default GlobalLoader;
