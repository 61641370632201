import * as React from "react";
import styles from "./KeyValueEditor.module.scss";
import { ComponentType, FC, useEffect, useRef } from "react";
import EntryLine from "./EntryLine/EntryLine";
import { Button } from "../button";
import { ButtonGroup } from "../button-group";
import {
  useKeyValueEntries,
  IEntryLinePartialProps,
  IEntry,
} from "./useKeyValueEntries";
import classNames from "classnames";

export type EntryType = "text" | "select" | "number";

export interface IUiEntry {
  id: string;
  keyProps: IEntryLinePartialProps;
  valueProps: IEntryLinePartialProps;
}

export interface IKeyValueEntry {
  key: string;
  value: string;
}

interface IKeyValueEditor {
  onChange: (entries: { [key: string]: string }) => void;
  entries: IUiEntry[];
  className?: string;
  footerClassName?: string;
  entriesWrapperClassName?: string;
  containerElement?: string | ComponentType<any>;
  isEditMode?: boolean;
  entryFactory?: (id: string) => IUiEntry;
  canModifyEntries?: boolean;
  customValidator?: (entries: IKeyValueEntry[]) => string | null;
  onEntriesChange?: (newEntries: IEntry[]) => void;
}

const KeyValueEditor: FC<IKeyValueEditor> = ({
  onChange,
  entriesWrapperClassName,
  isEditMode = true,
  entries = [],
  className,
  footerClassName,
  containerElement = "div",
  entryFactory,
  canModifyEntries = true,
  customValidator,
  onEntriesChange,
}) => {
  const {
    dataArray,
    onUpdateEntry,
    onAddEntry,
    onCancel,
    onRemoveEntry,
    isDirty,
    onSave,
    errors,
  } = useKeyValueEntries(
    entries,
    onChange,
    entryFactory,
    customValidator,
    onEntriesChange
  );
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [dataArray.length, ref]);

  return (
    <div
      className={classNames(styles.keyValueEditor, className)}
      data-cy={"EntityEditor"}
    >
      {React.createElement(
        containerElement,
        {
          className: entriesWrapperClassName,
          ref: ref,
        },
        dataArray.map((entry, index) => (
          <EntryLine
            key={entry.id}
            onUpdate={onUpdateEntry}
            entry={entry}
            index={index}
            canAdd={index === dataArray.length - 1}
            canRemove={index !== 0}
            onAdd={onAddEntry}
            onRemove={onRemoveEntry}
            valueProps={entry.valueProps}
            keyProps={entry.keyProps}
            isEditable={canModifyEntries}
          />
        ))
      )}
      <div className={classNames(styles.footer, footerClassName)}>
        {errors && (
          <div data-cy={"DataErrors"} className={styles.errors}>
            {errors}
          </div>
        )}
        <ButtonGroup className={styles.actions}>
          <Button
            data-cy={"CancelEntityData"}
            text={"Cancel"}
            disabled={!isDirty}
            onClick={onCancel}
            minimal={true}
          />
          <Button
            data-cy={"SaveEntityData"}
            text={"Save"}
            disabled={
              isEditMode ? !isDirty || errors !== null : errors !== null
            }
            onClick={onSave}
            minimal={true}
          />
        </ButtonGroup>
      </div>
    </div>
  );
};

export default KeyValueEditor;
