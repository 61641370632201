export class ColorGenerator {
  public static NodeColors = [
    "#e74c3c",
    "#1abc9c",
    "#2980b9",
    "#2ecc71",
    "#9b59b6",
    "#34495e",
    "#16a085",
    "#27ae60",
    "#8e44ad",
    "#2c3e50",
    "#3498db",
    "#f1c40f",
    "#e67e22",
    "#f39c12",
    "#d35400",
    "#c0392b",
    "#22a6b3",
    "#be2edd",
    "#4834d4",
    "#130f40",
    "#535c68",
  ];

  public static genNodeColor = function* () {
    let i = 0;
    while (true) {
      yield ColorGenerator.NodeColors[i++ % ColorGenerator.NodeColors.length];
    }
  };
}
