import { useCallback } from "react";
import NotificationService from "./NotificationService";

export const useNotification = () => {
  const notifyError = useCallback(
    (message: string) => NotificationService.error(message),
    []
  );
  const notifySuccess = useCallback(
    (message: string) => NotificationService.success(message),
    []
  );

  return {
    notifyError,
    notifySuccess,
  };
};
