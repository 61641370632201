import React, { FC, useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { IconName } from "@blueprintjs/core";
import styles from "./EntityToolbar.module.scss";
import { Button } from "../../../button";
import { GraphEntity } from "../../types/GraphTypes";
import { ColorPicker } from "../../../color-picker";
import { Tooltip } from "../../../tooltip";
import { EntityType } from "../../../../shared/types/common/enums";
import { EntityDataViewer } from "./EntityDataViewer/EntityDataViewer";
import { EntityEditor } from "./EntityEditor/EntityEditor";
import { IOption } from "../../../key-value-editor/EntryLine/interface";
import { IKeyValueEntry } from "../../../key-value-editor/KeyValueEditor";

interface IEntityToolbarProps {
  entity: GraphEntity | null;
  updateLabelColor: (label: string, color: string) => void;
  colorMap: { [key: string]: string };
  deleteEntity: (entity: GraphEntity) => void;
  onSaveEntityData: (entity: GraphEntity) => void;
  valueOptions?: IOption[];
  keyOptions?: IOption[];
  customValidator?: (entries: IKeyValueEntry[]) => string | null;
  displayEntityId?: boolean;
  canModifyEntries?: boolean;
  maxValueChars: number;
}

interface IActionButtonProps {
  title: string;
  onClick?: () => void;
  icon: IconName;
  style?: object;
  active?: boolean;
  testId?: string;
}

const ActionButton: FC<IActionButtonProps> = ({
  title,
  icon,
  onClick,
  style,
  active,
  testId,
}) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <Tooltip defaultIsOpen={true} content={title} position={"bottom"}>
      <Button
        className={classNames(styles.actionButton, { [styles.active]: active })}
        style={style}
        minimal={true}
        icon={icon}
        onClick={handleClick}
        data-cy={testId}
      />
    </Tooltip>
  );
};

const EntityToolbar: FC<IEntityToolbarProps> = ({
  entity,
  updateLabelColor,
  deleteEntity,
  colorMap,
  onSaveEntityData,
  valueOptions,
  keyOptions,
  customValidator,
  maxValueChars,
  displayEntityId = true,
  canModifyEntries = true,
}) => {
  const [actionWindow, setActionWindow] = useState<string | null>(null);
  const toggleEditor = useCallback(() => {
    setActionWindow(actionWindow === "editor" ? null : "editor");
  }, [actionWindow]);

  const updateColor = useCallback(
    (color: string) => {
      if (entity !== null) {
        updateLabelColor(entity.data.label, color);
      }
    },
    [entity, updateLabelColor]
  );

  const handleDelete = useCallback(() => {
    if (entity) {
      deleteEntity(entity);
    }
  }, [deleteEntity, entity]);

  const entityData = useMemo(() => {
    if (entity) {
      const { label, ...rest } = entity.data;
      return rest;
    }
    return {};
  }, [entity]);

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.entityToolbar)}>
        {entity !== null ? (
          <div className={styles.content}>
            <div
              className={classNames(styles.label, styles.sep)}
              style={{ background: colorMap[entity.data.label] }}
            >
              <span className={styles.labelText}>{entity.data.label}</span>
              {displayEntityId && (
                <span className={styles.entityId}>({entity.id})</span>
              )}
            </div>
            <div className={classNames(styles.buttons, styles.sep)}>
              <ActionButton
                title={"Edit"}
                active={actionWindow === "editor"}
                onClick={toggleEditor}
                icon={"edit"}
                testId={"EditEntity"}
              />
              <ActionButton
                title={"Delete"}
                onClick={handleDelete}
                icon={"trash"}
                testId={"DeleteEntity"}
              />
              {entity.type === EntityType.node && (
                <ColorPicker
                  onChange={updateColor}
                  initialColor={colorMap[entity.data.label]}
                >
                  <ActionButton
                    style={{ color: colorMap[entity.data.label] }}
                    icon={"tint"}
                    title={"Change label color"}
                    testId={"ChangeLabelColor"}
                  />
                </ColorPicker>
              )}
            </div>
            {Object.keys(entityData).length > 0 && (
              <div className={classNames(styles.sep, styles.dataViewerWrapper)}>
                <EntityDataViewer
                  maxValueChars={maxValueChars}
                  data={entityData}
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
      {actionWindow === "editor" && (
        <EntityEditor
          valueOptions={valueOptions}
          keyOptions={keyOptions}
          entity={entity}
          save={onSaveEntityData}
          customValidator={customValidator}
          canModifyEntries={canModifyEntries}
        />
      )}
    </div>
  );
};

export default EntityToolbar;
