import { useCallback, useState } from "react";

export const useToolbarWindows = () => {
  const [activeWindow, setActiveWindow] = useState<string | null>(null);
  const isActive = (window: string) => activeWindow === window;
  const handleClick = useCallback(
    (window: string) => {
      return () => setActiveWindow(activeWindow === window ? null : window);
    },
    [activeWindow]
  );

  return {
    isActive,
    handleClick,
    activeWindow,
  };
};
