import {
  ITooltipProps,
  Position,
  Tooltip as BPTooltip,
} from "@blueprintjs/core";
import { FC } from "react";
import React from "react";

const Tooltip: FC<ITooltipProps> = ({
  children,
  content,
  position = Position.RIGHT,
  disabled,
}) => (
  <BPTooltip content={content} position={position} disabled={disabled}>
    {children}
  </BPTooltip>
);

export default Tooltip;
