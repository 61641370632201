import {
  IEdge,
  INode,
} from "../../../../../../../components/graph-builder/types/GraphTypes";

export class CommonUtils {
  /**
   *
   * @param label
   * @param entities
   */
  public static isNodeLabelExists(label: string, entities: INode[]) {
    return entities.some((e) => e.data.label === label);
  }

  /**
   *
   * @param label
   * @param source
   * @param target
   * @param entities
   */
  public static isEdgeLabelExists(
    label: string,
    source: number,
    target: number,
    entities: IEdge[]
  ) {
    return entities.some(
      (e) =>
        e.data.label === label && e.source === source && e.target === target
    );
  }
}
