import { useCallback, useContext, useState } from "react";
import { GraphBuilderService } from "../../../services/graph-builder";
import { useAnalytics } from "../../../shared/hooks";
import SandBoxContext from "../SandBoxContext";

export const useClearWorkspace = () => {
  const dispatch = useContext(SandBoxContext)[1];
  const [isLoading, setIsLoading] = useState(false);
  const [sendEvent] = useAnalytics();

  const clear = useCallback(async () => {
    sendEvent({
      category: "Sandbox",
      action: "Clear Workspace Pressed",
    });

    try {
      setIsLoading(true);
      await GraphBuilderService.dropGraph();
      dispatch({ type: "CLEAR_WORKSPACE" });
      setIsLoading(false);
    } catch (e) {}
  }, [dispatch, sendEvent]);

  return { clear, isLoading };
};
