import React, { FC } from "react";
import styles from "./Cloud.module.scss";

interface ICloudSpinnerProps {
  size?: number;
}

const CloudSpinner: FC<ICloudSpinnerProps> = ({ size = 75 }) => (
  <div className={styles.cloudSpinner} style={{ width: size, height: size }}>
    <svg
      id="enpo164tlyaj1"
      className={styles.cloudSpinnerSvg}
      viewBox="0 0 111 79"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <g id="enpo164tlyaj2">
        <g id="enpo164tlyaj3" transform="matrix(1 0 0 1 -7 -25)">
          <g id="enpo164tlyaj4" transform="matrix(1 0 0 1 9 25)">
            <path
              id="enpo164tlyaj5"
              d="M71.644589,53.006856L71.646120,53.224557C71.646120,66.103552,61.188565,76.544040,48.288517,76.544040C39.671184,76.544040,32.143776,71.885135,28.095486,64.952620C25.526984,66.186520,22.647300,66.877933,19.605864,66.877933C8.777844,66.877933,0,58.114415,0,47.304066C0,38.767151,5.474072,31.506687,13.109009,28.830474C12.924640,27.847453,12.828528,26.833421,12.828528,25.796979C12.828528,16.721625,20.197583,9.364597,29.287772,9.364597C31.485557,9.364597,33.582733,9.794655,35.499476,10.575079C38.097633,6.231972,42.852839,3.323280,48.288517,3.323280C53.662591,3.323280,58.371530,6.166416,60.988582,10.428266C63.125357,9.737546,65.405732,9.364597,67.773357,9.364597"
              fill="none"
              fillRule="evenodd"
              strokeWidth="3.936600"
              strokeLinecap="square"
            />
            <g
              id="enpo164tlyaj6"
              transform="matrix(1 0 0 1 29.91820000000000 0.17400000000000)"
            >
              <g
                className={styles.enpo164tlyaj7_ts}
                transform="translate(8.463690,32.477000) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj7"
                  r="6.495390"
                  transform="translate(0,0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="3.936600"
                />
              </g>
              <g
                className={styles.enpo164tlyaj8_ts}
                transform="translate(29.130800,49.207500) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj8"
                  r="4.723920"
                  transform="translate(0,0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="3.149280"
                />
              </g>
              <g
                className={styles.enpo164tlyaj9_ts}
                transform="translate(58.852200,50.979000) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj9"
                  r="6.495390"
                  transform="translate(0,0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="3.936600"
                />
              </g>
              <circle
                id="enpo164tlyaj10"
                r="4.527090"
                transform="matrix(1 0 0 1 43.49940000000000 23.81640000000000)"
                fill="none"
                fillRule="evenodd"
                strokeWidth="3.149280"
              />
              <g
                className={styles.enpo164tlyaj11_ts}
                transform="translate(70.268300,8.463690) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj11"
                  r="6.495390"
                  transform="translate(0,0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="3.936600"
                />
              </g>
              <g
                className={styles.enpo164tlyaj12_ts}
                transform="translate(34.968589,25.180027) rotate(167.999987) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj12"
                  r="1.218300"
                  transform="translate(-0,0)"
                  fill="none"
                  strokeWidth="1.500000"
                />
              </g>
              <g
                className={styles.enpo164tlyaj13_ts}
                transform="translate(29.577764,26.325953) rotate(167.999987) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj13"
                  r="1.218300"
                  transform="translate(0,-0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="1.500000"
                />
              </g>
              <g
                className={styles.enpo164tlyaj14_ts}
                transform="translate(18.770919,28.622886) rotate(167.999987) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj14"
                  r="1.218300"
                  transform="translate(-0,0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="1.500000"
                />
              </g>
              <g
                className={styles.enpo164tlyaj15_ts}
                transform="translate(24.186917,27.471681) rotate(167.999987) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj15"
                  r="1.218300"
                  transform="translate(-0,0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="1.500000"
                />
              </g>
              <g
                className={styles.enpo164tlyaj16_ts}
                transform="translate(39.140015,31.732968) rotate(120.000012) skewX(0) skewY(0) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj16"
                  r="1.218300"
                  transform="translate(0,0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="1.500000"
                />
              </g>
              <g
                className={styles.enpo164tlyaj17_ts}
                transform="translate(36.384415,36.505813) rotate(120.000012) skewX(0) skewY(0) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj17"
                  r="1.218300"
                  transform="translate(-0,0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="1.500000"
                />
              </g>
              <g
                className={styles.enpo164tlyaj18_ts}
                transform="translate(33.628764,41.278645) rotate(120.000012) skewX(0) skewY(0) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj18"
                  r="1.218300"
                  transform="translate(-0,0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="1.500000"
                />
              </g>
              <g
                className={styles.enpo164tlyaj19_ts}
                transform="translate(53.342461,41.396211) rotate(239.999988) skewX(-0) skewY(0) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj19"
                  r="1.218300"
                  transform="translate(0,0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="1.500000"
                />
              </g>
              <g
                className={styles.enpo164tlyaj20_ts}
                transform="translate(50.586810,36.622880) rotate(239.999988) skewX(-0) skewY(0) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj20"
                  r="1.218300"
                  transform="translate(-0,-0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="1.500000"
                />
              </g>
              <g
                className={styles.enpo164tlyaj21_ts}
                transform="translate(47.831296,31.849985) rotate(239.999988) skewX(-0) skewY(0) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj21"
                  r="1.218300"
                  transform="translate(0,0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="1.500000"
                />
              </g>
              <g
                className={styles.enpo164tlyaj22_ts}
                transform="translate(50.948455,18.945960) rotate(-30.000012) skewX(-0) skewY(0) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj22"
                  r="1.218300"
                  transform="translate(-0,-0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="1.500000"
                />
              </g>
              <g
                className={styles.enpo164tlyaj23_ts}
                transform="translate(55.721273,16.190441) rotate(-30.000012) skewX(-0) skewY(0) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj23"
                  r="1.218300"
                  transform="translate(-0,-0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="1.500000"
                />
              </g>
              <g
                className={styles.enpo164tlyaj24_ts}
                transform="translate(60.494180,13.434773) rotate(-30.000012) skewX(-0) skewY(0) scale(0,0)"
              >
                <circle
                  id="enpo164tlyaj24"
                  r="1.218300"
                  transform="translate(-0,-0)"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="1.500000"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export default CloudSpinner;
