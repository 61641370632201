import * as React from "react";
import {FC} from "react";
import {Intent} from "@blueprintjs/core";

interface IWithErrorsProps {
  errors?: string;
}

const withErrors = <D extends { intent?: Intent }>(Component: FC<D>): FC<D> => (
  props: (D & IWithErrorsProps)
) => <Component intent={props.errors ? Intent.DANGER : Intent.NONE} {...props} />;

export default withErrors;
