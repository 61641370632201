import { useCallback } from "react";
import { IKeyValueEntry } from "../../../../../../components/key-value-editor/KeyValueEditor";
import { GraphEntity } from "../../../../../../components/graph-builder/types/GraphTypes";
import { TypeUtils } from "../../../../../../utils/Type";
import { PropertyValueType } from "../../../../../../shared/types/common/graph";

export const useValidateEntityTypes = () => {
  const makeError = useCallback(
    (msg: string) => `Scheme Validation Error: ${msg}`,
    []
  );
  return useCallback(
    (entity: GraphEntity, entries: IKeyValueEntry[]) => {
      const typeMap: { [key: string]: string } = entity.data;

      const labelEntry = entries.find((entry) => entry.key === "label");
      for (let i = 0; i < entries.length; i++) {
        const { key, value } = entries[i];
        if (!typeMap[key]) {
          return makeError(
            labelEntry
              ? `Property '${key}' does not exist for '${labelEntry.value}'`
              : `Property '${key}' is invalid`
          );
        }

        const validation = TypeUtils.validateType(
          typeMap[key] as PropertyValueType,
          value
        );

        if (!validation) {
          return makeError(
            `Type mismatch - '${key}' must be of type ${typeMap[key]}`
          );
        }
      }
      return null;
    },
    [makeError]
  );
};
