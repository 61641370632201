import DagreLayout from "./DagreLayout";
import VivaLayout from "./VivaLayout";

export enum LayoutType {
  Dagre = "Dagre",
  Viva = "Viva",
}

export const DEFAULT_LAYOUT = LayoutType.Dagre;

class LayoutFactory {
  public static create(layout: LayoutType) {
    if (layout === LayoutType.Viva) {
      return new VivaLayout();
    } else {
      return new DagreLayout();
    }
  }
}

export default LayoutFactory;
