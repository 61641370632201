import { lazy } from "react";

export const Workspaces = lazy(() =>
  import("../workspaces").then(({ Workspaces }) => ({
    default: Workspaces,
  }))
);

export const Login = lazy(() =>
  import("../authentication/login").then(({ Login }) => ({
    default: Login,
  }))
);

export const SignUp = lazy(() =>
  import("../authentication/signUp").then(({ SignUp }) => ({
    default: SignUp,
  }))
);

export const ResetPassword = lazy(() =>
  import("../authentication/resetPassword").then(({ ResetPassword }) => ({
    default: ResetPassword,
  }))
);

export const ChangePassword = lazy(() =>
  import("../authentication/changePassword").then(({ ChangePassword }) => ({
    default: ChangePassword,
  }))
);

export const ChangeTempPassword = lazy(() =>
    import("../authentication/changeTempPassword").then(({ ChangeTempPassword }) => ({
        default: ChangeTempPassword,
    }))
);
