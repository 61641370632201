import { GraphUtils } from "../../../../../../services/graph-builder";
import { useCallback, useContext, useMemo } from "react";
import { EntityType } from "../../../../../../shared/types/common/enums";
import SandBoxContext from "../../../../SandBoxContext";
import {
  GraphEntity,
  IGraph,
} from "../../../../../../components/graph-builder/types/GraphTypes";
import { EntryPropsFactory } from "../utils/EntryValue";

export const usePropertyEntries = (
  entityType: EntityType | null,
  currentLabel: string
) => {
  const [state] = useContext(SandBoxContext);
  const {
    graphBuilder: { scheme: graph },
    schemaBuilder: { scheme: schema },
  } = state;
  const extractEntities = useCallback(
    (scheme: IGraph): GraphEntity[] => {
      return entityType === EntityType.edge ? scheme.edges : scheme.nodes;
    },
    [entityType]
  );

  const graphEntities = useMemo(() => extractEntities(graph), [
    extractEntities,
    graph,
  ]);
  const schemaEntities = useMemo(() => extractEntities(schema), [
    extractEntities,
    schema,
  ]);

  const hasSchema = schemaEntities.length > 0;

  const getLabelsForSelect = useCallback(() => {
    const labels = GraphUtils.getEntitiesLabels(schemaEntities);
    return labels.map((label) => ({ value: label, label }));
  }, [schemaEntities]);

  const getLabelsForAutoComplete = useCallback(
    () => GraphUtils.getEntitiesLabels(graphEntities),
    [graphEntities]
  );

  const createLabelValueProps = useCallback(() => {
    const baseProps = {
      editable: true,
      value: currentLabel,
    };
    if (hasSchema) {
      return {
        ...baseProps,
        selectOptions: getLabelsForSelect(),
        type: "select" as const,
      };
    } else {
      return {
        ...baseProps,
        autoCompleteItems: getLabelsForAutoComplete(),
        type: "text" as const,
      };
    }
  }, [currentLabel, getLabelsForAutoComplete, getLabelsForSelect, hasSchema]);

  const createLabelEntry = useCallback(() => {
    return {
      id: "label",
      keyProps: { editable: false, value: "label", type: "text" as const },
      valueProps: createLabelValueProps(),
    };
  }, [createLabelValueProps]);

  /**
   * retrieve entity type from schema
   */
  const getEntryType = useCallback(
    (prop: string) => {
      if (hasSchema) {
        const schemaNode = schemaEntities.find(
          (entity) => entity.data.label === currentLabel
        );
        if (schemaNode) {
          const entry = Object.entries(schemaNode.data).find(
            ([key, value]) => key === prop
          );
          if (entry) {
            return entry[1];
          }
        }
      }

      return "";
    },
    [currentLabel, hasSchema, schemaEntities]
  );

  const createEntry = useCallback(
    (prop: string) => {
      if (prop === "label") {
        return createLabelEntry();
      }

      const entryType = getEntryType(prop);
      const valueProps = EntryPropsFactory.createValueProps(entryType);
      const keyProps = {
        editable: !hasSchema,
        value: prop,
        type: "text" as const,
      };

      return {
        id: prop,
        keyProps,
        valueProps,
      };
    },
    [createLabelEntry, getEntryType, hasSchema]
  );

  /**
   * calculate properties
   */
  const properties = useMemo(() => {
    const entities = hasSchema ? schemaEntities : graphEntities;
    const entitiesByLabel = entities.filter(
      (e) => e.data.label === currentLabel
    );
    const props: string[] = ["label"];
    entitiesByLabel.forEach((entity) => {
      const properties = GraphUtils.getEntityProperties(entity);
      properties.forEach((prop) => {
        if (!props.includes(prop)) {
          props.push(prop);
        }
      });
    });
    return props;
  }, [currentLabel, graphEntities, hasSchema, schemaEntities]);

  return useMemo(() => {
    return properties.map(createEntry);
  }, [createEntry, properties]);
};
