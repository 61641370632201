import { InputGroup } from "../../../form";
import * as React from "react";
import styles from "./Entry.module.scss";
import { FC, useCallback } from "react";
import { AutoComplete } from "../../../autocomplete";
import classNames from "classnames";
import { HTMLSelect } from "../../../html-select";
import { IEntryLinePartialProps } from "../../useKeyValueEntries";
import { TypeTag } from "../../../TypeTag";

export interface IEntry {
  index: number;
  onChange: (index: number, name: string, value: string) => void;
  valueProps: IEntryLinePartialProps;
  keyProps: IEntryLinePartialProps;
}

const Entry: FC<IEntry> = ({ index, onChange, keyProps, valueProps }) => {
  const onChangeKey = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { name, value } = event.target as HTMLInputElement;
      onChange(index, name, value);
    },
    [index, onChange]
  );

  const onChangeValue = useCallback(
    (name: string, value: string) => {
      onChange(index, name, value);
    },
    [index, onChange]
  );

  const handleValueSelectChange = useCallback(
    (event) => {
      onChange(index, event.target.name, event.target.value);
    },
    [index, onChange]
  );

  return (
    <div className={styles.entry}>
      <InputGroup
        name={"key"}
        disabled={!keyProps.editable}
        className={classNames(styles.input, styles.key)}
        placeholder={"Property Name"}
        value={keyProps.value}
        autoComplete={"off"}
        onChange={keyProps.editable ? onChangeKey : undefined}
      />
      <div className={styles.valueWrapper}>
        {valueProps.valueType && (
          <TypeTag type={valueProps.valueType} className={styles.tag} />
        )}
        {(valueProps.type === "text" || valueProps.type === "number") && (
          <AutoComplete
            items={valueProps.autoCompleteItems || []}
            name={"value"}
            inputProps={{
              placeholder: "Property Value",
              disabled: !valueProps.editable,
            }}
            className={styles.input}
            value={valueProps.value}
            onChange={valueProps.editable ? onChangeValue : undefined}
          />
        )}
        {valueProps.type === "select" && valueProps.selectOptions && (
          <HTMLSelect
            name={"value"}
            value={valueProps.value}
            onChange={handleValueSelectChange}
            className={classNames(styles.select, {
              [styles.hasTypeTag]: valueProps.valueType !== undefined,
            })}
            disabled={!valueProps.editable}
          >
            {valueProps.selectOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </HTMLSelect>
        )}
      </div>
    </div>
  );
};

export default Entry;
