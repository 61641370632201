import { useCallback, useContext, useEffect, useState } from "react";
import { useColorMap, useCreateEdge } from "../common";
import {
  GraphEntity,
  IEdge,
  IEntityData,
  IGraph,
  INode,
} from "../../../../../components/graph-builder/types/GraphTypes";
import {
  UPDATE_GRAPH_LAYOUT,
  UPDATE_GRAPH,
} from "../../../reducers/SandboxActions";
import {
  useAddEdge,
  useSwapEdge,
  useUpdateEdge,
  useUpdateVertex,
  useDeleteEdge,
  useDeleteVertex,
  useAddVertex,
} from "../../../../../services/graph-builder";
import GraphUtils from "../../../../../services/graph-builder/GraphUtils";
import { EntityType } from "../../../../../shared/types/common/enums";
import NodeFactory from "../../../../../components/graph-builder/factory/NodeFactory";
import EdgeFactory from "../../../../../components/graph-builder/factory/EdgeFactory";
import { LayoutType } from "../../../../../services/graph-builder/Layout/LayoutFactory";
import SandBoxContext from "../../../SandBoxContext";
import { useCreateNode, useDeleteNode } from "../common";
import { IKeyValueEntry } from "../../../../../components/key-value-editor/KeyValueEditor";
import { useValidateEntityTypes } from "../common/hooks/useValidateEntityTypes";
import { useSchemaValidator } from "../common/hooks/useSchemaValidator";
import { useNotification } from "../../../../../services/notification";

const useGraphBuilder = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const { graphBuilder } = state;
  const { scheme } = graphBuilder;
  const [isProcessing, setIsProcessing] = useState(false);
  const [selected, setSelected] = useState<GraphEntity | null>(null);
  const { notifyError } = useNotification();
  const [creatingEntityOfType, setCreatingEntityOfType] = useState<{
    type: EntityType;
    args: unknown[];
  } | null>(null);
  const [clipboard, setClipboard] = useState<INode | null>(null);
  const [lastEntityLabels, setLastEntityLabels] = useState<{
    node: string;
    edge: string;
  }>({ node: NodeFactory.DefaultLabel, edge: EdgeFactory.DefaultLabel });
  const { colorMap, updateColor, assignColorToLabel } = useColorMap();
  const validateEntries = useValidateEntityTypes();
  const { validateEdge } = useSchemaValidator();
  const createNodeMutator = useCreateNode(scheme);
  const deleteNodeMutator = useDeleteNode(scheme);
  const createEdgeMutator = useCreateEdge(scheme);
  const addVertex = useAddVertex();
  const addEdge = useAddEdge();
  const updateVertex = useUpdateVertex();
  const updateEdge = useUpdateEdge();
  const swapEdge = useSwapEdge();
  const deleteVertex = useDeleteVertex();
  const deleteEdge = useDeleteEdge();

  const updateScheme = useCallback(
    (nextScheme: IGraph) => {
      dispatch({ type: UPDATE_GRAPH, payload: { scheme: nextScheme } });
    },
    [dispatch]
  );

  const addLabel = useCallback(
    (entityType: EntityType, label: string) => {
      dispatch({ type: "ADD_LABEL", payload: { entityType, label } });
    },
    [dispatch]
  );

  const cleanDummyEntities = useCallback(() => {
    const nextScheme = GraphUtils.cleanGraphFromDummyEntities(scheme);
    updateScheme(nextScheme);
  }, [updateScheme, scheme]);

  const executeAction = async (action: () => Promise<unknown>) => {
    setIsProcessing(true);
    const result = await action();
    setIsProcessing(false);
    return result;
  };

  const onMaybeCreateNode = useCallback(
    async (x: number, y: number) => {
      const node = GraphUtils.createNode(x, y, { label: "?" });
      const dummyScheme = {
        edges: scheme.edges,
        nodes: [
          ...scheme.nodes,
          {
            ...node,
            id: -1,
          },
        ],
      };

      updateScheme(dummyScheme);
      setCreatingEntityOfType({ type: EntityType.node, args: [x, y] });
    },
    [updateScheme, scheme]
  );

  const onMaybeCreateEdge = useCallback(
    async (source: INode, target: INode) => {
      const edge = GraphUtils.createEdge(source, target, { label: "?" });
      const dummyScheme = {
        nodes: scheme.nodes,
        edges: [
          ...scheme.edges,
          {
            ...edge,
            id: -1,
          },
        ],
      };

      updateScheme(dummyScheme);
      setCreatingEntityOfType({
        type: EntityType.edge,
        args: [source, target],
      });
    },
    [updateScheme, scheme]
  );

  const createNode = useCallback(
    async (x: number, y: number, data: IEntityData) => {
      return await executeAction(async () => {
        const node = GraphUtils.createNode(x, y, data);
        const addResult = await addVertex(node);
        node.id = addResult.id;
        const nextScheme = createNodeMutator(node);
        updateScheme(nextScheme);
        assignColorToLabel(node.data.label);
        addLabel(EntityType.node, node.data.label);
        return node;
      });
    },
    [addLabel, addVertex, assignColorToLabel, createNodeMutator, updateScheme]
  );

  const createEdge = useCallback(
    async (source: INode, target: INode, data: IEntityData) => {
      const edge = GraphUtils.createEdge(source, target, data);
      const error = validateEdge(edge);
      return await executeAction(async () => {
        if (error) {
          notifyError(error);
          return null;
        }
        const addResult = await addEdge(edge);
        edge.id = addResult.id;
        const nextScheme = createEdgeMutator(edge);
        updateScheme(nextScheme);
        addLabel(EntityType.edge, edge.data.label);
        return edge;
      });
    },
    [
      addEdge,
      addLabel,
      createEdgeMutator,
      notifyError,
      updateScheme,
      validateEdge,
    ]
  );

  const onCreateEntity = useCallback(
    async (data: IEntityData) => {
      if (creatingEntityOfType) {
        setLastEntityLabels({
          ...lastEntityLabels,
          [creatingEntityOfType.type]: data.label,
        });
        const { args = [] } = creatingEntityOfType;
        let entity: GraphEntity;

        if (creatingEntityOfType.type === EntityType.node) {
          entity = (await createNode(
            args[0] as number,
            args[1] as number,
            data
          )) as INode;
        } else {
          entity = (await createEdge(
            args[0] as INode,
            args[1] as INode,
            data
          )) as IEdge;
        }

        if (entity) {
          setSelected(entity);
        } else {
          cleanDummyEntities();
        }
        setCreatingEntityOfType(null);
      }
    },
    [
      creatingEntityOfType,
      lastEntityLabels,
      createNode,
      createEdge,
      cleanDummyEntities,
    ]
  );

  const onDeleteNode = useCallback(
    async (node: INode, nodeId: number, nodes: INode[]) => {
      return await executeAction(async () => {
        await deleteVertex(node);
        const nextScheme = deleteNodeMutator(node, nodeId, nodes);
        updateScheme(nextScheme);
      });
    },
    [deleteNodeMutator, deleteVertex, updateScheme]
  );

  const onDeleteEdge = useCallback(
    async (edge: IEdge) => {
      return await executeAction(async () => {
        await deleteEdge(edge);
        const nextScheme = {
          edges: scheme.edges.filter((e) => e.id !== edge.id),
          nodes: scheme.nodes,
        };

        updateScheme(nextScheme);
      });
    },
    [deleteEdge, scheme.edges, scheme.nodes, updateScheme]
  );

  const onDeleteEntity = useCallback(
    async (entity: GraphEntity) => {
      if (entity.type === EntityType.node) {
        await onDeleteNode(
          entity,
          entity.id,
          scheme.nodes.filter((n) => n.id !== entity.id)
        );
      } else if (entity.type === EntityType.edge) {
        await onDeleteEdge(entity);
      }
    },
    [onDeleteEdge, onDeleteNode, scheme.nodes]
  );

  const updateNodeData = useCallback(
    async (node: INode) => {
      return await executeAction(async () => {
        const result = await updateVertex(node);
        if (result) {
          setSelected(node);
          return {
            edges: scheme.edges,
            nodes: scheme.nodes.map((n) =>
              n.id === node.id ? { ...node } : n
            ),
          };
        }

        return null;
      });
    },
    [scheme.edges, scheme.nodes, updateVertex]
  );

  const updateEdgeData = useCallback(
    async (edge: IEdge) => {
      return await executeAction(async () => {
        const result = await updateEdge(edge);
        if (result) {
          setSelected(edge);
          return {
            edges: scheme.edges.map((e) =>
              e.id === edge.id ? { ...edge } : e
            ),
            nodes: scheme.nodes,
          };
        }
        return null;
      });
    },
    [updateEdge, scheme.edges, scheme.nodes]
  );

  const onUpdateEntityData = useCallback(
    async (entity: GraphEntity) => {
      let nextScheme = null;
      if (entity.type === "node") {
        nextScheme = await updateNodeData(entity);
      } else {
        nextScheme = await updateEdgeData(entity);
      }

      if (nextScheme) {
        updateScheme(nextScheme as IGraph);
      }
    },
    [updateScheme, updateEdgeData, updateNodeData]
  );

  const closeCreationDialog = useCallback(() => {
    cleanDummyEntities();
    setCreatingEntityOfType(null);
  }, [cleanDummyEntities]);

  const onUpdateNodePosition = useCallback(
    (node: INode) => {
      updateScheme({
        nodes: scheme.nodes.map((n) => (n.id === node.id ? { ...node } : n)),
        edges: scheme.edges,
      });
    },
    [scheme, updateScheme]
  );

  const onSwapEdge = useCallback(
    async (source: INode, target: INode, edge: IEdge) => {
      return await executeAction(async () => {
        const oldEdge = scheme.edges.find((e) => e.id === edge.id);
        const newEdge = GraphUtils.createEdge(source, target, edge.data);

        if (oldEdge) {
          const swapResult = await swapEdge(oldEdge, newEdge);
          const nextScheme = {
            edges: scheme.edges.map((e) =>
              e.id === oldEdge.id ? { ...newEdge, id: swapResult.id } : e
            ),
            nodes: scheme.nodes,
          };
          updateScheme(nextScheme);
        }
      });
    },
    [scheme.edges, scheme.nodes, swapEdge, updateScheme]
  );

  const onSelectNode = useCallback((node: INode) => {
    setSelected(node);
  }, []);

  const onSelectEdge = useCallback((edge: IEdge) => {
    setSelected(edge);
  }, []);

  const onCopy = useCallback(() => {
    if (selected && selected.type === "node") {
      const copied = GraphUtils.copyNode(selected);
      setClipboard(copied);
    }
  }, [selected]);

  const onPaste = useCallback(async () => {
    if (clipboard !== null) {
      setSelected(clipboard);
      await createNode(clipboard.x, clipboard.y, clipboard.data);
      setClipboard(null);
    }
  }, [clipboard, createNode]);

  // reset selected entity if entity does not exist anymore
  useEffect(() => {
    const entities = [...scheme.edges, ...scheme.nodes];
    if (selected) {
      const previousSchemeSelectedEntity = entities.find(
        (e) => e.id === selected.id
      );
      const selectedEntity = previousSchemeSelectedEntity
        ? previousSchemeSelectedEntity
        : null;
      setSelected(selectedEntity);
    }
  }, [selected, scheme.edges, scheme.nodes]);

  const updateLabelColor = useCallback(
    (label: string, color: string) => {
      updateColor(label, color);
    },
    [updateColor]
  );
  const updateLayout = useCallback(
    (layout: LayoutType) => {
      const updatedScheme = GraphUtils.generateGraphFromLayout(layout, scheme);
      dispatch({
        type: UPDATE_GRAPH_LAYOUT,
        payload: { layout: layout, scheme: updatedScheme },
      });
    },
    [dispatch, scheme]
  );

  const validateEntityEntries = useCallback(
    (entries: IKeyValueEntry[]) => {
      const {
        schemaBuilder: {
          scheme: { edges, nodes },
        },
      } = state;
      if (selected) {
        const entities: GraphEntity[] =
          selected.type === EntityType.edge ? edges : nodes;
        const schemaEntity = entities.find(
          (e) => e.data.label === selected.data.label
        );
        return schemaEntity ? validateEntries(schemaEntity, entries) : null;
      }
      return null;
    },
    [selected, state, validateEntries]
  );

  return {
    graph: scheme,
    onCreateEntity,
    onDeleteNode,
    onDeleteEdge,
    onDeleteEntity,
    onMaybeCreateNode,
    onMaybeCreateEdge,
    isProcessing,
    onUpdateNodePosition,
    onUpdateEntityData,
    creatingEntityOfType,
    closeCreationDialog,
    lastEntityLabels,
    onSwapEdge,
    onCopy,
    onPaste,
    onSelectNode,
    onSelectEdge,
    selected,
    updateLabelColor,
    colorMap,
    updateLayout,
    validateEntityEntries,
  };
};

export default useGraphBuilder;
