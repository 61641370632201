import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Dialog } from "../../../../../../components/dialog";
import { useExportToGremlin } from "./useExportToGremlin";
import SandBoxContext from "../../../../SandBoxContext";
import { CloudSpinner } from "../../../../../../components/spinner";
import styles from "./ExportDialog.module.scss";
import { Pre } from "../../../../../../components/pre";
import { Button } from "../../../../../../components/button";
import { useCopyToClipboard } from "../../../../../../shared/hooks";

interface IExportResultDialog {
  isOpen: boolean;
  onClose: () => void;
}

export const ExportResultDialog: FC<IExportResultDialog> = ({
  isOpen,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [state] = useContext(SandBoxContext);
  const exportToGremlin = useExportToGremlin();

  useEffect(() => {
    const fetchQuery = async () => {
      setIsLoading(true);
      const result = await exportToGremlin(state.graphBuilder.scheme);
      setQuery(result);
      setIsLoading(false);
    };

    if (isOpen) {
      fetchQuery();
    }
  }, [exportToGremlin, isOpen, state.graphBuilder.scheme]);

  const copyToClipboard = useCopyToClipboard();
  const handleCopy = useCallback(() => copyToClipboard(query), [copyToClipboard, query]);

  return (
    <Dialog
      onClose={onClose}
      className={styles.exportDialog}
      title={`Export Graph as Gremlin query`}
      isOpen={isOpen}
    >
      <div className={styles.content}>
        {isLoading ? (
          <CloudSpinner />
        ) : (
          <Pre className={styles.query}>{query}</Pre>
        )}
      </div>
      <div className={styles.footer}>
        <Button
          icon={"clipboard"}
          intent={"primary"}
          text={"Copy to Clipboard"}
          onClick={handleCopy}
        />
      </div>
    </Dialog>
  );
};
