import { useCallback } from "react";
import {
  GraphEntity,
  IEntityData,
  IGraph,
} from "../../../../../../components/graph-builder/types/GraphTypes";
import { ITypeMap } from "../../../../../../utils/Type/TypeUtils";
import { TypeUtils } from "../../../../../../utils/Type";
import { useEdgePredicate, useNodePredicate } from "./entityPredicates";
import { PropertyValueTypeMap } from "../../../../../../shared/constants/common/common";

export const useCompareSchemes = () => {
  const typeDataEntries = useCallback((data: IEntityData) => {
    const { label, ...rest } = data;
    return Object.fromEntries(
      Object.entries(rest).map(([key, value]) => [
        key,
        TypeUtils.inferType(value),
      ])
    );
  }, []);

  /**
   * compare schema and graph types. String is a loose type.
   */
  const compareTypes = useCallback(
    (schemeTypes: ITypeMap, graphTypes: ITypeMap) => {
      const graphEntries = Object.entries(graphTypes);
      return graphEntries.every(([key, type]) => {
        return (
          schemeTypes[key] === PropertyValueTypeMap.STRING ||
          schemeTypes[key] === type
        );
      });
    },
    []
  );

  const makeEdgePredicate = useEdgePredicate();
  const makeNodePredicate = useNodePredicate();
  /**
   * compares the entities.
   * Note that that a graph is contained within a schema
   */
  const compareEntities = useCallback(
    (
      graphEntities: GraphEntity[],
      schemaEntities: GraphEntity[],
      predicate: (entity: GraphEntity) => GraphEntity | undefined
    ) => {
      return graphEntities.every((entity) => {
        const entityDataTypes = typeDataEntries(entity.data);
        const schemaEntity = predicate(entity);
        if (schemaEntity) {
          const { label, ...schemaEntityTypes } = schemaEntity.data;
          return compareTypes(schemaEntityTypes, entityDataTypes);
        }
        return false;
      });
    },
    [compareTypes, typeDataEntries]
  );

  return useCallback(
    (graph: IGraph, schema: IGraph) => {
      const nodesPredicate = (node: GraphEntity) =>
        makeNodePredicate(node, schema);
      const edgesPredicate = (edge: GraphEntity) =>
        makeEdgePredicate(edge, schema, graph);

      return (
        compareEntities(graph.nodes, schema.nodes, nodesPredicate) &&
        compareEntities(graph.edges, schema.edges, edgesPredicate)
      );
    },
    [compareEntities, makeEdgePredicate, makeNodePredicate]
  );
};
