import { SandboxAction } from "../../../reducers/SandboxActions";
import { IGraph } from "../../../../../components/graph-builder/types/GraphTypes";
import { LayoutType } from "../../../../../services/graph-builder/Layout/LayoutFactory";
import { EntityType } from "../../../../../shared/types/common/enums";

export type GraphBuilderAction =
  | {
      type: "UPDATE_GRAPH_ID";
      payload: { id: string };
    }
  | { type: "UPDATE_GRAPH"; payload: { scheme: IGraph } }
  | { type: "UPDATE_GRAPH_SUCCESS" }
  | {
      type: "UPDATE_GRAPH_LAYOUT";
      payload: { scheme: IGraph; layout: LayoutType };
    }
  | {
      type: "UPDATE_COLOR_MAP";
      payload: { nodeLabelsColorMap: { [key: string]: string } };
    }
  | {
      type: "UPDATE_PROPERTY_DISPLAY";
      payload: { label: string; entityType: EntityType; property: string };
    }
  | { type: "RESET_DISPLAY_SETTINGS" };

interface IDisplayByPropertySettings {
  edges: { [key: string]: string };
  nodes: { [key: string]: string };
}

export interface IGraphBuilderState {
  scheme: IGraph;
  id: string;
  isDirty: boolean;
  lastUpdatedTime: number | null;
  layout: LayoutType | null;
  displayByProperty: IDisplayByPropertySettings;
}

export const initialState: IGraphBuilderState = {
  scheme: {
    nodes: [],
    edges: [],
  },
  id: "initialScheme",
  lastUpdatedTime: null,
  isDirty: true,
  layout: null,
  displayByProperty: {
    edges: {},
    nodes: {},
  },
};

export const reducer = (
  action: SandboxAction,
  state: IGraphBuilderState
): IGraphBuilderState => {
  switch (action.type) {
    case "SET_WORKSPACE":
      return {
        ...state,
        isDirty: false,
        lastUpdatedTime: Date.now(),
        scheme: action.payload.graph,
        layout: action.payload.layout,
        displayByProperty: { ...initialState.displayByProperty },
      };
    case "CLEAR_WORKSPACE":
      return {
        ...state,
        scheme: initialState.scheme,
        lastUpdatedTime: Date.now(),
        displayByProperty: { ...initialState.displayByProperty },
        layout: null,
      };
    case "UPDATE_GRAPH":
      const { scheme } = action.payload;
      return {
        ...state,
        lastUpdatedTime: Date.now(),
        isDirty: true,
        scheme,
      };
    case "UPDATE_GRAPH_ID":
      return {
        ...state,
        id: action.payload.id,
      };
    case "UPDATE_GRAPH_LAYOUT":
      return {
        ...state,
        scheme: action.payload.scheme,
        layout: action.payload.layout,
      };
    case "UPDATE_GRAPH_SUCCESS":
      return {
        ...state,
        isDirty: false,
        lastUpdatedTime: Date.now(),
      };
    case "UPDATE_PROPERTY_DISPLAY":
      const { entityType, label, property } = action.payload;
      const edges = { ...state.displayByProperty.edges };
      const nodes = { ...state.displayByProperty.nodes };
      return {
        ...state,
        displayByProperty: {
          ...state.displayByProperty,
          edges:
            entityType === EntityType.edge
              ? { ...edges, [label]: property }
              : edges,
          nodes:
            entityType === EntityType.node
              ? { ...nodes, [label]: property }
              : nodes,
        },
      };
    case "RESET_DISPLAY_SETTINGS":
      return {
        ...state,
        displayByProperty: { ...initialState.displayByProperty },
      };
  }

  return state;
};
