import React, { useCallback, useContext } from "react";
import SandBoxContext from "../../../../../../SandBoxContext";
import {
  useSelectEdges,
  useSelectEdgesPropertyDisplaySettings,
  useSelectNodes,
  useSelectNodesPropertyDisplaySettings,
} from "../../../../common/selectors/GraphBuilderSelectors";
import { PropList } from "./PropList";
import { EntityType } from "../../../../../../../../shared/types/common/enums";

export const CustomLabelProperties = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const nodes = useSelectNodes(state);
  const edges = useSelectEdges(state);
  const edgesSettings = useSelectEdgesPropertyDisplaySettings(state);
  const nodesSettings = useSelectNodesPropertyDisplaySettings(state);

  const handleChange = useCallback(
    (entityType: EntityType, label: string, property: string) => {
      dispatch({
        type: "UPDATE_PROPERTY_DISPLAY",
        payload: { label, property, entityType },
      });
    },
    [dispatch]
  );
  return (
    <div>
      <PropList
        onChange={handleChange}
        entityType={EntityType.node}
        labelPropertyMap={nodesSettings}
        entities={nodes}
      />
      <PropList
        labelPropertyMap={edgesSettings}
        onChange={handleChange}
        entityType={EntityType.edge}
        entities={edges}
      />
    </div>
  );
};
