import { useMemo } from "react";
import { ISandBoxReducerState } from "../../../../reducers/SandBoxReducer";

/**
 *
 * @param state
 */
export const useSelectSchemaNodes = (state: ISandBoxReducerState) =>
  useMemo(() => state.schemaBuilder.scheme.nodes, [
    state.schemaBuilder.scheme.nodes,
  ]);

/**
 *
 * @param state
 */
export const useSelectSchemaEdges = (state: ISandBoxReducerState) =>
  useMemo(() => state.schemaBuilder.scheme.edges, [
    state.schemaBuilder.scheme.edges,
  ]);


/**
 *
 * @param state
 */
export const useSelectSchemaScheme = (state: ISandBoxReducerState) =>
  useMemo(() => state.schemaBuilder.scheme, [state.schemaBuilder.scheme]);
