import React, { FC } from "react";
import {
  ActionsToolbar,
  ActionsToolbarButton,
  ToolbarContainer,
  useToolbarWindows,
} from "../../common/components/ActionsToolbar";
import { Instructions } from "../../common/components/Instructions";

interface ISchemaActionsProps {
  onClearSchema: () => void;
}

export const SchemaActions: FC<ISchemaActionsProps> = ({ onClearSchema }) => {
  // TODO: lift up if needed
  const { activeWindow, handleClick, isActive } = useToolbarWindows();
  return (
    <ToolbarContainer>
      <ActionsToolbar hasActiveWindow={activeWindow !== null}>
        <ActionsToolbarButton
          onClick={onClearSchema}
          disabled={false}
          icon={"trash"}
          tooltipText={"Clear Schema"}
        />
        <ActionsToolbarButton
          onClick={handleClick("help")}
          tooltipText={"Help"}
          icon={"info-sign"}
          active={isActive("help")}
          className={"gy-graph-help"}
        />
      </ActionsToolbar>
      {isActive("help") && <Instructions />}
    </ToolbarContainer>
  );
};
