import { IKeyValueEntry } from "./KeyValueEditor";

class KeyValueUtils {
  /**
   *
   * @param entries
   */
  public static fromEntries(entries: IKeyValueEntry[]) {
    return entries.reduce((obj, entry) => {
      if (entry.key) {
        (obj as any)[entry.key] = entry.value;
      }
      return obj;
    }, {});
  }

  /**
   *
   * @param entries
   */
  public static hasDuplicates(entries: IKeyValueEntry[]) {
    const keys = entries.map((entry) => entry.key);

    return new Set(keys).size !== keys.length;
  }

  /**
   *
   * @param entries
   * @param customValidator
   */
  public static validate(
    entries: IKeyValueEntry[],
    customValidator?: (entries: IKeyValueEntry[]) => string | null
  ) {
    const hasEmptyValues = entries.some(({ key, value }) => {
      return !isNaN(parseFloat(key)) || key === "" || value === "";
    });

    if (hasEmptyValues) {
      return "Property Name / Value must not be empty.";
    }

    if (this.hasDuplicates(entries)) {
      return "Duplicate Property names are not allowed.";
    }

    if (customValidator) {
      const customValidation = customValidator(entries);

      if (customValidation) {
        return customValidation;
      }
    }

    return null;
  }
}

export default KeyValueUtils;
