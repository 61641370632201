export { Intent } from "@blueprintjs/core";

export enum ConnectionType {
  http = "http",
  https = "https"
}

export enum EntityType {
  node = "node",
  edge = "edge"
}
