import { IGraph } from "../../../components/graph-builder/types/GraphTypes";
import { LayoutType } from "../../../services/graph-builder/Layout/LayoutFactory";
import { FavouriteQueriesAction } from "../../favourite-queries/FavouriteQueriesReducer";
import { HistoryAction } from "../../history/HistoryReducer";
import { ResultsAction } from "../components/results/ResultsReducer";
import { QueryConsoleAction } from "../components/query-console/QueryConsoleReducer";
import { GraphBuilderAction } from "../components/builders/GraphBuilder/GraphBuilderReducer";
import { MetadataAction } from "../../metadata/MetadataReducer";
import { HelpAction } from "../../help/HelpReducer";
import { UpdatesAction } from "../../updates/UpdatesReducer";
import { SchemaBuilderAction } from "../components/builders/SchemaBuilder/SchemaBuilderReducer";
import { LabelsAction } from "../components/builders/common/reducers/LabelsReducer";
import { ColorMapAction } from "../components/builders/common/reducers/ColorMapReducer";
import { IQuery } from "../components/query-console/Query.interface";

export const UPDATE_GRAPH = "UPDATE_GRAPH";
export const UPDATE_QUERY = "UPDATE_QUERY";
export const SET_WORKSPACE = "SET_WORKSPACE";
export const TOGGLE_READY = "TOGGLE_READY";
export const RUN_FINISHED = "RUN_FINISHED";
export const UPDATE_WORKSPACE_META = "UPDATE_WORKSPACE_META";
export const HIDE_NEW_UPDATES = "HIDE_NEW_UPDATES";
export const TOGGLE_HELP_TOUR = "TOGGLE_HELP_TOUR";
export const UPDATE_GRAPH_ID = "UPDATE_GRAPH_ID";
export const UPDATE_GRAPH_LAYOUT = "UPDATE_GRAPH_LAYOUT";

export type SandboxAction =
  | {
      type: "SET_WORKSPACE";
      payload: {
        graph: IGraph;
        schema: IGraph;
        queries: IQuery[];
        title: string;
        description: string;
        isPrivate: boolean;
        layout: LayoutType;
        nodeLabelsColorMap: { [key: string]: string };
        id: number;
      };
    }
  | { type: "TOGGLE_READY"; payload: { ready: boolean } }
  | { type: "CLEAR_WORKSPACE" }
  | FavouriteQueriesAction
  | HistoryAction
  | ResultsAction
  | QueryConsoleAction
  | GraphBuilderAction
  | MetadataAction
  | HelpAction
  | UpdatesAction
  | SchemaBuilderAction
  | LabelsAction
  | ColorMapAction;
