import React, { FC, useEffect, useRef } from "react";
import { ContextMenu as BPContextMenu, IconName } from "@blueprintjs/core";

export interface IContextMenuItem {
  label: string;
  icon?: IconName;
  onClick?: () => void;
  subMenu?: IContextMenuItem[];
}

interface IContextMenuProps {
  wrapperId: string;
  className?: string;
  content?: JSX.Element;
}

const ContextMenu: FC<IContextMenuProps> = ({
  wrapperId,
  children,
  className,
  content,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current && content) {
      ref.current.oncontextmenu = (e: MouseEvent) => {
        e.preventDefault();
        BPContextMenu.show(content, { left: e.clientX, top: e.clientY });
      };
    }
  }, [ref, content]);
  return (
    <div className={className} id={wrapperId} ref={ref}>
      {children}
    </div>
  );
};

export default ContextMenu;
