import React, { FC, useCallback, useState } from "react";
import { InputGroup } from "../form";

interface IEditableProps {
  value: string;
  onChange: (value: string) => void;
  inputClassName?: string;
  defaultValue?: string;
}
const Editable: FC<IEditableProps> = ({
  value,
  inputClassName,
  onChange,
  defaultValue,
}) => {
  const [editing, setEditing] = useState(false);

  const startEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const stopEdit = useCallback(() => {
    setEditing(false);
  }, []);

  const handleKeyDown = useCallback((evt) => {
    if (evt.key === 'Escape' || evt.key === 'Enter') {
      stopEdit();
    }
  }, [stopEdit]);

  const handleChange = useCallback(
    (evt) => {
      onChange(evt.target.value);
    },
    [onChange]
  );

  return editing ? (
    <InputGroup
      className={inputClassName}
      value={value}
      autoFocus={true}
      onBlur={stopEdit}
      onKeyDown={handleKeyDown}
      placeholder={defaultValue}
      onChange={handleChange}
    />
  ) : (
    <div onClick={startEdit}>{value ? value : defaultValue}</div>
  );
};

export default Editable;
