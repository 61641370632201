import AppRestService from "../communication/AppRestService";
import { NotificationService } from "../notification";
import isArray from "lodash/isArray";
import {
  IGraph,
  INode,
  IEdge,
} from "../../components/graph-builder/types/GraphTypes";
import { IObject } from "../communication/RestTypes";

class GraphBuilderService {
  /**
   *
   * @param vertex
   */
  public async addVertex(vertex: INode) {
    return await this.execute("vertex", "add", vertex);
  }

  /**
   *
   * @param vertex
   */
  public async updateVertex(vertex: INode) {
    return await this.execute("vertex", "update", vertex);
  }

  /**
   *
   * @param vertex
   */
  public async deleteVertex(vertex: INode) {
    return await this.execute("vertex", "delete", vertex);
  }

  /**
   *
   * @param edge
   */
  public async addEdge(edge: IEdge) {
    return await this.execute("edge", "add", edge);
  }

  /**
   *
   * @param edge
   */
  public async updateEdge(edge: IEdge) {
    return await this.execute("edge", "update", edge);
  }

  /**
   *
   * @param edge
   */
  public async deleteEdge(edge: IEdge) {
    return await this.execute("edge", "delete", edge);
  }

  /**
   *
   * @param oldEdge
   * @param newEdge
   */
  public async swapEdge(oldEdge: IEdge, newEdge: IEdge) {
    return await this.execute("edge", "swap", { oldEdge, newEdge });
  }

  /**
   *
   */
  public async dropGraph() {
    return await this.execute(null, "drop");
  }

  /**
   *
   * @param scheme
   */
  public async exportToQuery(scheme: IGraph) {
    return await this.execute(null, "query", { scheme });
  }

  /**
   *
   * @param graph
   * @param schema
   */
  public async enforceSchema(schema: IGraph, graph: IGraph) {
    return await this.execute(null, "schema/enforce", { schema, graph });
  }

  /**
   *
   * @param entityType
   * @param actionName
   * @param data
   * @private
   */
  private async execute(
    entityType: "edge" | "vertex" | null,
    actionName: string,
    data?: IObject
  ) {
    try {
      const path = [entityType, actionName].filter((p) => p).join("/");
      const response = await AppRestService.post(`graph-builder/${path}`, data);
      return response.data;
    } catch (error) {
      if (isArray(error.message)) {
        // TODO: handle properly!!!
        /*** fix ***/
        const errors = error.message
          .reduce((acc: string[], err: any) => {
            return acc.concat(Object.values(err.constraints));
          }, [] as string[])
          .join();
        /*** fix ***/
        NotificationService.error(errors);
      } else {
        NotificationService.error(error.message);
      }
    }
  }
}

export default new GraphBuilderService();
