import {IEdge, IEntityData} from "../types/GraphTypes";

class EdgeFactory {
  public static DefaultLabel = 'child';
  static create(id: number, sourceId: number, targetId: number, data: IEntityData): IEdge {
    return {
      id: id ? id : -1,
      source: sourceId,
      target: targetId,
      type: 'edge',
      data: {
        ...data,
        label: data.label || EdgeFactory.DefaultLabel,
      },
    }
  }
}

export default EdgeFactory;
