import { EntryPropsBuilder } from "./EntryPropsBuilder";

export class EntryPropsFactory {
  static createDefaultValuePropsBuilder() {
    return new EntryPropsBuilder()
      .setEditable(true)
      .setType("text")
      .setValue("");
  }

  /**
   * create props for the entry value component based on the entry type
   * @param entryType
   */
  static createValueProps(entryType: string) {
    const builder = this.createDefaultValuePropsBuilder().setValueType(
      entryType
    );

    switch (entryType) {
      case "BOOLEAN":
        builder
          .setType("select")
          .setSelectOptions([
            { label: "true", value: "true" },
            { label: "false", value: "false" },
          ])
          .setValueIfNotExisting("true");
        break;
      case "NUMBER":
        builder.setType("number");
        break;
      default:
        break;
    }

    return builder.build().serialize();
  }
}
