import { useCallback } from "react";
import { GraphEntity } from "../../../../../../components/graph-builder/types/GraphTypes";

export const useGetEntityLabel = () =>
  useCallback((entities: GraphEntity[], id: number) => {
    const entity = entities.find((e) => e.id === id);
    if (entity) {
      return entity.data.label;
    }
    return null;
  }, []);
