import { useEffect } from "react";
import { GraphBuilderService } from "../../../../services/graph-builder";

export const useCleanGraph = () => {
  useEffect(() => {
    return () => {
      GraphBuilderService.dropGraph();
    };
  }, []);
};
