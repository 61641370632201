export interface IEntity {
  shapeId: string;
  shape: any;
}

export interface INodeType extends IEntity {
  typeText: string;
}

export interface INodeSubType extends IEntity {
}

export interface IEdgeType extends IEntity {
}

interface IType {
  [key: string]: IEdgeType | INodeType | INodeSubType;
}

export interface IGraphConfig {
  NodeTypes: IType;
  NodeSubtypes: IType;
  EdgeTypes: IType;
}

class GraphConfig {
  private readonly _NodeTypes: IType;
  private readonly _NodeSubtypes: IType;
  private readonly _EdgeTypes: IType;

  constructor(builder: GraphConfigBuilder) {
    this._NodeTypes = builder.config.NodeTypes;
    this._EdgeTypes = builder.config.EdgeTypes;
    this._NodeSubtypes = builder.config.NodeSubtypes;
  }

  public get NodeTypes() {
    return this._NodeTypes;
  }

  public get NodeSubtypes() {
    return this._NodeSubtypes;
  }

  public get EdgeTypes() {
    return this._EdgeTypes;
  }
}

class GraphConfigBuilder {
  private readonly _config: IGraphConfig;

  constructor() {
    this._config = {
      NodeTypes: {},
      NodeSubtypes: {},
      EdgeTypes: {},
    };
  }

  setNodeTypes(nodeTypes: IType) {
    this._config.NodeTypes = nodeTypes;
    return this;
  }

  setNodeSubtypes(nodeSubTypes: IType) {
    this._config.NodeSubtypes = nodeSubTypes;
    return this;
  }

  setEdgeTypes(edgeTypes: IType) {
    this._config.EdgeTypes = edgeTypes;
    return this;
  }

  build() {
    return new GraphConfig(this);
  }

  get config() {
    return this._config;
  }
}

export default GraphConfigBuilder;
