import React, { FC, useCallback, useMemo, useState } from "react";
import { IOrganization, IOrganizationUser } from "../types";
import { Table } from "../../../components/table";
import styles from "./OrganizationData.module.scss";
import { Icon } from "../../../components/icon";
import { Tooltip } from "../../../components/tooltip";
import { Button } from "../../../components/button";
import { Alert } from "../../../components/alert";
import { Avatar } from "../../../components/Avatar";

interface IOrganizationDataProps {
  data: IOrganization;
  onDeleteUserFromOrganization: (userId: number) => Promise<void>;
}

interface ICellParams {
  row: { original: IOrganizationUser };
}

export const OrganizationData: FC<IOrganizationDataProps> = ({
  data,
  onDeleteUserFromOrganization,
}) => {
  const columns = useMemo(
    () => [
      {
        id: "avatar",
        Header: "",
        Cell: (cellParams: ICellParams) => {
          const { name } = cellParams.row.original;
          return <Avatar name={name} size={20} />;
        },
      },
      {
        accessor: "name",
        Header: "Name",
        id: "name",
      },
      {
        accessor: "email",
        Header: "Email",
        id: "email",
      },
      {
        accessor: "status",
        Header: "Status",
        id: "status",
        Cell: (cellParams: ICellParams) => {
          const { original } = cellParams.row;
          const isPending = original.status === "Pending";
          return (
            <div className={styles.status}>
              <Tooltip content={isPending ? "Invitation Sent" : "Member"}>
                <Icon
                  intent={!isPending ? "success" : "none"}
                  icon={isPending ? "envelope" : "tick"}
                />
              </Tooltip>
            </div>
          );
        },
      },
      {
        id: "delete",
        Header: "Actions",
        Cell: (cellParams: ICellParams) => {
          const [userToDelete, setUserToDelete] = useState<null | number>(null);
          const { actions, id } = cellParams.row.original;
          const handleDelete = useCallback(async () => {
            setUserToDelete(null);
            await onDeleteUserFromOrganization(id);
          }, [id]);

          return (
            <div>
              <Alert
                onCancel={() => setUserToDelete(null)}
                onConfirm={handleDelete}
                isOpen={userToDelete !== null}
                confirmButtonText={"Yes"}
                cancelButtonText={"No"}
                intent={"danger"}
                icon={"trash"}
              >
                Are you sure you want to delete this user?
              </Alert>
              {actions.length === 0 && "-"}
              {actions.includes("delete") && (
                <Tooltip content={"Remove from organization"}>
                  <Button
                    onClick={() => setUserToDelete(id)}
                    className={styles.actionButton}
                    minimal
                    icon={"trash"}
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      },
    ],
    [onDeleteUserFromOrganization]
  );

  return (
    <div className={styles.organizationData}>
      <div className={styles.content}>
        <div className={styles.name}>
          <Icon iconSize={32} icon={"people"} />
          <h1 className={styles.nameText}>{data.name}</h1>
        </div>
        <Table className={styles.table} columns={columns} data={data.users} />
      </div>
    </div>
  );
};
