import React, { FC, useCallback, useMemo, useState } from "react";
import { Dialog } from "../../../components/dialog";
import styles from "./FormDialog.module.scss";
import { FormGroup, InputGroup } from "../../../components/form";
import { Button } from "../../../components/button";

interface ICreateOrganizationProps {
  isOpen: boolean;
  onClose: (result: boolean) => void;
  title: string;
  onSubmit: (value: string) => Promise<boolean>;
  buttonLabel: string;
  inputProps: {
    label: string;
    placeholder: string;
    validator?: (input: string) => string[];
  };
}

export const FormDialog: FC<ICreateOrganizationProps> = ({
  isOpen,
  title,
  onClose,
  onSubmit,
  buttonLabel,
  inputProps,
}) => {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleCreate = useCallback(async () => {
    setIsLoading(true);
    const result = await onSubmit(value);
    setIsLoading(false);
    if (result) {
      onClose(true);
    }
  }, [onClose, onSubmit, value]);

  const handleNameChange = useCallback((evt) => {
    setValue(evt.currentTarget.value);
  }, []);

  const isValid = useMemo(() => {
    const { validator } = inputProps;
    if (validator) {
      return validator(value).length === 0;
    }
    return true;
  }, [inputProps, value]);

  return (
    <Dialog
      className={styles.createOrganization}
      title={title}
      onClose={() => onClose(false)}
      isOpen={isOpen}
    >
      <div className={styles.content}>
        <FormGroup label={inputProps.label}>
          <InputGroup
            disabled={isLoading}
            value={value}
            onChange={handleNameChange}
            placeholder={inputProps.placeholder}
            autoFocus
          />
        </FormGroup>
        <div className={styles.footer}>
          <Button
            disabled={isLoading || !isValid}
            intent={"success"}
            minimal
            onClick={handleCreate}
            text={buttonLabel}
          />
        </div>
      </div>
    </Dialog>
  );
};
