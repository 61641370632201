import { FC } from "react";
import React from "react";
import styles from "./Sidebar.module.scss";
import Item, { IItemProps } from "./Item";
import Title, { ITitleProps } from "./Title";
import Content, {IContentProps} from "./Content";
import Section, {ISectionProps} from "./Section";
import classNames from "classnames";

type SideBarComponents = {
  Title: React.ComponentType<ITitleProps>;
  Item: React.ComponentType<IItemProps>;
  Content: FC<IContentProps>;
  Section: FC<ISectionProps>;
};

interface ISideBarProps {
  className?: string;
}

const Sidebar: FC<ISideBarProps> & SideBarComponents = ({ children, className }) => {
  return (
    <div className={classNames(styles.sideBar, className)}>
      {children}
    </div>
  );
};

Sidebar.Title = Title;
Sidebar.Content = Content;
Sidebar.Section = Section;
Sidebar.Item = Item;

export default Sidebar;
