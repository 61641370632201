import { AppRestService } from "../communication";
import { NotificationService } from "../notification";

class OrganizationService {
  /**
   *
   */
  public async getUserOrganization() {
    const { data } = await AppRestService.get("organization");
    return data;
  }

  /**
   *
   * @param name
   */
  public async createOrganization(name: string) {
    try {
      const { data } = await AppRestService.post("organization", { name });
      return data;
    } catch (err) {
      console.log(err);
      NotificationService.error(err.message);
    }
  }

  /**
   *
   * @param organizationId
   * @param userEmail
   */
  public async inviteUser(organizationId: number, userEmail: string) {
    try {
      const { data } = await AppRestService.post(
        `organization/${organizationId}/user/invite`,
        {
          userEmail,
        }
      );
      return data;
    } catch (err) {
      NotificationService.error(err.message);
    }
  }

  /**
   *
   * @param organizationId
   * @param userId
   */
  public async removeUserFromOrganization(
    organizationId: number,
    userId: number
  ) {
    try {
      const { data } = await AppRestService.delete(
        `organization/${organizationId}/user/${userId}`
      );
      return data;
    } catch (err) {
      console.log(err);
      NotificationService.error(err.message);
    }
  }

  public async acceptInvitation() {
    try {
      const { data } = await AppRestService.post(`organization/invitation`);
      return data;
    } catch (err) {
      NotificationService.error(err.message);
    }
  }

  /**
   *
   */
  public async getPendingInvitation() {
    try {
      const { data } = await AppRestService.get(`organization/invitation`);
      return data;
    } catch (err) {
      NotificationService.error(err.message);
    }
  }
}

export default new OrganizationService();
