import { IFavouriteQuery } from "../../services/favourite-queries/FavouriteQueriesService";
import { SandboxAction } from "../sandbox/reducers/SandboxActions";

export type FavouriteQueriesAction =
  | {
      type: "SET_FAVOURITE_QUERIES";
      payload: { items: IFavouriteQuery[] };
    }
  | { type: "ADD_QUERY_TO_FAVOURITES"; payload: { query: IFavouriteQuery } }
  | { type: "REMOVE_QUERY_FROM_FAVOURITES"; payload: { queryId: number } }
  | { type: "TOGGLE_FAVOURITE_QUERIES_LOADING"; payload: { loading: boolean } };

export interface IFavouriteQueriesState {
  items: IFavouriteQuery[];
  loading: boolean;
  fetched: boolean;
}

export const initialState = {
  items: [],
  loading: false,
  fetched: false,
};

export const reducer = (
  action: SandboxAction,
  state: IFavouriteQueriesState
) => {
  switch (action.type) {
    case "TOGGLE_FAVOURITE_QUERIES_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "SET_FAVOURITE_QUERIES":
      return {
        ...state,
        fetched: true,
        items: action.payload.items,
      };
    case "ADD_QUERY_TO_FAVOURITES":
      return {
        ...state,
        items: state.items
          ? state.items.concat(action.payload.query)
          : [action.payload.query],
      };
    case "REMOVE_QUERY_FROM_FAVOURITES":
      return {
        ...state,
        items: state.items
          ? state.items.filter((it) => it.id !== action.payload.queryId)
          : state.items,
      };
  }

  return state;
};
