import { IAnalyticsService } from "../interface";
import ReactGA from "react-ga4";

export interface IGoogleAnalyticsEvent {
  type?: string;
  category: string;
  action: string;
  label?: string;
  value?: number;
}

class GoogleAnalyticsService
  implements IAnalyticsService<IGoogleAnalyticsEvent> {
  private key = "";

  public init(key: string) {
    this.key = key;
    ReactGA.initialize(this.key, {
      gaOptions: {
        cookieDomain: "auto",
      },
    });
  }

  public sendEvent(event: IGoogleAnalyticsEvent) {
    if (process.env.NODE_ENV !== "development") {
      ReactGA.event(event);
    } else if (process.env.VERBOSE) {
      console.log("[GoogleAnalytics]", event);
    }
  }
}

export default new GoogleAnalyticsService();
