import * as React from "react";
import styles from "./Mimic.module.scss";
import Header from "../../components/header/Header";
import { Spinner } from "./../../components/spinner";
import Button from "../../components/button/Button";
import { SpinnerType } from "../../components/spinner/Spinner";

const Mimic = ({ spinner = true }) => (
  <div className={styles.mimic}>
    <Header isMimic={true}>
      <Button className={styles.ghostButton} large={true} />
    </Header>
    <div className={styles.content}>
      {spinner && <Spinner type={SpinnerType.FLOATING_GREMLIN} />}
    </div>
  </div>
);

export default Mimic;
