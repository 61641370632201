import { useCallback, useContext, useEffect } from "react";
import { AuthStatus } from "../authentication/AuthTypes";
import { FavouriteQueriesService } from "../../services/favourite-queries";
import AuthContext from "../authentication/AuthContext";
import SandBoxContext from "../sandbox/SandBoxContext";
import useAnalytics from "../../shared/hooks/useAnalytics";

const useFavouriteQueries = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const { authStatus, user } = useContext(AuthContext);
  const [sendEvent] = useAnalytics();

  useEffect(() => {
    const fetch = async () => {
      if (user.id) {
        dispatch({
          type: "TOGGLE_FAVOURITE_QUERIES_LOADING",
          payload: { loading: true },
        });
        const queries = await FavouriteQueriesService.getUserFavouriteQueriesByUserId(
          user.id
        );

        if (queries) {
          dispatch({
            type: "SET_FAVOURITE_QUERIES",
            payload: { items: queries },
          });
        }
        dispatch({
          type: "TOGGLE_FAVOURITE_QUERIES_LOADING",
          payload: { loading: false },
        });
      }
    };

    if (authStatus === AuthStatus.CONNECT && !state.favouriteQueries.fetched) {
      fetch();
    }
  }, [
    authStatus,
    dispatch,
    state.favouriteQueries.fetched,
    state.favouriteQueries.items,
    user.id,
  ]);

  const applyQuery = useCallback(
    (item) => {
      sendEvent({
        category: "Favourite Queries",
        action: "Apply",
      });
      dispatch({
        type: "UPDATE_QUERY",
        payload: { query: item },
      });
    },
    [dispatch, sendEvent]
  );

  const removeQueryFromFavourites = useCallback(
    async (queryId: number) => {
      if (user.id) {
        const result = await FavouriteQueriesService.removeFromFavourites(
          user.id,
          queryId
        );
        if (result) {
          dispatch({
            type: "REMOVE_QUERY_FROM_FAVOURITES",
            payload: { queryId },
          });
        }
      }
    },
    [dispatch, user.id]
  );

  return {
    favouriteQueries: state.favouriteQueries.items,
    applyQuery,
    removeQueryFromFavourites,
    isLoading: state.favouriteQueries.loading,
  };
};

export default useFavouriteQueries;
