import { useEffect, useState } from "react";
import {
  GraphEntity,
  IGraph,
} from "../../../../../../components/graph-builder/types/GraphTypes";

export const useSelectedEntity = (scheme: IGraph) => {
  const [selected, setSelected] = useState<GraphEntity | null>(null);
  useEffect(() => {
    const entities = [...scheme.edges, ...scheme.nodes];
    if (selected) {
      const previousSchemeSelectedEntity = entities.find(
        (e) => e.id === selected.id
      );
      const selectedEntity = previousSchemeSelectedEntity
        ? previousSchemeSelectedEntity
        : null;
      setSelected(selectedEntity);
    }
  }, [selected, scheme.edges, scheme.nodes]);

  return {
    selected,
    setSelected,
  };
};
