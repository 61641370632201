import { useCallback } from "react";
import useSaveWorkspace from "./useSaveWorkspace";
import { useParams } from "react-router";
import { useAnalytics } from "../../../shared/hooks";

export const useFork = () => {
  const { save, isLoading } = useSaveWorkspace();
  const [sendEvent] = useAnalytics();

  const { id } = useParams();
  const fork = useCallback(async () => {
    sendEvent({ category: "Workspace", action: "fork" });
    await save(id);
  }, [id, save, sendEvent]);

  return {
    isLoading,
    fork,
  };
};
