import {
  GraphEntity,
  IEdge,
  IEntityData,
  IGraph,
  INode,
  IPoint,
} from "../../components/graph-builder/types/GraphTypes";
import NodeFactory from "../../components/graph-builder/factory/NodeFactory";
import EdgeFactory from "../../components/graph-builder/factory/EdgeFactory";
import { CommonUtils } from "../../utils/index";
import { EntityResponse, IGraphResponse } from "./GraphBuilderTypes";
import LayoutFactory, {
  DEFAULT_LAYOUT,
  LayoutType,
} from "./Layout/LayoutFactory";
import get from "lodash/get";
import { TypeUtils } from "../../utils/Type";

class GraphUtils {
  /**
   * check if two entities are data-equal
   * @param entity1
   * @param entity2
   */
  public static isEqual = (entity1: GraphEntity, entity2: GraphEntity) => {
    return CommonUtils.isEqual(entity1.data, entity2.data);
  };

  /**
   * copy a node
   * @param node
   */
  public static copyNode = (node: INode) => NodeFactory.copy(node);

  /**
   * create a graph node
   * @param x
   * @param y
   * @param data
   */
  public static createNode = (x: number, y: number, data: IEntityData): INode =>
    NodeFactory.create(-1, x, y, data);

  /**
   * create a new graph edge
   * @param source
   * @param target
   * @param data
   */
  public static createEdge = (
    source: INode,
    target: INode,
    data: IEntityData
  ): IEdge => EdgeFactory.create(-1, source.id, target.id, data);

  /**
   * generate a graph with a given layout engine
   * @param layoutType
   * @param graph
   */
  public static generateGraphFromLayout(
    layoutType: LayoutType | null,
    graph: IGraph
  ) {
    const layout = LayoutFactory.create(
      layoutType ? layoutType : DEFAULT_LAYOUT
    );
    return layout.buildLayout(graph);
  }

  /**
   * aligns a layout around a center point
   * @param graph
   * @param m
   * @param scaleFactor
   */
  public static alignLayout(graph: IGraph, m: IPoint, scaleFactor = 1): IGraph {
    const xPositions = graph.nodes.map((node) => node.x * scaleFactor);
    const yPositions = graph.nodes.map((node) => node.y * scaleFactor);
    const x1 = Math.min.apply(null, xPositions);
    const x2 = Math.max.apply(null, xPositions);
    const y1 = Math.min.apply(null, yPositions);
    const y2 = Math.max.apply(null, yPositions);

    const nodes = graph.nodes.map((node) => ({
      ...node,
      x: node.x * scaleFactor + (m.x - (x1 + x2) / 2),
      y: node.y * scaleFactor + (m.y - (y1 + y2) / 2),
    }));

    return {
      edges: graph.edges,
      nodes,
    };
  }

  /**
   * get a clean copy of the graph
   * @param scheme
   */
  public static cleanGraphFromDummyEntities(scheme: IGraph) {
    return {
      nodes: scheme.nodes.filter((n) => n.id !== -1),
      edges: scheme.edges.filter((e) => e.id !== -1),
    };
  }

  /**
   * normalize graph response
   * @param graphResponse
   *
   * TODO:// make private!!!!
   */
  public static buildGraphFromResponse(graphResponse: IGraphResponse): IGraph {
    const nodes = graphResponse.nodes.map((node) => {
      const data = GraphUtils.flattenEntityData(node);

      // give nodes initial data
      return NodeFactory.create(node.id, 0, 0, data);
    });
    const edges = graphResponse.edges.map((edge) => {
      const data = GraphUtils.flattenEntityData(edge);
      return EdgeFactory.create(edge.id, edge.source, edge.target, data);
    });

    return {
      nodes,
      edges,
    };
  }

  /**
   *
   * @param graph
   * @param layout
   */
  public static buildGraphWithLayoutFromResponse(
    graph: IGraph,
    layout: LayoutType | null
  ) {
    const raw = GraphUtils.buildGraphFromResponse(graph);
    return GraphUtils.generateGraphFromLayout(layout, raw);
  }

  /**
   *
   * @param label
   * @param entities
   */
  public static filterEntitiesWithLabel(
    entities: GraphEntity[],
    label: string
  ) {
    return entities.filter((entity) => entity.data.label === label);
  }

  /**
   *
   * @param entity
   */
  public static getEntityProperties(entity: GraphEntity) {
    return Object.keys(entity.data);
  }

  /**
   *
   * @param entities
   */
  public static getEntitiesLabels(entities: GraphEntity[]) {
    return entities.reduce((acc, cur) => {
      if (!acc.includes(cur.data.label)) {
        return acc.concat(cur.data.label);
      }
      return acc;
    }, [] as string[]);
  }

  /**
   * extract properties into a map
   * @param entities
   * @param props
   */
  public static generatePropertyMap<T>(
    entities: GraphEntity[],
    props: string[]
  ): { [key: number]: T } {
    return entities.reduce((acc, entity) => {
      acc[entity.id as number] = props.reduce((acc, p) => {
        acc[p] = get(entity, p);
        return acc;
      }, {} as { [key: string]: any }) as T;
      return acc;
    }, {} as { [key: number]: T });
  }

  /**
   *
   * @param entity
   */
  private static flattenEntityData(entity: EntityResponse) {
    return Object.entries(entity.data).reduce((acc, entry) => {
      const [key, value] = entry;
      acc[key] = value;
      return acc;
    }, {} as IEntityData);
  }

  /**
   * generates a type map from entity data
   * @param data
   */
  public static getDataTypeMap(data: IEntityData) {
    const { label, ...rest } = data;
    return Object.fromEntries(
      Object.entries(rest).map(([key, value]) => [
        key,
        TypeUtils.inferType(value),
      ])
    );
  }
}

export default GraphUtils;
