import { useCallback, useContext, useEffect, useState } from "react";
import { EntityType } from "../../../../../../shared/types/common/enums";
import {
  GraphEntity,
  IEntityData,
} from "../../../../../../components/graph-builder/types/GraphTypes";
import { usePropertyEntries } from "../../common/hooks/usePropertyEntries";
import { IKeyValueEntry } from "../../../../../../components/key-value-editor/KeyValueEditor";
import SandBoxContext from "../../../../SandBoxContext";
import { useLabels } from "../../common/hooks/useLabels";
import { useValidateEntityTypes } from "../../common/hooks/useValidateEntityTypes";
import { IEntry } from "../../../../../../components/key-value-editor/useKeyValueEntries";

const useCreation = (
  entityType: EntityType | null,
  onCreate: (data: IEntityData) => void
) => {
  const [state] = useContext(SandBoxContext);
  const {
    schemaBuilder: { scheme },
  } = state;
  const [loading, setLoading] = useState(false);
  const { currentLabel, setCurrentLabel } = useLabels(entityType);
  const validateEntityTypes = useValidateEntityTypes();
  const entries = usePropertyEntries(entityType, currentLabel);

  useEffect(() => {
    if (entityType === null) {
      setLoading(false);
    }
  }, [entityType]);

  const handleSubmit = useCallback(
    (data) => {
      setLoading(true);
      onCreate(data);
    },
    [onCreate]
  );

  const validateEntries = useCallback(
    (entries: IKeyValueEntry[]) => {
      const label = currentLabel;
      const entities: GraphEntity[] =
        entityType === EntityType.edge ? scheme.edges : scheme.nodes;
      const entity = entities.find((e) => e.data.label === label);
      return entity ? validateEntityTypes(entity, entries) : null;
    },
    [currentLabel, entityType, scheme.edges, scheme.nodes, validateEntityTypes]
  );

  const handleChangeEntries = useCallback(
    (entries: IEntry[]) => {
      const labelEntry = entries.find((e) => e.keyProps.value === "label");

      if (labelEntry) {
        const {
          valueProps: { value },
        } = labelEntry;
        setCurrentLabel(value);
      }
    },
    [setCurrentLabel]
  );

  return {
    entries,
    loading,
    handleSubmit,
    validateEntries,
    handleChangeEntries,
  };
};

export default useCreation;
