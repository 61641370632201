import { useCallback } from "react";
import GoogleAnalyticsService, {
  IGoogleAnalyticsEvent
} from "../../services/analytics/google/GoogleAnalyticsService";

type Event = IGoogleAnalyticsEvent;

const useAnalytics = () => {
  const google = (event: IGoogleAnalyticsEvent) => GoogleAnalyticsService.sendEvent(event);
  const event = useCallback((event: Event) => google(event), []);

  return [event];
};

export default useAnalytics;
