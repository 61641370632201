import React, { FC } from "react";
import classNames from "classnames";
import styles from "./GeneralToolbar.module.scss";
interface IGeneralToolbarProps {
  customToolbar?: JSX.Element;
  className?: string;
}

export const GeneralToolbar: FC<IGeneralToolbarProps> = ({
  customToolbar,
  className,
}) => {
  return (
    <div className={classNames(className, styles.generalToolbar)}>
      <div className={classNames(styles.content)}>{customToolbar}</div>
    </div>
  );
};
