import {Language} from "../../shared/types/common/language";
import RunGremlinService from "./gremlin/RunGremlinService";
import {IRunService} from "./RunServiceTypes";
import {DEFAULT_LANGUAGE} from "../../shared/constants/common/common";

class RunnerServiceProvider {
  private _instance: IRunService;

  constructor() {
    this._instance = this.getServiceByLanguage(DEFAULT_LANGUAGE);
  }

  public getInstance(language: Language) {
    if (!this._instance || this._instance.getLanguage() !== language) {
      this._instance = this.getServiceByLanguage(language);
    }

    return this._instance;
  }

  /**
   * get a new instance of a runner by language
   * @param language
   */
  private getServiceByLanguage(language: Language): IRunService {
    switch (language) {
      default:
        return new RunGremlinService();
    }
  }
}

export default new RunnerServiceProvider();