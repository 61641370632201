import React, { FC, memo } from "react";
import { useFlexLayout, useSortBy, useTable } from "react-table";
import styles from "./Table.module.scss";
import classNames from "classnames";
import { Icon } from "../icon";

// TODO: fix typing
export interface IColumn {
  id: string;
  Header: string;
  accessor?: string;
  minWidth?: number;
  // TODO: change
  Cell?: ({ row }: { row: { original: any } }) => JSX.Element;
}

// TODO: change
interface ITableProps {
  data: any;
  columns: IColumn[];
  className?: string;
  thClassName?: string;
  tdClassName?: string;
  trClassName?: string;
  defaultColumn?: {
    width?: number;
    height?: number;
  };
}

const Table: FC<ITableProps> = ({
  columns,
  data,
  className,
  thClassName,
  defaultColumn,
  tdClassName,
}) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data, defaultColumn },
    useFlexLayout,
    useSortBy
  );

  return (
    <div {...getTableProps()} className={classNames(className, styles.table, 'table')}>
      <div className={classNames(styles.thead, 'thead')}>
        {headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps()} className={classNames(styles.tr, 'tr')}>
            {headerGroup.headers.map((column: any) => (
              <div
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={classNames(styles.th, thClassName)}
                data-cy={"Th"}
              >
                {column.render("Header")}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <Icon iconSize={10} icon={"arrow-down"} />
                  ) : (
                    <Icon iconSize={10} icon={"arrow-up"} />
                  )
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className={classNames('tbody')}>
        {rows.map((row: any) => {
          prepareRow(row);
          return (
            <div {...row.getRowProps()} className={classNames('tr')}>
              {row.cells.map((cell: any) => {
                return (
                  <div
                    {...cell.getCellProps()}
                    className={classNames(styles.td, tdClassName, 'td')}
                  >
                    {cell.render("Cell")}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(Table);
