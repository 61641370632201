import { useCallback, useContext } from "react";
import GoogleAnalyticsService from "../../../services/analytics/google/GoogleAnalyticsService";
import SandBoxContext from "../SandBoxContext";

export const useHelpTour = () => {
  const dispatch = useContext(SandBoxContext)[1];
  const toggleTour = useCallback(
    (run) => {
      dispatch({ type: "TOGGLE_HELP_TOUR", payload: { run } });
    },
    [dispatch]
  );
  const stop = useCallback(() => {
    toggleTour(false);
  }, [toggleTour]);
  const start = useCallback(() => {
    GoogleAnalyticsService.sendEvent({
      category: "SandBox",
      action: "Start Help Tour",
    });
    toggleTour(true);
  }, [toggleTour]);

  return { start, stop };
};
