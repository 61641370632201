import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Suspense,
  FC,
} from "react";
import { Sidebar } from "../../components/sidebar";
import styles from "./SideMenu.module.scss";
import items from "./items";
import classNames from "classnames";
import { CustomScrollbar } from "../../components/scrollbar";

interface ISideMenuProps {
  disabled?: boolean;
}

const SideMenu: FC<ISideMenuProps> = ({ disabled = false }) => {
  const [activeItem, setActiveItem] = useState<null | string>(null);
  const [maxHeight, setMaxHeight] = useState(500);
  const sideMenu = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sideMenu.current) {
      setMaxHeight(sideMenu.current.clientHeight);
    }
  }, [sideMenu, activeItem]);

  const handleItemClick = useCallback(
    (name: string) => {
      if (activeItem === name) {
        setActiveItem(null);
      } else {
        setActiveItem(name);
      }
    },
    [activeItem]
  );

  return (
    <div
      className={classNames(styles.siteSideMenu, {
        [styles.open]: activeItem !== null,
      })}
      ref={sideMenu}
    >
      <Sidebar className={styles.iconsBar}>
        {Object.entries(items).map(([name, meta]) => (
          <Sidebar.Item
            key={meta.name}
            className={classNames(styles.iconItem, {
              [styles.active]: activeItem === meta.name,
            })}
            id={disabled ? undefined : meta.markupId}
            title={meta.title}
            name={meta.name}
            onClick={handleItemClick}
            disabled={disabled}
          >
            {React.createElement(items[meta.name].toggle)}
          </Sidebar.Item>
        ))}
      </Sidebar>
      {activeItem && (
        <div className={styles.content}>
          <Sidebar.Title
            primary={true}
            className={styles.mainTitle}
            title={items[activeItem].title}
          />
          <CustomScrollbar style={{ maxHeight }}>
            <Suspense fallback={<div />}>
              {React.createElement(items[activeItem].component)}
            </Suspense>
          </CustomScrollbar>
        </div>
      )}
    </div>
  );
};

export default SideMenu;
