import { Menu, MenuItem } from "../../../../../../components/menu";
import { LAYOUTS } from "../../../../../../shared/constants/common/common";
import * as React from "react";
import { LayoutType } from "../../../../../../services/graph-builder/Layout/LayoutFactory";
import { FC, useCallback, useMemo } from "react";
import styles from "./GraphBuilderContextMenu.module.scss";
import { GraphEntity } from "../../../../../../components/graph-builder/types/GraphTypes";
import { useCopyToClipboard } from "../../../../../../shared/hooks";
import { MenuDivider } from "@blueprintjs/core";

interface IGraphBuilderContextMenu {
  schemeLayout: LayoutType | null;
  onSelectLayout: (layout: LayoutType) => void;
  selectedEntity: GraphEntity | null;
}

const translateType = (type: string) => (type === "node" ? "Vertex" : "Edge");

const GraphBuilderContextMenu: FC<IGraphBuilderContextMenu> = React.memo(
  ({ schemeLayout, onSelectLayout, selectedEntity }) => {
    const copyToClipboard = useCopyToClipboard();
    const handleCopy = useCallback((value) => copyToClipboard(value), [
      copyToClipboard,
    ]);

    const attributeArray = useMemo(() => {
      if (!selectedEntity) return null;

      return Object.entries(selectedEntity.data).reduce(
        (acc, cur) => {
          return [...acc, cur];
        },
        [["id", selectedEntity.id]]
      );
    }, [selectedEntity]);

    return (
      <Menu type={"secondary"} className={styles.graphBuilderContextMenu}>
        <MenuItem icon={"layout"} text={"Change Layout"}>
          {Object.entries(LAYOUTS).map(([key, item]) => (
            <MenuItem
              key={key}
              text={item.label}
              onClick={() => onSelectLayout(item.value)}
              icon={schemeLayout === item.value ? "small-tick" : "blank"}
            />
          ))}
        </MenuItem>
        <MenuItem
          icon={"duplicate"}
          text={"Copy"}
          disabled={selectedEntity === null}
        >
          {selectedEntity && (
            <div className={styles.menuHeading}>
              {translateType(selectedEntity.type)} attributes
            </div>
          )}
          <MenuDivider />
          {attributeArray &&
            attributeArray.map(([key, value]) => (
              <MenuItem
                text={key}
                key={key}
                onClick={() => handleCopy(value)}
              />
            ))}
        </MenuItem>
      </Menu>
    );
  }
);

export default GraphBuilderContextMenu;
