import { Dialog as BPDialog, IDialogProps } from "@blueprintjs/core";
import * as React from "react";
import { FC } from "react";
import classNames from 'classnames';
import styles from './Dialog.module.scss';

// TODO: use theme
const Dialog: FC<IDialogProps> = props => (
  <BPDialog {...props} className={classNames(styles.dialog, props.className)} />
);

export default Dialog;
