import React, { FC, useCallback, useEffect, useState } from "react";
import ChromePicker from "react-color/lib/components/chrome/Chrome";
import styles from "./ColorPicker.module.scss";
import { Button } from "../button";
import { ButtonGroup } from "../button-group";

interface IColorPickerProps {
  initialColor: string;
  onChange: (color: string) => void;
}

interface IColor {
  hex: string;
}

const ColorPicker: FC<IColorPickerProps> = ({
  initialColor,
  onChange,
  children,
}) => {
  const [show, setShow] = useState(false);
  const [color, setColor] = useState(initialColor);
  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  const handleChange = useCallback((color: IColor) => {
    setColor(color.hex);
  }, []);

  const handleConfirm = useCallback(() => {
    onChange(color);
    setShow(false);
  }, [color, onChange]);

  const handleCancel = useCallback(() => {
    setShow(false);
    setColor(initialColor);
  }, [initialColor]);

  const handleClick = useCallback(() => setShow(true), []);

  return (
    <div className={styles.colorPicker} data-cy={"ColorPicker"}>
      <div onClick={handleClick}>{children}</div>
      {show && (
        <div className={styles.picker}>
          <ChromePicker color={color} onChange={handleChange} />
          <ButtonGroup fill={true} className={styles.buttonGroup}>
            <Button
              data-cy={"CancelColorPicker"}
              minimal={true}
              onClick={handleCancel}
              className={styles.btn}
              text={"Cancel"}
            />
            <Button
              data-cy={"ConfirmColorPicker"}
              minimal={true}
              onClick={handleConfirm}
              className={styles.btn}
              text={"Ok"}
            />
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
