import { ReactElement } from "react";

interface IStepOptions {
  placement: "bottom" | "top" | "top-start" | "top-end" | "center" | "left"; // add more if needed
  disableBeacon?: boolean;
}

export interface IStep {
  target: string;
  content: string | ReactElement;
  options?: IStepOptions;
}

class Tour {
  private readonly _steps: IStep[] = [];

  public constructor(builder: TourBuilder) {
    this._steps = builder.steps;
  }

  get steps(): IStep[] {
    return this._steps;
  }
}

class TourBuilder {
  private _steps: IStep[] = [];

  public addStep(
    target: string,
    content: string | ReactElement,
    options?: IStepOptions
  ) {
    this._steps.push({
      target,
      content,
      ...(options || {}),
    });
    return this;
  }

  get steps(): IStep[] {
    return this._steps;
  }

  public build() {
    return new Tour(this);
  }
}

export default TourBuilder;
