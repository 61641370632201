import React, { FC } from "react";
import styles from "./MetadataDisplay.module.scss";
import { Editable } from "../../../../components/editable";

interface IMetadataDisplayProps {
  title?: string;
  user: string | null;
  onChangeTitle: (title: string) => void;
}
const MetadataDisplay: FC<IMetadataDisplayProps> = ({
  title,
  onChangeTitle,
  user,
}) => (
  <div className={styles.metadataDisplay} data-cy={"MetadataDisplay"}>
    {user && (
      <>
        <span className={styles.username}>{user}</span>
        <span className={styles.sep}>/</span>
      </>
    )}
    <span>
      <Editable
        inputClassName={styles.input}
        value={title ? title : ""}
        onChange={onChangeTitle}
        defaultValue={title ? "" : "Untitled Workspace"}
      />
    </span>
  </div>
);

export default MetadataDisplay;
