import { Sidebar } from "../../components/sidebar";
import * as React from "react";
import styles from "./Settings.module.scss";
import Themes from "./Themes";
import { useContext } from "react";
import ThemeContext from "../theme/ThemeContext";

const Settings = () => {
  const [theme, setTheme] = useContext(ThemeContext);

  return (
    <div className={styles.settings}>
      <Sidebar.Section title={"Theme"}>
        <Themes onSelect={setTheme} selected={theme} />
      </Sidebar.Section>
    </div>
  );
};

export default Settings;
