import { useMemo } from "react";
import { ISandBoxReducerState } from "../../../../reducers/SandBoxReducer";

/**
 *
 * @param state
 */
export const useSelectNodes = (state: ISandBoxReducerState) =>
  useMemo(() => state.graphBuilder.scheme.nodes, [
    state.graphBuilder.scheme.nodes,
  ]);

/**
 *
 * @param state
 */
export const useSelectEdges = (state: ISandBoxReducerState) =>
  useMemo(() => state.graphBuilder.scheme.edges, [
    state.graphBuilder.scheme.edges,
  ]);

/**
 *
 * @param state
 */
export const useSelectGraphScheme = (state: ISandBoxReducerState) =>
  useMemo(() => state.graphBuilder.scheme, [state.graphBuilder.scheme]);

/**
 *
 * @param state
 */
export const useSelectEdgesPropertyDisplaySettings = (
  state: ISandBoxReducerState
) =>
  useMemo(() => state.graphBuilder.displayByProperty.edges, [
    state.graphBuilder.displayByProperty.edges,
  ]);

/**
 *
 * @param state
 */
export const useSelectNodesPropertyDisplaySettings = (
  state: ISandBoxReducerState
) =>
  useMemo(() => state.graphBuilder.displayByProperty.nodes, [
    state.graphBuilder.displayByProperty.nodes,
  ]);
