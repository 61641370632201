import React, { useCallback, useContext } from "react";
import styles from "./Help.module.scss";
import { Sidebar } from "../../components/sidebar";
import SandBoxContext from "../sandbox/SandBoxContext";
import { TOGGLE_HELP_TOUR } from "../sandbox/reducers/SandboxActions";
import { Button } from "../../components/button";

const Help = () => {
  const dispatch = useContext(SandBoxContext)[1];
  const handleStartTourClick = useCallback(() => {
    dispatch({ type: TOGGLE_HELP_TOUR, payload: { run: true } });
  }, [dispatch]);
  return (
    <div className={styles.help}>
      <Sidebar.Section className={styles.section}>
        <p>New to Gremlify?</p>
        <Button
          fill={true}
          intent={"primary"}
          text={"Take a Tour!"}
          onClick={handleStartTourClick}
        />
      </Sidebar.Section>
      <Sidebar.Section title={"Documentation"} defaultOpen={false}>
        <p>
          To learn more about Gremlify, please visit our{" "}
          <a
            href={"https://gremlify.gitbook.io/gremlify/"}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Official Documentation
          </a>
        </p>
      </Sidebar.Section>
      <Sidebar.Section title={"Terms of Service"} defaultOpen={false}>
        <p>
          Before using Gremlify, please make sure you read our{" "}
          <a
            href={"https://gremlify.gitbook.io/gremlify/terms-of-service"}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Terms Of Service
          </a>{" "}
          carefully.
        </p>
      </Sidebar.Section>
      <Sidebar.Section title={"Privacy Policy"} defaultOpen={false}>
        <p>
          Before using Gremlify, please make sure your read our{" "}
          <a
            href={"https://gremlify.gitbook.io/gremlify/privacy-policy"}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Privacy Policy
          </a>{" "}
          carefully.
        </p>
      </Sidebar.Section>
    </div>
  );
};

export default Help;
