import { useCallback, useContext } from "react";
import { ColorGenerator } from "../utils/ColorGenerator";
import SandBoxContext from "../../../../SandBoxContext";
import { IGraph } from "../../../../../../components/graph-builder/types/GraphTypes";

const generator = ColorGenerator.genNodeColor();
interface IMap {
  [key: string]: string;
}

const defaultMap: IMap = { "?": "transparent" };

export const useColorMap = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const { map: colorMap } = state.colorMap;

  const getNextColor = useCallback(() => {
    let color = generator.next().value;
    const currentColors = Object.values(colorMap || defaultMap);
    for (let i = 0; i < currentColors.length; i++) {
      if (currentColors.includes(color)) {
        color = generator.next().value;
      } else {
        return color;
      }
    }
    return color;
  }, [colorMap]);

  const updateColor = useCallback(
    (label: string, color: string) => {
      dispatch({ type: "SET_LABEL_COLOR", payload: { label, color } });
    },
    [dispatch]
  );

  const assignColorToLabel = useCallback(
    (label: string) => {
      if (!colorMap[label]) {
        const color = getNextColor();
        updateColor(label, color);
      }
    },
    [colorMap, getNextColor, updateColor]
  );

  const assignColorsToGraph = useCallback(
    (scheme: IGraph) => {
      const map = scheme.nodes.reduce(
        (acc, cur) => {
          if (!acc[cur.data.label]) {
            acc[cur.data.label] = getNextColor();
          }
          return acc;
        },
        { ...state.colorMap.map }
      );
      dispatch({ type: "SET_COLOR_MAP", payload: { map } });
    },
    [dispatch, getNextColor, state.colorMap.map]
  );

  return {
    colorMap: state.colorMap.map,
    updateColor,
    assignColorToLabel,
    assignColorsToGraph,
  };
};
