import { FC } from "react";
import * as React from "react";
import ThemeContext from "./ThemeContext";

interface IThemeProviderProps {
  theme: string;
  setTheme: (theme: string) => void;
}

const ThemeProvider: FC<IThemeProviderProps> = ({ theme, setTheme, ...props }) => {
  return <ThemeContext.Provider value={[theme, setTheme]} {...props} />;
};

export default ThemeProvider;
