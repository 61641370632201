import React, { FC } from "react";
import styles from "./Updates.module.scss";
import newUpdatesContent from "./../../data/updates/updates.json";
import { Sidebar } from "../../components/sidebar";
import { CallOut } from "../../components/callout";

const Updates: FC = ({ children }) => {
  const body = newUpdatesContent.body.map((content, i) => (
    <CallOut data-cy={"Update"} key={i} className={styles.update}>
      {content}
    </CallOut>
  ));
  const content = (
    <div className={styles.popOverContentContainer}>
      <div className={styles.body}>{body}</div>
    </div>
  );
  return (
    <div>
      <Sidebar.Content>
        <Sidebar.Section>{content}</Sidebar.Section>
      </Sidebar.Content>
    </div>
  );
};

export default Updates;
