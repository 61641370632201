import * as React from "react";
import { FC, useCallback, useContext } from "react";
import Button from "../../../../components/button/Button";
import styles from "./HeaderActions.module.scss";
import SandBoxContext from "../../SandBoxContext";
import MetadataDisplay from "./MetadataDisplay";
import { UPDATE_WORKSPACE_META } from "../../reducers/SandboxActions";
import AuthContext from "../../../authentication/AuthContext";
import { AuthStatus } from "../../../authentication/AuthTypes";
import { Menu, MenuItem } from "../../../../components/menu";
import { MenuDivider } from "@blueprintjs/core";
import { PopOver } from "../../../../components/popOver";
import { browserHistory } from "../../../router/history";
import { useParams } from "react-router";
import { useSaveRevision } from "../../hooks/useSaveRevision";
import { useFork } from "../../hooks/useFork";
import { useRun } from "../../hooks/useRun";
import { ConfirmDialog } from "../confirm";
import { useState } from "react";
import { useClearWorkspace } from "../../hooks/useClearWorkspace";
import { useUndo } from "../../hooks/useUndo";
import { Avatar } from "../../../../components/Avatar";

const HeaderContents: FC = () => {
  const { id: workspaceId } = useParams();
  const [state, dispatch] = useContext(SandBoxContext);
  const { user, authStatus, logOut } = useContext(AuthContext);
  const { run, isLoading: runLoading } = useRun();
  const { save, isLoading: saveLoading } = useSaveRevision();
  const { fork, isLoading: forkLoading } = useFork();
  const { clear, isLoading: clearLoading } = useClearWorkspace();
  const { undo: revert, isLoading: undoLoading } = useUndo();

  const [confirmDialog, setConfirmDialog] = useState<string | null>(null);
  const closeConfirmDialog = useCallback(() => setConfirmDialog(null), []);

  const onClearWorkspaceClick = useCallback(() => {
    setConfirmDialog("clear");
  }, []);

  const logOutOnClick = useCallback(async () => {
    logOut();
    browserHistory.push("/user/login");
  }, [logOut]);

  const handleUpdateTitle = useCallback(
    (title: string) => {
      dispatch({ type: UPDATE_WORKSPACE_META, payload: { title } });
    },
    [dispatch]
  );

  const handleUndoClick = useCallback(() => {
    setConfirmDialog("revert");
  }, []);

  const exampleMenu = (
    <Menu type={"secondary"}>
      <MenuItem
        text="Workspaces"
        onClick={() => browserHistory.push("/workspaces/my")}
      />
      <MenuItem
        text="Organization"
        onClick={() => browserHistory.push("/organization")}
      />
      <MenuDivider />
      <MenuItem text="Sign out" onClick={logOutOnClick} />
    </Menu>
  );

  const getUsername = () => {
    if (state.metadata.isPrivate) {
      return user.name;
    }

    return null;
  };

  return (
    <div className={styles.headerActions}>
      <ConfirmDialog
        close={closeConfirmDialog}
        isOpen={confirmDialog === "clear"}
        onClear={clear}
        confirmButtonText={"Clear"}
        text={"Are you sure you want to clear your current workspace?"}
      />
      <ConfirmDialog
        close={closeConfirmDialog}
        confirmButtonText={"Revert"}
        isOpen={confirmDialog === "revert"}
        onClear={revert}
        text={
          "Are you sure you want to revert your workspace to the last revision?"
        }
      />
      <div className={styles.menu}>
        <Button
          className={"gy-run"}
          data-cy={"Run"}
          onClick={run}
          large={true}
          icon={"play"}
          text={"Run"}
          loading={runLoading}
          intent={"success"}
        />
        <Button
          minimal={true}
          onClick={save}
          data-cy={"SaveWorkspace"}
          className={"gy-save-workspace"}
          loading={saveLoading}
          large={true}
          text={"Save"}
        />
        <Button
          minimal={true}
          onClick={fork}
          data-cy={"ForkWorkspace"}
          className={"gy-fork-workspace"}
          loading={forkLoading}
          large={true}
          disabled={workspaceId === undefined}
          text={"Fork"}
        />
        <Button
          minimal={true}
          data-cy={"ClearWorkspace"}
          onClick={onClearWorkspaceClick}
          className={"gy-clear-workspace"}
          loading={clearLoading}
          large={true}
          text={"Clear"}
        />
        <Button
          minimal={true}
          data-cy={"UndoGraph"}
          onClick={handleUndoClick}
          className={"gy-undo-graph"}
          disabled={!state.graphBuilder.isDirty}
          loading={undoLoading}
          large={true}
          text={"Revert"}
        />
        <Button
          minimal={true}
          onClick={() =>
            window.open(
              "https://www.buymeacoffee.com/gremlifyF",
              "_blank",
              "noopener,noreferrer,resizable"
            )
          }
          large={true}
          intent={"success"}
          text={"Donate"}
        />
      </div>
      <MetadataDisplay
        onChangeTitle={handleUpdateTitle}
        title={state.metadata.title}
        user={getUsername()}
      />
      {authStatus === AuthStatus.CONNECT && user.name ? (
        <PopOver minimal={true} content={exampleMenu} position={"bottom"}>
          <div className={styles.user}>
            <Avatar name={user.name} size={25} />
            <Button
              intent={"primary"}
              text={user.name}
              data-cy={"AccountButton"}
            />
          </div>
        </PopOver>
      ) : (
        <Button
          text={"Sign In"}
          minimal={true}
          onClick={() => browserHistory.push("/user/login")}
        />
      )}
    </div>
  );
};

export default HeaderContents;
