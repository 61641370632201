import { ChalkArrow } from "../../../../../../../components/icon";
import React from "react";
import styles from "./FirstInstructions.module.scss";

export const FirstInstructions = () => (
  <div className={styles.firstInstructions}>
    <p className={styles.start}>
      Start by creating your <br />
      first vertex
    </p>
    <p className={styles.keyboard}>Hold Shift and click somewhere</p>
    <p className={styles.moreHelp}>
      Need more help? check out the help section.
    </p>
    <div className={styles.arrowWrapper}>
      <ChalkArrow />
    </div>
  </div>
);
