import {
  IGraph,
  INode,
  IEdge,
  GraphEntity,
} from "../../../../../../components/graph-builder/types/GraphTypes";
import { useCallback } from "react";
import { EntityType } from "../../../../../../shared/types/common/enums";
import GraphUtils from "../../../../../../services/graph-builder/GraphUtils";

/**
 *
 * @param scheme
 */
export const useCreateNode = (scheme: IGraph) =>
  useCallback(
    (node: INode) => {
      return {
        edges: scheme.edges,
        nodes: [
          ...scheme.nodes.filter((n) => n.id !== -1),
          {
            ...node,
            title: `${node.data.label}`,
          },
        ],
      };
    },
    [scheme.edges, scheme.nodes]
  );

/**
 *
 * @param scheme
 */
export const useCreateEdge = (scheme: IGraph) =>
  useCallback(
    (edge: IEdge) => {
      return {
        nodes: scheme.nodes,
        edges: [...scheme.edges.filter((e) => e.id !== -1), edge],
      };
    },
    [scheme]
  );

/**
 *
 * @param scheme
 */
export const useDeleteNode = (scheme: IGraph) =>
  useCallback(
    (node: INode, nodeId: number, nodes: INode[]) => {
      return {
        edges: scheme.edges.filter(
          (edge) => edge.source !== nodeId && edge.target !== nodeId
        ),
        nodes,
      };
    },
    [scheme]
  );

/**
 *
 * @param scheme
 */
export const useDeleteEdge = (scheme: IGraph) =>
  useCallback(
    (edge: IEdge) => {
      return {
        edges: scheme.edges.filter((e) => e.id !== edge.id),
        nodes: scheme.nodes,
      };
    },
    [scheme.edges, scheme.nodes]
  );

/**
 *
 * @param scheme
 */
export const useDeleteEntity = (scheme: IGraph) =>
  useCallback(
    async (entity: GraphEntity, onDeleteNode, onDeleteEdge) => {
      if (entity.type === EntityType.node) {
        await onDeleteNode(
          entity,
          entity.id,
          scheme.nodes.filter((n) => n.id !== entity.id)
        );
      } else if (entity.type === EntityType.edge) {
        await onDeleteEdge(entity);
      }
    },
    [scheme.nodes]
  );

/**
 *
 * @param scheme
 */
export const useUpdateNode = (scheme: IGraph) =>
  useCallback(
    (node: INode) => {
      return {
        edges: scheme.edges,
        nodes: scheme.nodes.map((n) => (n.id === node.id ? { ...node } : n)),
      };
    },
    [scheme.edges, scheme.nodes]
  );

/**
 *
 * @param scheme
 */
export const useUpdateEdge = (scheme: IGraph) =>
  useCallback(
    (edge: IEdge) => {
      return {
        edges: scheme.edges.map((e) => (e.id === edge.id ? { ...edge } : e)),
        nodes: scheme.nodes,
      };
    },
    [scheme.edges, scheme.nodes]
  );

/**
 *
 * @param scheme
 */
export const useSwapEdge = (scheme: IGraph) =>
  useCallback(
    (oldEdge: IEdge, newEdge) => {
      return {
        edges: scheme.edges.map((e) =>
          e.id === oldEdge.id ? { ...newEdge } : e
        ),
        nodes: scheme.nodes,
      };
    },
    [scheme.edges, scheme.nodes]
  );

/**
 *
 * @param scheme
 */
export const useUpdateNodePosition = (scheme: IGraph) =>
  useCallback(
    (node: INode) => {
      return {
        nodes: scheme.nodes.map((n) => (n.id === node.id ? { ...node } : n)),
        edges: scheme.edges,
      };
    },
    [scheme.edges, scheme.nodes]
  );

/**
 *
 * @param scheme
 */
export const useCreateDummyNode = (scheme: IGraph) =>
  useCallback(
    (x: number, y: number) => {
      const node = GraphUtils.createNode(x, y, { label: "?" });
      return {
        edges: scheme.edges,
        nodes: [
          ...scheme.nodes,
          {
            ...node,
            id: -1,
          },
        ],
      };
    },
    [scheme.edges, scheme.nodes]
  );

/**
 *
 * @param scheme
 */
export const useCreateDummyEdge = (scheme: IGraph) =>
  useCallback(
    (source: INode, target: INode) => {
      const edge = GraphUtils.createEdge(source, target, { label: "?" });
      return {
        nodes: scheme.nodes,
        edges: [
          ...scheme.edges,
          {
            ...edge,
            id: -1,
          },
        ],
      };
    },
    [scheme.edges, scheme.nodes]
  );
