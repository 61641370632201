export type Validator = (
  input: string,
  input2?: string | undefined
) => string[];

class Validators {
  /**
   *
   * @param validators
   */
  public static chain(...validators: Validator[]) {
    return (input: string) =>
      validators.reduce((acc, validator) => {
        const errors = validator(input);
        return acc.concat(errors);
      }, [] as string[]);
  }
  /**
   *
   * @param length
   */
  public static minLength(length: number, text?: string) {
    return (input: string) => {
      if (input.length < length) {
        const value = text ? text : "Value";
        return [`${value} must have at least ${length} characters`];
      }
      return [];
    };
  }

  /**
   *
   * @param str
   */
  public static contains(str: string) {
    return (input: string) => {
      if (!input.includes(str)) {
        return [`Value must contain ${str}`];
      }
      return [];
    };
  }

  /**
   *
   * @param length
   */
  public static maxLength(length: number) {
    return (input: string) => {
      if (input.length > length) {
        return [`Value must have at most ${length} characters`];
      }
      return [];
    };
  }

  /**
   *
   */
  public static isANumber() {
    return (input: string) => {
      if (isNaN(Number(input))) {
        return [`Value must be a number`];
      }

      return [];
    };
  }

  public static isValidEmail() {
    return (email: string) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        return [`Enter a valid email`];
      }

      return [];
    };
  }

  public static isValidPassword() {
    return (password: string) => {
      if (!(password.length > 7)) {
        return [`Password must be at least 8 characters`];
      }

      return [];
    };
  }

  public static isPasswordsEquals() {
    return (password: string, confirmPassword: string | undefined) => {
      if (password !== confirmPassword) {
        return [`Passwords do not match`];
      }

      return [];
    };
  }

  /**
   *
   */
  public static isHostname() {
    return (input: string) => {
      const regexIP = new RegExp(
        '"^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$"'
      );
      const regexHostName = new RegExp(
        "^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$"
      );
      if (!regexHostName.test(input) && !regexIP.test(input)) {
        return ["Value must be a valid host name"];
      }

      return [];
    };
  }

  /**
   *
   * @param args
   */
  public static isOneOf(...args: string[]) {
    return (input: string) => {
      if (!args.includes(input)) {
        return [`Value must be one of the following: ${args.join()}`];
      }

      return [];
    };
  }

  /**
   *
   */
  public static isRequired() {
    return (input: string) => {
      if (!input) {
        return ["Value is required"];
      }
      return [];
    };
  }
}

export default Validators;
