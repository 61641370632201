import React, { FC } from "react";
import styles from "./Title.module.scss";
import classNames from "classnames";
import { Icon } from "../icon";

export interface ITitleProps {
  title: string;
  className?: string;
  primary?: boolean;
  open?: boolean;
  onClick?: () => void;
}

const Title: FC<ITitleProps> = ({ title, className, primary, open, onClick }) => (
  <div
    onClick={onClick}
    className={classNames(styles.sideBarTitle, className, {
      [styles.primary]: primary,
    })}
  >
    {!primary && (
      <Icon
        className={classNames(styles.caret, { [styles.open]: open })}
        icon={"caret-right"}
      />
    )}
    <span>{title}</span>
  </div>
);

export default Title;
