import { Alert as BPAlert, IAlertProps } from "@blueprintjs/core";
import * as React from "react";
import { FC } from "react";
import classNames from "classnames";
import styles from "./Alert.module.scss";

const Alert: FC<IAlertProps> = (props) => (
  <BPAlert {...props} className={classNames(props.className, styles.alert)} />
);

export default Alert;
