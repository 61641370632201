import { FC, useCallback } from "react";
import * as React from "react";
import { Alert } from "../../../../components/alert";

interface IClearDialog {
  isOpen: boolean;
  close: () => void;
  onClear: () => Promise<void>;
  text: string;
  confirmButtonText: string;
}

export const ConfirmDialog: FC<IClearDialog> = ({
  isOpen,
  close,
  onClear,
  text,
  confirmButtonText,
}) => {
  const onConfirm = useCallback(async () => {
    close();
    await onClear();
  }, [onClear, close]);

  return (
    <Alert
      data-cy={"ClearDialog"}
      cancelButtonText="Cancel"
      confirmButtonText={confirmButtonText}
      icon="trash"
      intent={"danger"}
      isOpen={isOpen}
      onCancel={close}
      onConfirm={onConfirm}
      canOutsideClickCancel={true}
    >
      <p>{text}</p>
    </Alert>
  );
};
