import React, { FC, useCallback } from "react";
import { Button } from "../../../../../components/button";
import styles from "./Toolbar.module.scss";
import { IconName } from "@blueprintjs/core";
import { Tooltip } from "../../../../../components/tooltip";

interface IToolbarButtonProps {
  icon?: IconName;
  text: string;
  active: boolean;
  id: string;
  onClick: (id: string) => void;
  disabled: boolean;
  testId: string;
}

const ToolbarButton: FC<IToolbarButtonProps> = ({
  icon,
  active,
  id,
  text,
  onClick,
  disabled,
  testId,
}) => {
  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);
  return (
    <Tooltip disabled={disabled} content={text} position={"bottom"}>
      <Button
        active={active}
        onClick={handleClick}
        className={styles.button}
        minimal={true}
        icon={icon}
        disabled={disabled}
        data-cy={testId}
      />
    </Tooltip>
  );
};

interface IToolbarProps {
  activeView: string | null;
  setView: (view: string) => void;
  disabled: boolean;
}

const Toolbar: FC<IToolbarProps> = ({ activeView, setView, disabled }) => (
  <div className={styles.toolbar} data-cy={"ResultsToolbar"}>
    <ToolbarButton
      id={"json"}
      disabled={disabled}
      onClick={setView}
      icon={"diagram-tree"}
      active={activeView === "json"}
      text={"JSON"}
      testId={"JSON"}
    />
    <ToolbarButton
      id={"table"}
      disabled={disabled}
      onClick={setView}
      icon={"th"}
      active={activeView === "table"}
      text={"Table"}
      testId={"Table"}
    />
  </div>
);

export default Toolbar;
