import React, { FC, useCallback, useState } from "react";
import styles from "./Section.module.scss";
import classNames from "classnames";
import Title from "./Title";

export interface ISectionProps {
  className?: string;
  defaultOpen?: boolean;
  title?: string;
}

const Section: FC<ISectionProps> = ({
  title,
  children,
  className,
  defaultOpen = true,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const handleTitleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div>
      {title && <Title open={open} onClick={handleTitleClick} title={title} />}
      {open && (
        <div className={classNames(styles.sideBarSection, className, {[styles.open]: open})}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Section;
