import React, { useCallback, useEffect, useState } from "react";
import styles from "./Inviation.module.scss";
import { Card } from "../../../components/card";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { Button } from "../../../components/button";
import { useHistory } from "react-router";
import { OrganizationService } from "../../../services/organization";
import { browserHistory } from "../../router/history";

export const Invitation = () => {
  const [orgName, setOrgName] = useState(null);
  const [isAccepted, setIsAccepted] = useState(false);
  const history = useHistory();
  const goBack = useCallback(() => history.replace("/"), [history]);

  useEffect(() => {
    const acceptInvitation = async () => {
      const result = await OrganizationService.acceptInvitation();
      if (result) {
        setOrgName(result);
        setIsAccepted(true);
      } else {
        goBack();
      }
    };
    acceptInvitation();
  }, [goBack, history]);

  if (!isAccepted) return null;
  return (
    <div className={styles.invitation}>
      <div className={styles.content}>
        <Logo className={styles.logo} />
        <Card elevation={4} className={styles.card}>
          <div>
            <h2>
              Welcome to <strong>Gremlify!</strong>
            </h2>
          </div>
          <p>
            You are now a member of <strong>{orgName}</strong> organization.
          </p>
          <Button
            className={styles.button}
            onClick={() => browserHistory.replace("/workspaces/organization")}
            large
            intent={"success"}
            text={"Start Collaborating"}
          />
        </Card>
      </div>
    </div>
  );
};
