import { ISwitchProps, Switch as BPSwitch } from "@blueprintjs/core";
import React, { FC } from "react";
import styles from "./Switch.module.scss";
import classNames from "classnames";

const Switch: FC<ISwitchProps> = (props) => (
  <BPSwitch {...props} className={classNames(styles.switch, props.className)} />
);

export default Switch;
