import * as React from "react";
import SandBoxContainer from "./SandBoxContainer";
import SandBoxContextProvider from "./SandBoxContextProvider";
import SandBoxContent from "./SandBoxContent";
import Header from "./components/header/Header";

const SandBox = () => (
  <SandBoxContextProvider>
    <SandBoxContainer>
      <Header/>
      <SandBoxContent/>
    </SandBoxContainer>
  </SandBoxContextProvider>
);

export default SandBox;