import { useMemo } from "react";
import { ISandBoxReducerState } from "../../../../reducers/SandBoxReducer";

/**
 *
 * @param state
 */
export const useSelectNodeLabels = (state: ISandBoxReducerState) =>
  useMemo(() => state.labels.nodeLabels, [state.labels.nodeLabels]);

/**
 *
 * @param state
 */
export const useSelectEdgeLabels = (state: ISandBoxReducerState) =>
  useMemo(() => state.labels.edgeLabels, [state.labels.edgeLabels]);
