import { SandboxAction } from "../sandbox/reducers/SandboxActions";

export type MetadataAction = {
  type: "UPDATE_WORKSPACE_META";
  payload: {
    title?: string;
    description?: string;
    isPrivate?: boolean;
    user?: string;
    id?: number;
  };
};

export interface IMetadataState {
  title: string;
  description: string;
  isPrivate: boolean;
  user: string;
  id?: number;
}

export const initialState: IMetadataState = {
  title: "",
  description: "",
  isPrivate: false,
  user: "",
  id: undefined,
};

export const reducer = (
  action: SandboxAction,
  state: IMetadataState
): IMetadataState => {
  switch (action.type) {
    case "CLEAR_WORKSPACE":
      return initialState;
    case "SET_WORKSPACE":
      return {
        ...state,
        title: action.payload.title,
        description: action.payload.description,
        isPrivate: action.payload.isPrivate,
        id: action.payload.id,
      };
    case "UPDATE_WORKSPACE_META":
      return {
        ...state,
        title:
          action.payload.title !== undefined
            ? action.payload.title
            : state.title,
        description:
          action.payload.description !== undefined
            ? action.payload.description
            : state.description,
        isPrivate:
          action.payload.isPrivate !== undefined
            ? action.payload.isPrivate
            : state.isPrivate,
        user:
          action.payload.user !== undefined ? action.payload.user : state.user,
        id: action.payload.id !== undefined ? action.payload.id : state.id,
      };
  }

  return state;
};
