import * as React from "react";
import {initialState, ISandBoxReducerState} from "./reducers/SandBoxReducer";
import {Dispatch} from "react";
import {SandboxAction} from "./reducers/SandboxActions";

// TODO: change state type
const SandBoxContext = React.createContext<[ISandBoxReducerState, Dispatch<SandboxAction>]>([
  initialState,
  () => initialState
]);

export default SandBoxContext;
