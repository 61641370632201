import Editor from "../Editor";
import * as React from "react";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-terminal";
import { IEditorProps } from "../EditorTypes";
import { FC } from "react";

const JsonEditor: FC<IEditorProps> = ({
  onChange,
  value,
  label,
  allowedUploadFormats,
  renderCustomToolbar,
  ...restProps
}) => (
  <Editor
    {...restProps}
    mode={"json"}
    theme={"monokai"}
    onChange={onChange}
    editorProps={{ $blockScrolling: false }}
    value={value}
    name={"json"}
    allowedUploadFormats={allowedUploadFormats}
    label={label}
    renderCustomToolbar={renderCustomToolbar}
  />
);

JsonEditor.defaultProps = {
  value: "",
  label: "json",
  allowedUploadFormats: ".json"
};

export default JsonEditor;
