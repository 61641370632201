import React, { useCallback, useEffect, useState } from "react";
import { Header } from "../../components/header";
import { Button } from "../../components/button";
import styles from "./Organization.module.scss";
import { OrganizationService } from "../../services/organization";
import { CloudSpinner } from "../../components/spinner";
import { EmptyState } from "./EmptyState";
import { IOrganization } from "./types";
import { OrganizationData } from "./OrganizationData";
import { FormDialog } from "./FormDialog";
import { Validators } from "../../utils/Validators";
import useAuth from "../authentication/hook/useAuth";
import { browserHistory } from "../router/history";

export const Organization = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState<null | string>(null);
  const [organization, setOrganization] = useState<null | IOrganization>(null);
  const isOwner = organization && user.id === organization.ownerId;

  const fetchOrganizationData = useCallback(async () => {
    setIsLoading(true);
    const org = await OrganizationService.getUserOrganization();
    setOrganization(org);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchOrganizationData();
  }, [fetchOrganizationData]);

  const handleDialogClose = useCallback(
    (result) => {
      if (result) {
        fetchOrganizationData();
      }
      setOpenDialog(null);
    },
    [fetchOrganizationData]
  );

  const handleCreateOrganization = useCallback(async (name: string) => {
    return OrganizationService.createOrganization(name);
  }, []);

  const handleInviteUser = useCallback(
    async (email: string) => {
      if (organization) {
        return OrganizationService.inviteUser(organization.id, email);
      }
      return Promise.reject();
    },
    [organization]
  );

  const handleDeleteUserFromOrganization = useCallback(
    async (userId) => {
      if (organization) {
        const result = await OrganizationService.removeUserFromOrganization(
          organization.id,
          userId
        );
        if (result) {
          setOrganization((prev) => {
            return prev
              ? {
                  ...prev,
                  users: prev.users.filter((user) => user.id !== userId),
                }
              : prev;
          });
        }
      }
    },
    [organization]
  );

  const renderContent = () => {
    if (isLoading) return <CloudSpinner />;

    if (organization) {
      return (
        <OrganizationData
          onDeleteUserFromOrganization={handleDeleteUserFromOrganization}
          data={organization}
        />
      );
    } else {
      return (
        <EmptyState
          onCreateOrganization={() => setOpenDialog("createOrganization")}
        />
      );
    }
  };

  return (
    <div className={styles.organization}>
      <FormDialog
        title={"Create New Organization"}
        onClose={handleDialogClose}
        inputProps={{
          label: "Organization Name",
          placeholder: "e.g TinkerPop",
          validator: Validators.isRequired(),
        }}
        onSubmit={handleCreateOrganization}
        buttonLabel={"Create"}
        isOpen={openDialog === "createOrganization"}
      />
      <FormDialog
        title={"Invite User"}
        onClose={handleDialogClose}
        inputProps={{
          label: "Email",
          placeholder: "e.g johndoh@gmail.com",
          validator: Validators.isValidEmail(),
        }}
        onSubmit={handleInviteUser}
        isOpen={openDialog === "inviteMember"}
        buttonLabel={"Invite"}
      />
      <Header>
        <Button
          minimal={true}
          onClick={() => browserHistory.push("/")}
          text={"Home"}
        />
      </Header>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.content}>{renderContent()}</div>
          <div className={styles.footer}>
            {isOwner && (
              <Button
                intent={"success"}
                text={"Invite User"}
                icon={"new-person"}
                onClick={() => setOpenDialog("inviteMember")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
