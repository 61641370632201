import { GraphView } from "@gremlify/react-digraph";
import React, { FC, memo, ReactElement, useRef } from "react";
import { GraphEntity, IEdge, IGraph, INode } from "../types/GraphTypes";
import { IGraphConfig } from "../config/GrahpConfigBuilder";
import defaultGraphConfig from "../config/defaultGraphConfig";
import styles from "./GraphBuildr.module.scss";
import "./GraphView.scss";
import EntityToolbar from "./EntityToolbar/EntityToolbar";
import classNames from "classnames";
import { GeneralToolbar } from "./GeneralToolbar/GeneralToolbar";
import { ContextMenu } from "../../context-menu";
import { IOption } from "../../key-value-editor/EntryLine/interface";
import { IKeyValueEntry } from "../../key-value-editor/KeyValueEditor";

interface IGraphBuilder {
  scheme: IGraph;
  graphConfig?: IGraphConfig;
  graphWrapperClassName?: string;
  onCreateNode: (x: number, y: number) => void;
  onCreateEdge: (source: INode, target: INode) => void;
  onUpdateEntityData: (entity: GraphEntity) => void;
  onDeleteNode: (node: INode, nodeId: number, nodeArr: INode[]) => void;
  onUpdateNodePosition: (node: INode) => void;
  onSwapEdge: (source: INode, target: INode, edge: IEdge) => void;
  onDeleteEdge: (edge: IEdge) => void;
  onSelectNode: (node: INode) => void;
  onSelectEdge: (edge: IEdge) => void;
  onCopy: () => void;
  onPaste: () => void;
  selected: GraphEntity | null;
  label?: string | ReactElement;
  graphId?: string;
  updateLabelColor: (label: string, color: string) => void;
  colorMap: { [key: string]: string };
  onDeleteEntity: (entity: GraphEntity) => void;
  contextMenu?: JSX.Element;
  customToolbar?: JSX.Element;
  entityValueOptions?: IOption[];
  entityKeyOptions?: IOption[];
  customEntryValidator?: (entries: IKeyValueEntry[]) => string | null;
  displayEntityId?: boolean;
  canModifyEntries?: boolean;
  entityMaxValueChars?: number;
}

const GraphBuilder: FC<IGraphBuilder> = ({
  graphWrapperClassName,
  scheme,
  onCreateNode,
  onCreateEdge,
  onDeleteNode,
  onUpdateNodePosition,
  onUpdateEntityData,
  onSwapEdge,
  onDeleteEdge,
  onCopy,
  onPaste,
  selected,
  onSelectEdge,
  onSelectNode,
  graphConfig = defaultGraphConfig,
  graphId,
  updateLabelColor,
  colorMap,
  onDeleteEntity,
  contextMenu,
  customToolbar,
  entityValueOptions,
  entityKeyOptions,
  customEntryValidator,
  displayEntityId = true,
  canModifyEntries = true,
  entityMaxValueChars = Infinity,
}) => {
  const ref = useRef(null);

  return (
    <div
      className={classNames(styles.graphBuilder, graphWrapperClassName)}
      data-cy={"GraphBuilder"}
    >
      {selected && (
        <EntityToolbar
          colorMap={colorMap}
          updateLabelColor={updateLabelColor}
          entity={selected}
          onSaveEntityData={onUpdateEntityData}
          deleteEntity={onDeleteEntity}
          valueOptions={entityValueOptions}
          customValidator={customEntryValidator}
          displayEntityId={displayEntityId}
          canModifyEntries={canModifyEntries}
          keyOptions={entityKeyOptions}
          maxValueChars={entityMaxValueChars}
        />
      )}
      <GeneralToolbar
        className={classNames({ [styles.pushDown]: selected }, styles.toolbar)}
        customToolbar={customToolbar}
      />
      <ContextMenu
        wrapperId={"graph"}
        content={contextMenu}
        className={styles.graphWrapper}
      >
        <GraphView
          ref={ref}
          nodeKey={"id"}
          nodes={scheme.nodes}
          edges={scheme.edges}
          onCreateNode={onCreateNode}
          selected={selected}
          nodeTypes={graphConfig.NodeTypes}
          nodeSubtypes={graphConfig.NodeSubtypes}
          edgeTypes={graphConfig.EdgeTypes}
          onSelectNode={onSelectNode}
          onCreateEdge={onCreateEdge}
          onUpdateNode={onUpdateNodePosition}
          onDeleteNode={onDeleteNode}
          onSelectEdge={onSelectEdge}
          onSwapEdge={onSwapEdge}
          onDeleteEdge={onDeleteEdge}
          minZoom={0}
          onCopySelected={onCopy}
          onPasteSelected={onPaste}
          showGraphControls={false}
          graphId={graphId}
        />
      </ContextMenu>
    </div>
  );
};

export default memo(GraphBuilder);
