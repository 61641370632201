import {
  HTMLInputProps,
  IInputGroupProps as IBPInputProps,
  InputGroup as BPInputGroup,
} from "@blueprintjs/core";
import * as React from "react";
import { FC } from "react";
import withErrors from "./withErrors";
import styles from "./InputGroup.module.scss";
import classNames from "classnames";

type IProps = IBPInputProps & HTMLInputProps;
export interface IInputGroupProps extends IProps {
  errors?: string;
}

const InputGroup: FC<IInputGroupProps> = ({ errors = "", ...props }) => {
  const InputClassNames = classNames(
    styles.inputGroup,
    props.className
  );
  return <BPInputGroup {...props} className={InputClassNames} />;
};

export default withErrors<IInputGroupProps>(InputGroup);
