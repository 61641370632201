import { useCallback, useContext, useState } from "react";
import SandBoxContext from "../SandBoxContext";
import WorkspaceService from "../../../services/workspace/WorkspaceService";
import { GraphUtils } from "../../../services/graph-builder";
import { useAnalytics } from "../../../shared/hooks";

export const useUndo = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const [isLoading, setIsLoading] = useState(false);
  const [sendEvent] = useAnalytics();
  const id = state.metadata.id;
  const undo = useCallback(async () => {
    if (id) {
      try {
        setIsLoading(true);
        sendEvent({ category: "Workspace", action: "Undo" });
        const workspace = await WorkspaceService.undo(
          id,
          state.graphBuilder.scheme
        );
        const raw = GraphUtils.buildGraphFromResponse(workspace.graph);
        const graph = GraphUtils.generateGraphFromLayout(
          state.graphBuilder.layout,
          raw
        );

        dispatch({
          type: "SET_WORKSPACE",
          payload: { ...workspace, graph },
        });
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    id,
    sendEvent,
    state.graphBuilder.scheme,
    state.graphBuilder.layout,
    dispatch,
  ]);

  return { undo, isLoading };
};
