import React from "react";
import styles from "./Community.module.scss";
import { Sidebar } from "../../components/sidebar";

const Community = () => (
  <div className={styles.community}>
    <Sidebar.Section className={styles.section}>
      <p>
        Our main goal in Gremlify is to help making Gremlin accessible to
        everyone, without the need to download or configure anything. This goal
        could be met by the help and feedback of the community.
      </p>
    </Sidebar.Section>
    <Sidebar.Section defaultOpen={false} title={"Twitter"} className={styles.section}>
      <p>
        Follow us on{" "}
        <a
          href={"https://twitter.com/gremlify"}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          Twitter
        </a>{" "}
        to always stay updated!
      </p>
    </Sidebar.Section>
    <Sidebar.Section title={"Github"} defaultOpen={false} className={styles.section}>
      <p>
        Encountered an issue? have question? Please file an issue on our{" "}
        <a
          href={"https://github.com/gremlfiy/gremlify/issues"}
          target={"_blank"}
          rel="noopener noreferrer"
        >
          issues
        </a>{" "}
        page.
      </p>
    </Sidebar.Section>
    <Sidebar.Section defaultOpen={false} title={"Apache Tinkerpop"} className={styles.section}>
      <p>
        Gremlify is proud to be powered by Apache Tinkerpop. Visit to official{" "}
        <a href={"http://tinkerpop.apache.org/docs/current/"}>
          Apache Tinkerpop
        </a>{" "}
        documentation.
      </p>
    </Sidebar.Section>
    <Sidebar.Section defaultOpen={false} title={"Contact Details"}>
      <p>
        For any other matter, please mail us at{" "}
        <span className={styles.mail}>gremlify@gmail.com</span>
      </p>
    </Sidebar.Section>
  </div>
);

export default Community;
