import * as React from "react";
import { FC, useCallback, useContext, useState } from "react";

import styles from "./Templates.module.scss";
import { Sidebar } from "../../components/sidebar";
import useTemplates, { ITemplate } from "./useTemplates";
import SandBoxContext from "../sandbox/SandBoxContext";
import { CloudSpinner } from "../../components/spinner";
import { Card } from "../../components/card";
import { PaginatedList } from "../../components/paginated-list";
import ConfirmationDialog from "./ConfirmationDialog";

const Template: FC<ITemplate & { onApply: (template: string) => void }> = ({
  name,
  title,
  description,
  onApply,
}) => {
  const handleClick = useCallback(() => {
    onApply(name);
  }, [onApply, name]);
  return (
    <Card
      onClick={handleClick}
      interactive={true}
      className={styles.templateCard}
    >
      <h3>{title}</h3>
      <p>{description}</p>
    </Card>
  );
};

const Templates: FC = () => {
  const dispatch = useContext(SandBoxContext)[1];
  const [loading, templates, applyTemplate] = useTemplates(dispatch);
  const [stagedTemplate, setStagedTemplate] = useState<null | string>(null);
  const onTemplateSelect = useCallback(async (template) => {
    setStagedTemplate(template);
  }, []);
  const closeConfirmModal = useCallback(() => {
    setStagedTemplate(null);
  }, []);
  const handleConfirm = useCallback(async () => {
    closeConfirmModal();
    if (stagedTemplate) {
      await applyTemplate(stagedTemplate);
    }
  }, [stagedTemplate, applyTemplate, closeConfirmModal]);

  const renderTemplateCard = useCallback(
    (template) => {
      return (
        <Template
          key={template.name}
          onApply={onTemplateSelect}
          {...template}
        />
      );
    },
    [onTemplateSelect]
  );

  return (
    <div className={`${styles.templates} gy-templates`} data-cy={"Templates"}>
      {loading && (
        <div className={styles.spinnerWrapper}>
          <CloudSpinner />
        </div>
      )}
      {stagedTemplate && (
        <ConfirmationDialog
          onClose={closeConfirmModal}
          onConfirm={handleConfirm}
        />
      )}
      <Sidebar.Content className={styles.templatesWrapper}>
        <Sidebar.Section className={styles.description}>
          <span>Browse through ready to play with Graph Templates.</span>
        </Sidebar.Section>
        <Sidebar.Section className={styles.listWrapper}>
          <PaginatedList
            perPage={3}
            className={styles.templateList}
            items={templates}
            renderItem={renderTemplateCard}
          />
        </Sidebar.Section>
      </Sidebar.Content>
    </div>
  );
};

export default Templates;
