import React, { FC } from "react";
import styles from "./Filters.module.scss";
import { Avatar } from "../../../../components/Avatar";
import { IFilterState } from "../../useWorkspaces";
import { Icon } from "../../../../components/icon";

interface IFiltersProps {
  users: string[];
  updateFilter: (key: keyof IFilterState, value: string) => void;
  filters: IFilterState;
}

export const Filters: FC<IFiltersProps> = ({
  users,
  updateFilter,
  filters,
}) => (
  <div className={styles.filters}>
    {users.length > 0 && (
      <>
        <div className={styles.title}>User</div>
        <ul className={styles.list}>
          {users.map((user) => (
            <li
              onClick={() => updateFilter("users", user)}
              className={styles.listItem}
              key={user}
            >
              <div className={styles.userItem}>
                <div className={styles.userName}>
                  <Avatar className={styles.avatar} name={user} size={25} />
                  <span>{user}</span>
                </div>
                {filters.users.includes(user) && (
                  <Icon icon={"tick"} intent={"success"} />
                )}
              </div>
            </li>
          ))}
        </ul>
      </>
    )}
  </div>
);
