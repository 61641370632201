import classNames from "classnames";
import React, { FC } from "react";
import styles from "./EntityDataViewer.module.scss";
import { Tooltip } from "../../../../tooltip";

interface IEntityDataViewerProps {
  data: { [key: string]: string | number };
  maxValueChars?: number;
}

export const EntityDataViewer: FC<IEntityDataViewerProps> = ({
  data,
  maxValueChars = Infinity,
}) => (
  <div className={classNames(styles.dataViewer)}>
    {Object.entries(data).map(([key, value]) => (
      <Tooltip
        key={key}
        content={value ? value.toString() : ""}
        position={"bottom"}
      >
        <div className={classNames(styles.entry)}>
          <label>{key}:</label>{" "}
          <span>{value.toString().slice(0, maxValueChars)}</span>
        </div>
      </Tooltip>
    ))}
  </div>
);
