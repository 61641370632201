import React, { FC } from "react";
import classNames from "classnames";
import styles from "./SideBar.module.scss";
import { Button } from "../../../../../components/button";
import { ButtonGroup } from "../../../../../components/button-group";

interface ISideBarProps {
  activeView: string;
  onChangeView: (view: "graph" | "schema") => void;
}

export const SideBar: FC<ISideBarProps> = ({ activeView, onChangeView }) => {

  return (
    <div className={styles.sideBar}>
      <ButtonGroup className={styles.tabs}>
        <Button
          active={activeView === "schema"}
          intent={"primary"}
          className={classNames(styles.tab, 'gy-tab-schema')}
          onClick={() => onChangeView("schema")}
          text={"SCHEMA"}
        />
        <Button
          active={activeView === "graph"}
          intent={"primary"}
          onClick={() => onChangeView("graph")}
          className={classNames(styles.tab, 'gy-tab-graph')}
          text={"GRAPH"}
        />
      </ButtonGroup>
    </div>
  );
};
