import { FC } from "react";
import * as React from "react";
import AuthContext from "./AuthContext";
import { IAuthContext } from "./AuthTypes";

const AuthProvider: FC<IAuthContext> = ({
  user,
  authStatus,
  login,
  signUp,
  logOut,
  resetPasswordRequest,
  changePassword,
  changeOldPassword,
  ...props
}) => {
  return (
    <AuthContext.Provider
      value={{
        user,
        authStatus,
        login,
        signUp,
        logOut,
        resetPasswordRequest,
        changePassword,
        changeOldPassword,
      }}
      {...props}
    />
  );
};

export default AuthProvider;
