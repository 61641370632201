import { useCallback, useContext, useEffect, useState } from "react";
import SandBoxContext from "../../../SandBoxContext";
import { useAnalytics } from "../../../../../shared/hooks";
import { useCompareSchemes } from "./hooks/useCompareSchemes";
import { useClearSchema } from "./hooks/useClearSchema";
import GraphUtils from "../../../../../services/graph-builder/GraphUtils";
import { useEnforceSchema } from "../../../../../services/graph-builder";

export const useSyncGraphAndSchema = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const {
    graphBuilder: { scheme: graphScheme },
    schemaBuilder: { scheme: schemaScheme },
  } = state;
  const [isSynced, setIsSynced] = useState(true);
  const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false);
  const [sendEvent] = useAnalytics();
  const compare = useCompareSchemes();
  const enforce = useEnforceSchema();
  const clearSchema = useClearSchema();

  const enforceSchema = useCallback(async () => {
    const nextGraph = await enforce(schemaScheme, graphScheme);
    if (nextGraph) {
      const flatNextGraph = GraphUtils.buildGraphWithLayoutFromResponse(
        nextGraph,
        state.graphBuilder.layout
      );
      dispatch({ type: "UPDATE_GRAPH", payload: { scheme: flatNextGraph } });
    }
  }, [dispatch, enforce, graphScheme, schemaScheme, state.graphBuilder.layout]);

  const confirmSync = useCallback(async () => {
    await enforceSchema();
    sendEvent({ category: "Schema", action: "Sync" });
    setIsSyncDialogOpen(false);
  }, [enforceSchema, sendEvent]);

  const cancelSync = useCallback(() => {
    clearSchema();
    setIsSyncDialogOpen(false);
  }, [clearSchema]);

  useEffect(() => {
    const hasSchema = schemaScheme.nodes.length > 0;
    if (hasSchema) {
      setIsSynced(compare(graphScheme, schemaScheme));
    }
  }, [compare, graphScheme, schemaScheme]);

  useEffect(() => {
    setIsSyncDialogOpen(!isSynced);
  }, [isSynced]);

  return {
    isSyncDialogOpen,
    confirmSync,
    cancelSync,
  };
};
