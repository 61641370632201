import React, { FC } from "react";
import { Tag } from "../tag";
import styles from "./TypeTag.module.scss";
import classNames from "classnames";

interface ITypeTagProps {
  className?: string;
  type: string;
}

const getClassNameByType = (type: string) => {
  switch (type) {
    case "NUMBER":
      return styles.number;
    case "BOOLEAN":
      return styles.boolean;
    default:
      return styles.string;
  }
};
export const TypeTag: FC<ITypeTagProps> = ({ className, type }) => (
  <Tag
    className={classNames(styles.typeTag, getClassNameByType(type), className)}
  >
    {type}
  </Tag>
);
