import {
  IObject,
  IResponse,
  IRestService,
  RequestMethod,
  RequestMethodType,
} from "./RestTypes";
import axios, { AxiosRequestConfig } from "axios";

class RestService implements IRestService {
  private _headers: IObject;
  private _baseUrl: string;
  private _requestInterceptors: { [key: string]: number[] } = {};

  constructor(baseUrl = "", headers: IObject = {}) {
    this._baseUrl = baseUrl;
    this._headers = headers;
  }

  /**
   * set a header
   * @param key
   * @param value
   */
  private setHeader(key: string, value: string) {
    this._headers[key] = value;
  }

  public setBaseUrl(baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  /**
   * GET
   * @param url
   * @param params
   */
  public async get(url: string, params?: IObject): Promise<IResponse<any>> {
    return this.request(RequestMethod.GET, url, { params });
  }

  /**
   * POST
   * @param url
   * @param data
   */
  public async post(url: string, data?: IObject): Promise<IResponse<any>> {
    return this.request(RequestMethod.POST, url, { data });
  }

  /**
   * DELETE
   * @param url
   * @param data
   */
  public async delete(url: string, data?: IObject): Promise<IResponse<any>> {
    return this.request(RequestMethod.DELETE, url);
  }

  /**
   * PUT
   * @param url
   * @param data
   */
  public async put(url: string, data?: any): Promise<IResponse<any>> {
    return this.request(RequestMethod.PUT, url, { data });
  }

  /**
   * get all default headers
   */
  protected getRequestDefaultHeaders() {
    return this._headers;
  }

  /**
   * add an interceptor
   * @param key
   * @param interceptor
   */
  protected addRequestInterceptor(
    key: string,
    interceptor: (config: AxiosRequestConfig) => AxiosRequestConfig
  ) {
    if (!this._requestInterceptors[key]) {
      this._requestInterceptors[key] = [];
    }
    this._requestInterceptors[key].push(
      axios.interceptors.request.use(interceptor)
    );
  }

  /**
   * eject an interceptor
   * @param key
   */
  protected ejectInterceptor(key: string) {
    this._requestInterceptors[key].forEach((id) => {
      axios.interceptors.request.eject(id);
    });

    this._requestInterceptors[key] = [];
  }

  /**
   * @param method
   * @param url
   * @param options
   */
  private async request(
    method: RequestMethodType,
    url: string,
    options = {}
  ): Promise<IResponse<unknown>> {
    try {
      const response = await axios.request({
        headers: {
          ...this.getRequestDefaultHeaders(),
        },
        url: `${this._baseUrl}/${url}`,
        method,
        ...options,
      });

      return response.data;
    } catch (e) {
      throw e.response.data;
    }
  }
}

export default RestService;
