import {IEntityData, INode} from "../types/GraphTypes";

class NodeFactory {
  public static DefaultLabel = 'Vertex';

  static copy(node: INode): INode {
    return {
      ...node,
      id: -1,
      x: node.x + 20,
      y: node.y + 20,
      data: {
        ...node.data
      },
    };
  }

  static create(id: number, x: number, y: number, data: IEntityData): INode {
    const label = data.label || NodeFactory.DefaultLabel;
    return {
      x,
      y,
      title: `${label}${id !== -1 ? `(${id})` : ''}`,
      type: "node",
      id,
      data: {
        ...data,
        label,
      }
    }
  }
}

export default NodeFactory;
