import * as React from "react";
import { FC } from "react";
import useSandBoxInit from "./hooks/useSandBoxInit";

const SandBoxContainer: FC = props => {
  useSandBoxInit();

  return <React.Fragment {...props} />;
};

export default SandBoxContainer;
