import Editor from "../Editor";
import * as React from "react";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-twilight";
import {IEditorProps} from "../EditorTypes";

const QueryEditor = (props: IEditorProps) => {

  return (
    <Editor mode={'javascript'}
            theme={'twilight'}
            onChange={props.onChange}
            editorProps={{ $blockScrolling: false }}
            value={props.value}
            name={'query'}
    />
  );
};

QueryEditor.defaultProps = {
  value: ''
};

export default QueryEditor;
