import * as React from "react";
import QueryConsole from "./components/query-console/QueryConsole";
import Results from "./components/results/Results";
import styles from "./SandBox.module.scss";
import HelpTour from "./components/help-tour/HelpTour";
import { useContext, useMemo } from "react";
import SandBoxContext from "./SandBoxContext";
import {
  ResizeContainer,
  ResizeElement,
  ResizeSplitter,
} from "../../components/resize";
import { SideMenu } from "../side-menu";
import { useHelpTour } from "./hooks/useHelpTour";
import { Helmet } from "react-helmet";
import { BuildersToggler } from "./components/builders";

const SandBoxContent = () => {
  const [state] = useContext(SandBoxContext);
  const { stop: stopHelpTour } = useHelpTour();
  const title = useMemo(() => {
    const path = [`Gremlify`];
    if (state.metadata.title) {
      path.push(state.metadata.title);
    }
    return path.concat(`online Gremlin workspace`).join(" - ");
  }, [state.metadata.title]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={styles.content}>
        <SideMenu />
        <ResizeContainer orientation="vertical">
          <ResizeElement>
            <ResizeContainer orientation="horizontal">
              <ResizeElement>
                <QueryConsole />
              </ResizeElement>
              <ResizeSplitter />
              <ResizeElement
                propagateDimensions={true}
                style={{ position: "relative", overflow: "hidden" }}
              >
                <Results />
              </ResizeElement>
            </ResizeContainer>
          </ResizeElement>
          <ResizeSplitter />
          <ResizeElement>
            <BuildersToggler />
          </ResizeElement>
        </ResizeContainer>
        <HelpTour onStop={stopHelpTour} run={state.help.helpTourRun} />
      </div>
    </>
  );
};

export default SandBoxContent;
