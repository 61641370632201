import React, { FC, useContext } from "react";
import { RouteProps } from "react-router";
import { AuthStatus } from "../authentication/AuthTypes";
import { Redirect, Route } from "react-router-dom";
import AuthContext from "../authentication/AuthContext";

const LoggedOutOnlyRoute: FC<RouteProps> = (props) => {
  const { authStatus } = useContext(AuthContext);
  return authStatus === AuthStatus.DISCONNECT ||
    authStatus === AuthStatus.CONNECTING ? (
    <Route {...props} />
  ) : (
    <Redirect to={"/"} />
  );
};

export default LoggedOutOnlyRoute;
