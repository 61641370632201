import { useCallback, useEffect, useMemo, useState } from "react";
import * as React from "react";
import { Validator } from "../../utils/Validators/Validators";

export interface IField {
  name: string;
  validator?: Validator;
}

const useForm = <D extends { [key: string]: any }>(
  fields: IField[],
  initialData: D
) => {
  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState({} as { [key: string]: string });
  const onChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name } = event.currentTarget;
      if (event.currentTarget.type !== "checkbox") {
        const { value } = event.currentTarget;
        setFormData({
          ...formData,
          [name]: value,
        });
      } else {
        const { checked } = event.currentTarget as HTMLInputElement;
        setFormData({
          ...formData,
          [name]: checked,
        });
      }
    },
    [formData]
  );

  useEffect(() => {
    const formErrors = fields.reduce((errorsAcc, field) => {
      const validator = field.validator;

      if (validator) {
        const fieldErrors = validator(
          formData[field.name],
          formData["password"]
        );
        if (fieldErrors.length > 0) {
          errorsAcc[field.name] = fieldErrors.join(", ");
        }
      }
      return errorsAcc;
    }, {} as { [key: string]: string });

    setErrors(formErrors);
  }, [fields, formData]);

  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors]);

  return {
    formData,
    onChange,
    hasErrors,
    errors,
  };
};

export default useForm;
