import { Router as ReactRouter, Route, Switch } from "react-router-dom";
import React, { Suspense } from "react";
import { SandBox } from "./../sandbox";
import styles from "./Router.module.scss";
import ProtectedRoute from "./ProtectedRoute";
import LoggedOutOnlyRoute from "./LoggedOutOnlyRoute";
import { browserHistory } from "./history";
import {
  ChangePassword,
  ChangeTempPassword,
  Login,
  ResetPassword,
  SignUp,
} from "./LazyRoutes";
import { Workspaces } from "../workspaces";
import { CloudSpinner } from "../../components/spinner";
import { Organization } from "../organization";
import { Invitation } from "../organization/Invitation";

const Router = () => {
  return (
    <div className={styles.main}>
      <Suspense
        fallback={
          <div className={styles.fallback}>
            <CloudSpinner />
          </div>
        }
      >
        <ReactRouter history={browserHistory}>
          <Switch>
            <LoggedOutOnlyRoute path={"/user/login"} component={Login} />
            <LoggedOutOnlyRoute path={"/user/signup"} component={SignUp} />
            <LoggedOutOnlyRoute
              path={"/user/resetpassword"}
              component={ResetPassword}
            />
            <LoggedOutOnlyRoute
              component={ChangePassword}
              path={"/user/changepassword"}
            />
            <ProtectedRoute
              component={ChangeTempPassword}
              path={"/user/changeTempPassword"}
            />
            <ProtectedRoute
              path={"/workspaces/:view/:key?"}
              component={Workspaces}
            />
            <ProtectedRoute
              path={"/organization/invitation"}
              component={Invitation}
            />
            <ProtectedRoute path={"/organization"} component={Organization} />
            <Route path={"/@:username/:id?/:rev?"} component={SandBox} />
            <Route path={"/:id?/:rev?"} component={SandBox} />
          </Switch>
        </ReactRouter>
      </Suspense>
    </div>
  );
};

export default Router;
