import { PropertyValueType } from "../../shared/types/common/graph";
import { TypeValidationMap } from "./TypeValidators";

export interface ITypeMap {
  [key: string]: string;
}

class TypeUtils {
  /**
   * infers type
   * @param el
   */
  static inferType = (el: unknown): PropertyValueType => {
    if (el instanceof Array) {
      return TypeUtils.inferType(el[0]);
    }

    if (el === true || el === false || el === "true" || el === "false") {
      return "BOOLEAN";
    }

    if (!isNaN(el as number)) {
      return "NUMBER";
    }

    return "STRING";
  };

  /**
   * compare two type maps
   * @param schemeTypes
   * @param graphTypes
   */
  static compareMaps = (schemeTypes: ITypeMap, graphTypes: ITypeMap) => {
    const graphEntries = Object.entries(schemeTypes);
    return graphEntries.every(([key, type]) => schemeTypes[key] === type);
  };

  /**
   * sometimes, values are stored as a 1-length arrays in the Graph
   * @param value
   * @private
   */
  private static extractValidatedValue(value: unknown) {
    return value instanceof Array && value.length === 1 ? value[0] : value;
  }

  /**
   *
   * @param type
   * @param value
   */
  static validateType = (type: PropertyValueType, value: unknown) => {
    const validator = TypeValidationMap[type];
    const validatedValue = TypeUtils.extractValidatedValue(value);

    if (validator) {
      return validator.apply(null, [validatedValue]);
    }

    return true;
  };
}

export default TypeUtils;
