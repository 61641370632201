import Fingerprint2 from 'fingerprintjs2';
import {IComponent} from "./FingerprintServiceTypes";

declare global {
  interface Window {
    requestIdleCallback: ((
      callback: ((deadline: RequestIdleCallbackDeadline) => void),
      opts?: RequestIdleCallbackOptions,
    ) => RequestIdleCallbackHandle);
    cancelIdleCallback: ((handle: RequestIdleCallbackHandle) => void);
  }
}

class FingerprintService {

  static TIMEOUT = 500;

  /**
   * get finger print
   */
  public async get(): Promise<string> {
    return new Promise((resolve) => {
      if (window.requestIdleCallback) {
        window.requestIdleCallback(() => {
          resolve(this.getHashedComponents());
        });
      } else {
        setTimeout(() => {
          resolve(this.getHashedComponents());
        }, FingerprintService.TIMEOUT);
      }
    });
  }

  /**
   * fetch fingerprint components and hash them
   */
  private async getHashedComponents(): Promise<string> {
    return new Promise(resolve => {
      Fingerprint2.get(components => resolve(this.mapComponentsToHash(components)));
    });
  }

  /**
   * hash components
   * @param components
   */
  private mapComponentsToHash(components: IComponent[]): string {
    const values = components.map(component => component.value);
    return Fingerprint2.x64hash128(values.join(''), 31);
  }
}

export default new FingerprintService();