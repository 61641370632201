import AppRestService from "../communication/AppRestService";
import { NotificationService } from "../notification";
import GoogleAnalyticsService from "../analytics/google/GoogleAnalyticsService";

class TemplatesService {
  /**
   * fetch graph templates
   */
  public async fetchTemplates() {
    try {
      const response = await AppRestService.get("templates");

      return response.data;
    } catch (e) {
      NotificationService.error(
        "Could not fetch templates, please come back later."
      );
    }
  }

  /**
   * load a template
   * @param template
   */
  public async loadTemplate(template: string) {
    try {
      const response = await AppRestService.post("templates", {
        name: template
      });
      GoogleAnalyticsService.sendEvent({
        category: "SandBox",
        action: "Load Template",
        label: template
      });

      return response.data;
    } catch (e) {
      NotificationService.error(
        `Could not load template ${template}, please try again later.`
      );
    }
  }
}

export default new TemplatesService();
