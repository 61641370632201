import {
  IPopoverProps,
  PopoverInteractionKind,
  Popover as BPpopOver
} from "@blueprintjs/core";
import { FC } from "react";
import React from "react";
import "./popOver.scss";

const PopOver: FC<IPopoverProps> = props => {
  return (
    <BPpopOver
      interactionKind={PopoverInteractionKind.CLICK}
      {...props}
    />
  );
};

export default PopOver;
