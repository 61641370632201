import { EntityType } from "../../../../../../../../../shared/types/common/enums";
import React, { FC, useCallback, useState } from "react";
import { GraphEntity } from "../../../../../../../../../components/graph-builder/types/GraphTypes";
import styles from "./PropList.module.scss";
import { Help } from "../../../../../../../../../components/help";
import { LabelItem } from "./LabelItem";
import { Collapse } from "../../../../../../../../../components/collapse";
import { Button } from "../../../../../../../../../components/button";

interface IPropListProps {
  entities: GraphEntity[];
  entityType: EntityType;
  onChange: (entityType: EntityType, label: string, prop: string) => void;
  labelPropertyMap: { [key: string]: string };
}

export const PropList: FC<IPropListProps> = ({
  entities,
  entityType,
  labelPropertyMap,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const labels = entities
    .filter((e) => e.id !== -1)
    .map((e) => e.data.label)
    .reduce(
      (acc, cur) => (acc.includes(cur) ? acc : acc.concat(cur)),
      [] as string[]
    );
  const getEntityPropsByLabel = useCallback(
    (entityType: EntityType, label: string) => {
      const filteredByLabel = entities.filter((e) => e.data.label === label);
      const entityProps = filteredByLabel
        .map((e) => Object.keys(e.data))
        .flat();
      const props = new Set([...entityProps]);
      return Array.from(props);
    },
    [entities]
  );
  const handleChange = useCallback(
    (label: string, prop: string) => {
      onChange(entityType, label, prop);
    },
    [entityType, onChange]
  );

  const entityName = entityType === EntityType.edge ? "Edge" : "Node";
  const toggleIsOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <div className={styles.propList}>
      <div className={styles.title}>
        <h3>{entityName} display settings</h3>
        <Help
          text={"Determine what property to display on the Graph entities"}
        />
      </div>
      <div className={styles.collapseTrigger}>
        <Button
          onClick={toggleIsOpen}
          minimal={true}
          text={`${entityName}s`}
          icon={isOpen ? "caret-down" : "caret-right"}
        />
      </div>
      <Collapse isOpen={isOpen}>
        {entities.length === 0 ? (
          <div className={styles.empty}>No {entityName}s.</div>
        ) : (
          <ul className={styles.list}>
            <li className={styles.tableTitles}>
              <div>LABEL</div>
              <div>PROPERTIES</div>
            </li>
            {labels.map((label) => {
              const props = getEntityPropsByLabel(entityType, label);
              return (
                <li key={label}>
                  <LabelItem
                    value={labelPropertyMap[label] || "label"}
                    onChange={handleChange}
                    label={label}
                    props={props}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </Collapse>
    </div>
  );
};
