import { IAppConfig } from "./ConfigurationTypes";

class ConfigurationLoader {
  private _isReady: boolean;
  private _config: IAppConfig;

  constructor() {
    this._isReady = false;
    this._config = {};
  }

  public get isReady(): boolean {
    return this._isReady;
  }

  public get config() {
    return this._config;
  }

  /**
   * initialize config
   */
  public async init(): Promise<IAppConfig> {
    this._config["API_BASE_URL"] = process.env.REACT_APP_API_URL;
    this._config["GOOGLE_ANALYTICS_KEY"] =
      process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
    this._config["GRAPH_LAYOUT_OPTS"] = {
      nodesep: 60,
      ranksep: 90,
      edgesep: 100,
      ranker: "network-simplex",
      acyclicer: "greedy",
    };
    this._isReady = true;
    return this.config;
  }

  /**
   * get a specific config
   * @param key
   */
  public get(key: string) {
    if (this._config[key] === undefined) {
      throw new Error(`Unknown config of type ${key}`);
    }

    return this._config[key];
  }
}

export default new ConfigurationLoader();
