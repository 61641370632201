import { useCallback } from "react";
import { GraphBuilderService } from "./";
import {IEdge, IGraph, INode} from "../../components/graph-builder/types/GraphTypes";

/**
 * add a single vertex
 */
export const useAddVertex = () =>
  useCallback(
    async (vertex: INode) => GraphBuilderService.addVertex(vertex),
    []
  );

/**
 * add a single edge
 */
export const useAddEdge = () =>
  useCallback(async (edge: IEdge) => GraphBuilderService.addEdge(edge), []);

/**
 * update a single vertex
 */
export const useUpdateVertex = () =>
  useCallback(
    async (vertex: INode) => GraphBuilderService.updateVertex(vertex),
    []
  );

/**
 * update a single edge
 */
export const useUpdateEdge = () =>
  useCallback(async (edge: IEdge) => GraphBuilderService.updateEdge(edge), []);

/**
 * swap edges
 */
export const useSwapEdge = () =>
  useCallback(
    async (oldEdge: IEdge, newEdge: IEdge) =>
      GraphBuilderService.swapEdge(oldEdge, newEdge),
    []
  );

/**
 * delete a vertex
 */
export const useDeleteVertex = () =>
  useCallback(
    async (vertex: INode) => GraphBuilderService.deleteVertex(vertex),
    []
  );

/**
 * delete an edge
 */
export const useDeleteEdge = () =>
  useCallback(async (edge: IEdge) => GraphBuilderService.deleteEdge(edge), []);

/**
 * enforce schema on graph
 */
export const useEnforceSchema = () =>
  useCallback(
    async (schema: IGraph, graph: IGraph) =>
      GraphBuilderService.enforceSchema(schema, graph),
    []
  );
