import React, { FC, useContext } from "react";
import { RouteProps } from "react-router";
import { AuthStatus } from "../authentication/AuthTypes";
import { Redirect, Route } from "react-router-dom";
import AuthContext from "../authentication/AuthContext";

const ProtectedRoute: FC<RouteProps> = (props) => {
  const { authStatus } = useContext(AuthContext);

  return authStatus !== AuthStatus.CONNECT ? (
    <Redirect to={"/user/login"} />
  ) : (
    <Route {...props} />
  );
};

export default ProtectedRoute;
