import { useContext, useEffect } from "react";
import WorkspaceService from "../../../services/workspace/WorkspaceService";
import { GraphUtils } from "../../../services/graph-builder";
import {
  SET_WORKSPACE,
  TOGGLE_READY,
  UPDATE_GRAPH_ID,
} from "../reducers/SandboxActions";
import { useParams } from "react-router";
import SandBoxContext from "../SandBoxContext";
import { browserHistory } from "../../router/history";
import { useColorMap } from "../components/builders/common";

const useSandBoxInit = () => {
  const [state, dispatch] = useContext(SandBoxContext);
  const { id, rev } = useParams();
  const { assignColorsToGraph } = useColorMap();

  useEffect(() => {
    const fetchWorkspace = async () => {
      if (id && !state.graphBuilder.lastUpdatedTime) {
        const workspace = await WorkspaceService.get(id, rev);
        if (workspace) {
          const raw = GraphUtils.buildGraphFromResponse(workspace.graph);
          const graph = GraphUtils.generateGraphFromLayout(
            workspace.layout,
            raw
          );
          dispatch({ type: SET_WORKSPACE, payload: { ...workspace, graph } });
          dispatch({ type: UPDATE_GRAPH_ID, payload: { id: workspace.key } });

          if (!workspace.nodeLabelsColorMap) {
            assignColorsToGraph(workspace.graph);
          }
        } else {
          browserHistory.push("/");
        }
      }

      dispatch({ type: TOGGLE_READY, payload: { ready: true } });
    };

    fetchWorkspace();
  }, [state.graphBuilder.lastUpdatedTime, id, dispatch, rev, assignColorsToGraph]);
};

export default useSandBoxInit;
