import { Alert } from "../../../../../../components/alert";
import * as React from "react";
import { FC } from "react";

interface IClearAlertProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ClearAlert: FC<IClearAlertProps> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => (
  <Alert
    isOpen={isOpen}
    onCancel={onCancel}
    onConfirm={onConfirm}
    cancelButtonText={"No"}
    confirmButtonText={"Yes"}
    icon={"trash"}
    intent={"danger"}
  >
    Are you sure you want to clear your Schema?
  </Alert>
);
