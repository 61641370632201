import React, { FC, useCallback, useEffect, useState } from "react";
import styles from "./QueryTabs.module.scss";
import classNames from "classnames";
import { Button } from "../../../../../components/button";
import { IQuery } from "../Query.interface";
import { EditableText } from "../../../../../components/editable-text";
import { Icon } from "../../../../../components/icon";

interface ITabProps {
  index: number;
  title: string;
  onRemove: (index: number) => void;
  onClick: (index: number) => void;
  onConfirmChangeText: (value: string) => void;
  selected: boolean;
  text: string;
}

const Tab: FC<ITabProps> = ({
  index,
  onRemove,
  onClick,
  selected,
  text,
  onConfirmChangeText,
}) => {
  const [currentText, setCurrentText] = useState(text);
  useEffect(() => {
    setCurrentText(text);
  }, [text]);

  const handleClick = useCallback(() => onClick(index), [index, onClick]);
  const handleRemove = useCallback(
    (evt) => {
      evt.stopPropagation();
      onRemove(index);
    },
    [index, onRemove]
  );
  const handleChange = useCallback((value) => {
    setCurrentText(value);
  }, []);

  const handleConfirm = (value: string) => {
    if (!value) {
      setCurrentText(text);
    } else {
      onConfirmChangeText(value);
    }
  };

  return (
    <div
      onClick={handleClick}
      className={classNames(styles.tab, { [styles.selected]: selected })}
    >
      <div className={styles.inner}>
        <EditableText
          onConfirm={handleConfirm}
          value={currentText}
          onChange={handleChange}
          className={styles.tabText}
        />
        <div
          className={classNames(styles.remove, {
            [styles.removable]: index > 0,
          })}
          onClick={handleRemove}
        >
          <Icon icon={"small-cross"} />
        </div>
      </div>
    </div>
  );
};

interface IQueryTabsProps {
  maxTabs?: number;
  queries: IQuery[];
  onSelectTab: (index: number) => void;
  onRemoveTab: (index: number) => void;
  onAddTab: () => void;
  currentTabIndex: number;
  onUpdateQueryTitle: (title: string) => void;
}

export const QueryTabs: FC<IQueryTabsProps> = ({
  currentTabIndex,
  queries,
  onAddTab,
  onRemoveTab,
  onSelectTab,
  onUpdateQueryTitle,
  maxTabs = Infinity,
}) => {
  const [isShown, setIsShown] = useState(true);
  const handleToggleTabs = useCallback(() => setIsShown(!isShown), [isShown]);

  return (
    <div className={styles.queryTabs}>
      {isShown && (
        <>
          {" "}
          <ul className={styles.tabList}>
            {queries.map((query, index) => (
              <li key={`${query.title}-${index}`}>
                {" "}
                <Tab
                  onClick={onSelectTab}
                  onRemove={onRemoveTab}
                  onConfirmChangeText={onUpdateQueryTitle}
                  selected={currentTabIndex === index}
                  title={"tab1"}
                  index={index}
                  text={query.title}
                />
              </li>
            ))}
          </ul>
          {queries.length < maxTabs && (
            <Button
              onClick={onAddTab}
              className={styles.addTab}
              icon={"plus"}
              minimal={true}
            />
          )}
        </>
      )}
      <Button
        onClick={handleToggleTabs}
        className={styles.addTab}
        icon={isShown ? "menu-closed" : "menu-open"}
        minimal={true}
      />
    </div>
  );
};
