import * as React from "react";
import styles from "./Header.module.scss";
import { ReactComponent as Logo } from "./../../assets/images/logo.svg";
import AWSLogo from "./../../assets/images/aws-neptune.png";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { TinkerpopIcon } from "../icon";

interface IHeaderProps {
  isMimic?: boolean;
}

const Header: FC<IHeaderProps> = ({ isMimic = false, ...props }) => {
  return (
    <header className={styles.appHeader}>
      <div className={styles.headerContent}>
        <div className={styles.logoContainer}>
          {isMimic ? (
            <Logo />
          ) : (
            <NavLink className={styles.logoLink} to={"/"}>
              <Logo />
            </NavLink>
          )}
          <div className={styles.attribution}>
            Powered By
            <a
              href={"https://tinkerpop.apache.org"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              <TinkerpopIcon />
            </a>
          </div>
          <div className={styles.attribution}>
            and Sponsored by
            <a
              href={"https://aws.amazon.com/neptune/"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              <img style={{ height: 35 }} src={AWSLogo} alt="AWS Neptune" />
            </a>
          </div>
        </div>
        {props.children}
      </div>
    </header>
  );
};

export default Header;
