import {
  lazy,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  Suspense,
} from "react";
import SandBoxContext from "../../SandBoxContext";
import * as React from "react";
import styles from "./Results.module.scss";
import Toolbar from "./Toolbar/Toolbar";
import { NonIdealState } from "../../../../components/non-ideal-state";
import { useAnalytics } from "../../../../shared/hooks";
import classNames from "classnames";

const TableView = lazy(() => import("./TableView/TableView"));
const JsonView = lazy(() =>
  import("../../../../components/json-view").then(({ JsonView }) => ({
    default: JsonView,
  }))
);

const DEFAULT_RESULTS_VIEW = "json";

const Results = () => {
  const [state] = useContext(SandBoxContext);
  const [sendEvent] = useAnalytics();
  const [view, setView] = useState<string | null>(DEFAULT_RESULTS_VIEW);
  const results = useMemo(() => {
    if (state.results) {
      return state.results.data;
    }
    return [];
  }, [state.results]);
  const handleSwitchView = useCallback(
    (view: string) => {
      sendEvent({
        category: "Results",
        action: "Change View",
        label: view,
      });
      setView(view);
    },
    [sendEvent]
  );

  useEffect(() => {
    if (state.results.error || !state.results.hasRun) {
      setView(null);
    } else if (state.results.hasRun) {
      setView(DEFAULT_RESULTS_VIEW);
    }
  }, [state.results.hasRun, state.results.error]);

  const jsonStyle = useMemo(
    () => ({
      width: "100%",
      textAlign: "left" as "left",
      position: "absolute" as "absolute",
    }),
    []
  );

  const error = (
    <JsonView
      displayObjectSize={false}
      style={jsonStyle}
      src={{ error: state.results.error }}
    />
  );

  const firstTime = (
    <NonIdealState
      icon={"stopwatch"}
      title={"What are you waiting for?"}
      description={"Run your first query!"}
    />
  );
  const content = (
    <>
      {view === "json" && <JsonView style={jsonStyle} src={results} />}
      {view === "table" && <TableView data={results} />}
    </>
  );

  return (
    <>
      <Toolbar
        disabled={!state.results.hasRun || state.results.error !== ""}
        activeView={view}
        setView={handleSwitchView}
      />
      <div
        data-cy={"Results"}
        className={classNames(styles.results, "gy-results", {
          [styles.hasError]: state.results.error !== "",
        })}
      >
        <Suspense fallback={<div />}>
          {state.results.error
            ? error
            : state.results.hasRun
            ? content
            : firstTime}
        </Suspense>
      </div>
    </>
  );
};

export default memo(Results);
