import AppRestService from "../communication/AppRestService";
import { NotificationService } from "../notification";
import GoogleAnalyticsService from "../analytics/google/GoogleAnalyticsService";
import { IUser } from "./UserTypes";
import JwtUtils from "../../utils/JwtUtils/Jwt";
import { LocalStorageService } from "../localStorage";

class UserService {
  public async login(email: string, password: string): Promise<IUser | null> {
    try {
      const result = await AppRestService.post("auth/login", {
        email: email,
        password: password,
      });
      GoogleAnalyticsService.sendEvent({
        category: "User",
        action: "login",
      });

      this.pairAccessToken(result.data.access_token);
      LocalStorageService.set("token", result.data.access_token);
      return JwtUtils.decode(result.data.access_token);
    } catch (e) {
      if (e && e.message) {
        NotificationService.error(e.message);
      }
      return null;
    }
  }

  public async signUp(
    name: string,
    email: string,
    password: string
  ): Promise<IUser | null> {
    try {
      const result = await AppRestService.post("auth/signup", {
        name: name,
        email: email,
        password: password,
      });
      GoogleAnalyticsService.sendEvent({
        category: "User",
        action: "signUp",
      });

      this.pairAccessToken(result.data.access_token);
      LocalStorageService.set("token", result.data.access_token);
      return JwtUtils.decode(result.data.access_token);
    } catch (e) {
      if (e && e.message) {
        NotificationService.error(e.message);
      }
      return null;
    }
  }

  public logOut() {
    try {
      GoogleAnalyticsService.sendEvent({
        category: "User",
        action: "logOut",
      });

      AppRestService.clearAccessToken();
      LocalStorageService.clear("token");
    } catch (e) {
      if (e && e.message) {
        NotificationService.error(e.message);
      }
      return null;
    }
  }

  public async resetPassword(email: string) {
    try {
      GoogleAnalyticsService.sendEvent({
        category: "User",
        action: "resetPasswordRequest",
      });

      const res = await AppRestService.post("auth/resetpassword", {
        email: email,
      });
      if (res) {
        NotificationService.success(
          "Reset password link was sent to your mail address."
        );
        return true;
      } else {
        return false;
      }
    } catch (e) {
      if (e && e.message) {
        NotificationService.error(e.message);
      }
      return false;
    }
  }

  public async changePassword(
    password: string,
    confirmPassword: string,
    token: string
  ) {
    try {
      GoogleAnalyticsService.sendEvent({
        category: "User",
        action: "changePassword",
      });

      const res = await AppRestService.post("auth/changePassword", {
        password: password,
        confirmPassword: confirmPassword,
        token: token,
      });
      if (res) {
        NotificationService.success("Your password is changed");
        return true;
      } else {
        return false;
      }
    } catch (e) {
      if (e && e.message) {
        NotificationService.error(e.message);
      }
      return false;
    }
  }

  public async changeOldPassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) {
    try {
      const res = await AppRestService.post("auth/changeOldPassword", {
        oldPassword,
        newPassword,
        confirmPassword,
      });
      if (res) {
        NotificationService.success("Your password is changed");
        return true;
      } else {
        return false;
      }
    } catch (e) {
      if (e && e.message) {
        NotificationService.error(e.message);
      }
      return false;
    }
  }

  public async validateToken() {
    try {
      return await AppRestService.get("auth/status");
    } catch (e) {
      if (e && e.message) {
        return false;
      }
    }
  }

  public setAccessToken(token: string) {
    this.pairAccessToken(token);
  }

  private pairAccessToken(token: string) {
    AppRestService.setAccessToken(token);
  }
}

export default new UserService();
