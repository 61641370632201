import AppRestService from "../communication/AppRestService";
import { NotificationService } from "../notification";
import GoogleAnalyticsService from "../analytics/google/GoogleAnalyticsService";

class QueryService {
  public async format(query: string): Promise<string | null> {
    try {
      const result = await AppRestService.post("query/format", { query });
      GoogleAnalyticsService.sendEvent({
        category: "Query",
        action: "Format",
        label: query,
      });
      return result.data;
    } catch (e) {
      if (e && e.message) {
        NotificationService.error(e.message);
      }
      return null;
    }
  }
}

export default new QueryService();
