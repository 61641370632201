import * as React from "react";
import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import SandBoxContext from "../../../SandBoxContext";
import { GraphBuilder as DiGraphBuilder } from "../../../../../components/graph-builder";
import styles from "./SchemaBuilder.module.scss";
import { CreationDialog } from "./CreationDialog";
import useSchemaBuilder from "./useSchemaBuilder";
import { SchemaReductionDialog } from "./SchemaReductionDialog";
import { useReduceGraphToSchema } from "./useReduceGraphToSchema";
import { PropertyValueTypeMap } from "../../../../../shared/constants/common/common";
import { SchemaActions } from "./SchemaActionsToolbar";
import { ClearAlert } from "./ClearAlert";
import { useAnalytics } from "../../../../../shared/hooks";
import { useEnhancedGraph } from "../common/hooks/useEnhancedGraph";

interface ISchemaBuilderProps {
  setView: (view: string) => void;
}

export const SchemaBuilder: FC<ISchemaBuilderProps> = memo(({ setView }) => {
  const [state] = useContext(SandBoxContext);
  const {
    graphBuilder: { scheme: graphScheme },
    schemaBuilder: { scheme: schemaScheme },
  } = state;

  const [sendEvent] = useAnalytics();
  const [isClearModalOpen, setIsClearModalOpen] = useState(false);
  const [isReduceToSchemeDialogOpen, setIsReduceToSchemeDialogOpen] = useState(
    false
  );
  const hasSchema = schemaScheme.nodes.length > 0;

  // suggest schema reduction when schema is empty but graph is not
  useEffect(() => {
    setIsReduceToSchemeDialogOpen(!hasSchema && graphScheme.nodes.length > 0);
  }, [
    graphScheme.nodes.length,
    hasSchema,
    schemaScheme.nodes.length,
    state.schemaBuilder.scheme.nodes.length,
  ]);

  const typeOptions = useMemo(
    () =>
      Object.values(PropertyValueTypeMap).map((t) => ({
        value: t,
        label: t,
      })),
    []
  );

  const {
    selected,
    onMaybeCreateNode,
    onMaybeCreateEdge,
    onCreateEntity,
    onSwapEdge,
    onDeleteNode,
    onDeleteEdge,
    onUpdateEntityData,
    creatingEntityOfType,
    closeCreationDialog,
    onSelectNode,
    onSelectEdge,
    onCopy,
    onPaste,
    onUpdateNodePosition,
    updateLabelColor,
    colorMap,
    onDeleteEntity,
    onClear,
    updateScheme,
  } = useSchemaBuilder();

  const handleClose = useCallback(() => {
    onClear();
    setIsReduceToSchemeDialogOpen(false);
    setView("graph");
  }, [onClear, setView]);
  const reduceGraphToSchema = useReduceGraphToSchema();
  const handleReductionConfirm = useCallback(() => {
    sendEvent({ category: "Schema", action: "Reduce" });
    const scheme = reduceGraphToSchema(graphScheme);
    updateScheme(scheme);
    setIsReduceToSchemeDialogOpen(false);
  }, [graphScheme, reduceGraphToSchema, sendEvent, updateScheme]);

  const toggleClearSchemaModal = useCallback(() => {
    setIsClearModalOpen(!isClearModalOpen);
  }, [isClearModalOpen]);

  const enhancedGraph = useEnhancedGraph(state.schemaBuilder.scheme, false);

  const handleClearConfirm = useCallback(() => {
    onClear();
    toggleClearSchemaModal();
    sendEvent({ category: "Schema", action: "Clear" });
    setView("graph");
  }, [onClear, sendEvent, setView, toggleClearSchemaModal]);

  return (
    <div className={`${styles.schemaBuilder}`}>
      <ClearAlert
        isOpen={isClearModalOpen}
        onCancel={toggleClearSchemaModal}
        onConfirm={handleClearConfirm}
      />
      <SchemaReductionDialog
        isOpen={isReduceToSchemeDialogOpen}
        onClose={handleClose}
        onConfirm={handleReductionConfirm}
      />
      <CreationDialog
        onCreate={onCreateEntity}
        entityType={creatingEntityOfType ? creatingEntityOfType.type : null}
        onClose={closeCreationDialog}
      />
      <DiGraphBuilder
        graphWrapperClassName={`${styles.graph} gy-scheme-builder`}
        scheme={enhancedGraph}
        onSwapEdge={onSwapEdge}
        onCreateNode={onMaybeCreateNode}
        onCreateEdge={onMaybeCreateEdge}
        onDeleteNode={onDeleteNode}
        onDeleteEdge={onDeleteEdge}
        onUpdateNodePosition={onUpdateNodePosition}
        onUpdateEntityData={onUpdateEntityData}
        onCopy={onCopy}
        onPaste={onPaste}
        onSelectEdge={onSelectEdge}
        onSelectNode={onSelectNode}
        selected={selected}
        graphId={state.schemaBuilder.id}
        updateLabelColor={updateLabelColor}
        colorMap={colorMap}
        onDeleteEntity={onDeleteEntity}
        entityValueOptions={typeOptions}
        displayEntityId={false}
        entityMaxValueChars={1}
        customToolbar={<SchemaActions onClearSchema={toggleClearSchemaModal} />}
      />
    </div>
  );
});
