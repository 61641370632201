import React, { FC } from "react";
import { NonIdealState } from "../../../components/non-ideal-state";
import styles from "./EmptyState.module.scss";

interface IEmptyStateProps {
  onCreateOrganization: () => void;
}

export const EmptyState: FC<IEmptyStateProps> = ({ onCreateOrganization }) => (
  <div className={styles.emptyState}>
    <NonIdealState
      icon={"people"}
      title={"Seems like you are not a member of an organization"}
      description={
        <div className={styles.nonIdealDesc}>
          Would you like to{" "}
          <span className={styles.create} onClick={onCreateOrganization}>
            create
          </span>{" "}
          one?
        </div>
      }
    />
    <div className={styles.learnMore}>
      Learn more about organizations{" "}
      <a href={"https://gremlify.gitbook.io/gremlify/organization"}>here</a>
    </div>
  </div>
);
