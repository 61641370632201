export class SchemaBuilderUtils {
  public static convertSchemaToDataEntries() {
    return [
      {
        id: "label",
        keyProps: { value: "label", editable: false, type: "text" as const },
        valueProps: {
          value: "",
          editable: true,
          type: "text" as const,
        },
      },
    ];
  }

  /**
   * generate a random entity i
   */
  public static generateEntityId() {
    return Math.ceil(Math.random() * 1000);
  }
}
